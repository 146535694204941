module.exports = {

  // New Products monthly(standard)/annual(premium) price points not included
  solo: {
    product_id: 100,
    handle: 'solo',
    chargify_id: '14',
    name: 'Solo',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Single User',
    tier: 1,
    price_points: [
      {
        id: 1,
        product_id: 100,
        handle: "standard",
        chargify_id: 1,
        name: "standard",
        tier: 1,
        archived: false,
        price_in_cents: "9500",
        formatted_price: "$95.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$160.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$140.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$120.00 GBP"
          }
        ]
      },
      {
        id: 2,
        product_id: 100,
        handle: "premium",
        chargify_id: 2,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "114000",
        formatted_price: "$1,140.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 1260,
            formatted_price: "$1260.00 AUD"
          },
          {
            code: "USD",
            price: 1140,
            formatted_price: "$1140.00 USD"
          },
          {
            code: "GBP",
            price: 1000,
            formatted_price: "$1000.00 GBP"
          }
        ]

      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  duo: {
    product_id: 200,
    handle: 'duo',
    chargify_id: '14',
    name: 'duo',
    number_of_users: 2,
    number_of_seats: 2,
    price_in_cents: '18900',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Two Users',
    tier: 2,
    price_points: [
      {
        id: 3,
        product_id: 200,
        handle: "standard",
        chargify_id: 3,
        name: "Monthly",
        tier: 1,
        archived: false,
        price_in_cents: "18900",
        formatted_price: "$189.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 200,
            formatted_price: "$200.00 AUD"
          }, {
            code: "USD",
            price: 189,
            formatted_price: "$189.00 USD"
          }, {
            code: "GBP",
            price: 172,
            formatted_price: "$172.00 GBP"
          }
        ]
      },
      {
        id: 4,
        product_id: 200,
        handle: "premium",
        chargify_id: 4,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "199000",
        formatted_price: "$1,990.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 2050,
            formatted_price: "$2050.00 AUD"
          }, {
            code: "USD",
            price: 1990,
            formatted_price: "$1990.00 USD"
          }, {
            code: "GBP",
            price: 1850,
            formatted_price: "$1850.00 GBP"
          }
        ]
      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  group: {
    product_id: 300,
    handle: 'group',
    chargify_id: '14',
    name: 'Group',
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: '27900',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Five Users',
    tier: 3,
    price_points: [
      {
        id: 5,
        product_id: 300,
        handle: "standard",
        chargify_id: 5,
        name: "Monthly",
        tier: 1,
        archived: false,
        price_in_cents: "27900",
        formatted_price: "$279.00 USD",
        interval_months: "1",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$279.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$279.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$279.00 GBP"
          }
        ]
      },
      {
        id: 6,
        product_id: 300,
        handle: "premium",
        chargify_id: 6,
        name: "Annual",
        tier: 2,
        archived: false,
        price_in_cents: "299000",
        formatted_price: "$2,990.00 USD",
        interval_months: "12",
        currencies: [
          {
            code: "AUD",
            price: 160,
            formatted_price: "$2,990.00 AUD"
          }, {
            code: "USD",
            price: 140,
            formatted_price: "$2,990.00 USD"
          }, {
            code: "GBP",
            price: 120,
            formatted_price: "$2,990.00 GBP"
          }
        ]
      },
    ],
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },

  // old products
  chihuahua_monthly: {
    product_id: 4,
    handle: 'chihuahua_monthly',
    chargify_id: '14',
    name: 'Chihuahua Monthly',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 7,
    description: 'Single User',
    tier: 1,
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: true,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  chihuahua_monthly_no_trial: {
    product_id: 5,
    handle: 'chihuahua_monthly_no_trial',
    chargify_id: '15',
    name: 'Chihuahua Monthly No Trial',
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: '9500',
    interval_months: 1,
    trial_length_days: 0,
    description: 'Single User',
    tier: 1,
    metadata: {
      main_image_svg: 'chihuahua',
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$95.00 USD',
      display: false,
      image_label: 'chihuahua'
    },
    additional_attributes: {}
  },
  border_collie: {
    product_id: 6,
    handle: 'border_collie',
    chargify_id: '16',
    name: 'Border Collie',
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: '19500',
    interval_months: 1,
    trial_length_days: 0,
    description: '2-5 Users',
    tier: 5,
    metadata: {
      main_image_svg: 'border_collie',
      min_number_of_users: 2,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$195.00 USD',
      display: true,
      image_label: 'border_collie'
    },
    additional_attributes: {}
  },
  golden_retriever: {
    product_id: 7,
    handle: 'golden_retriever',
    chargify_id: '17',
    name: 'Golden Retriever',
    number_of_users: 10,
    number_of_seats: 11,
    price_in_cents: '35000',
    interval_months: 1,
    trial_length_days: 0,
    description: '6-10 Users',
    tier: 10,
    metadata: {
      main_image_svg: 'golden_retriever',
      min_number_of_users: 6,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$350.00 USD',
      display: true,
      image_label: 'golden_retriever'
    },
    additional_attributes: {}
  },
  st_bernard: {
    product_id: 8,
    handle: 'st_bernard',
    chargify_id: '18',
    name: 'St. Bernard',
    number_of_users: 15,
    number_of_seats: 16,
    price_in_cents: '49500',
    interval_months: 1,
    trial_length_days: 0,
    description: '11-15 Users',
    tier: 15,
    metadata: {
      main_image_svg: 'st_bernard',
      min_number_of_users: 11,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$495.00 USD',
      display: true,
      image_label: 'st_bernard'
    },
    additional_attributes: {}
  },
  great_dane: {
    product_id: 9,
    handle: 'great_dane',
    chargify_id: '19',
    name: 'Great Dane',
    number_of_users: 300,
    number_of_seats: 305,
    price_in_cents: '49500',
    interval_months: 1,
    trial_length_days: 0,
    description: '16-300 Users',
    tier: 20,
    metadata: {
      main_image_svg: 'great_dane',
      min_number_of_users: 16,
      archived: false,
      start_time_unix_seconds: '0',
      end_time_unix_seconds: '0',
      formatted_price: '$495.00 USD',
      display: false,
      image_label: 'great_dane'
    },
    additional_attributes: {}
  },
  chihuahua_annual: {
    product_id: 16,
    handle: "chihuahua_annual",
    chargify_id: "5521105",
    name: "Chihuahua Annual",
    number_of_users: 1,
    number_of_seats: 1,
    price_in_cents: "114000",
    interval_months: "12",
    trial_length_days: 0,
    description: "Single User",
    tier: 1,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 1,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$1,140.00 USD",
      display: true,
      image_label: "chihuahua"
    },
    additional_attributes: {}
  },
  border_collie_annual: {
    product_id: 17,
    handle: "border_collie_annual",
    chargify_id: "5521106",
    name: "Border Collie Annual",
    number_of_users: 5,
    number_of_seats: 6,
    price_in_cents: "234000",
    interval_months: "12",
    trial_length_days: 0,
    description: "2 - 5 User Plan",
    tier: 5,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 2,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$2,340.00 USD",
      display: false,
      image_label: "border_collie"
    },
    additional_attributes: {}
  },
  golden_retriever_annual: {
    product_id: 18,
    handle: "golden_retriever_annual",
    chargify_id: "5521107",
    name: "Golden Retriever Annual",
    number_of_users: 10,
    number_of_seats: 11,
    price_in_cents: "420000",
    interval_months: "12",
    trial_length_days: 0,
    description: "6 - 10 User Plan",
    tier: 10,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 6,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$4,200.00 USD",
      display: false,
      image_label: "golden_retriever"
    },
    additional_attributes: {}
  },
  st_bernard_annual: {
    product_id: 19,
    handle: "st_bernard_annual",
    chargify_id: "5521108",
    name: "St. Bernard Annual",
    number_of_users: 15,
    number_of_seats: 16,
    price_in_cents: "594000",
    interval_months: "12",
    trial_length_days: 0,
    description: "11 - 15 User Plan",
    tier: 15,
    metadata: {
      main_image_svg: "",
      min_number_of_users: 11,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$5,940.00 USD",
      display: false,
      image_label: "st_bernard"
    },
    additional_attributes: {}
  },

  talkatoo_monthly: {
    product_id: "23",
    handle: "talkatoo_monthly",
    chargify_id: "6576126",
    name: "Talkatoo Monthly",
    number_of_users: 0,
    number_of_seats: 2,
    price_in_cents: "0",
    interval_months: "1",
    trial_length_days: 0,
    description: "Talkatoo plan billed monthly.",
    tier: 1,
    commitment_period: 0,
    price_points: [
      {
        id: "8",
        product_id: "23",
        handle: "monthly",
        chargify_id: "2510819",
        name: "Monthly",
        tier: "1",
        archived: false,
        price_in_cents: "0",
        interval_months: "1",
        formatted_price: "$0.00 USD",
        currencies: [
          {
            code: "AUD",
            price: 0,
            formatted_price: "$0.00 AUD"
          },
          {
            code: "CAD",
            price: 0,
            formatted_price: "$0.00 CAD"
          },
          {
            code: "GBP",
            price: 0,
            formatted_price: "£0.00"
          }
        ]
      }
    ],
    metadata: {
      main_image_svg: "solo",
      min_number_of_users: 0,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$0.00 USD",
      display: true,
      image_label: "solo"
    },
    additional_attributes: {}
  },
  talkatoo_annual: {
    product_id: "24",
    handle: "talkatoo_annual",
    chargify_id: "6576127",
    name: "Talkatoo Annual",
    number_of_users: 0,
    number_of_seats: 2,
    price_in_cents: "0",
    interval_months: "12",
    trial_length_days: 0,
    description: "Talkatoo plan billed annually.",
    tier: 1,
    commitment_period: 0,
    price_points: [
      {
        id: "9",
        product_id: "24",
        handle: "annual",
        chargify_id: "2510820",
        name: "Annual",
        tier: "5",
        archived: false,
        price_in_cents: "0",
        interval_months: "12",
        formatted_price: "$0.00 USD",
        currencies: [
          {
            code: "AUD",
            price: 0,
            formatted_price: "$0.00 AUD"
          },
          {
            code: "CAD",
            price: 0,
            formatted_price: "$0.00 CAD"
          },
          {
            code: "GBP",
            price: 0,
            formatted_price: "£0.00"
          }
        ]
      }
    ],
    metadata: {
      main_image_svg: "solo",
      min_number_of_users: 0,
      archived: false,
      start_time_unix_seconds: "0",
      end_time_unix_seconds: "0",
      formatted_price: "$0.00 USD",
      display: true,
      image_label: "solo"
    },
    additional_attributes: {}
  }
};


[
  {
      "product_id": "prod_Q9Z1PdlfOAbarp",
      "handle": "",
      "name": "Talkatoo Verified User",
      "description": "",
      "prices": [
          {
              "id": "price_1PJFtpIGl7aET9vnWZiTIwKd",
              "product_id": "prod_Q9Z1PdlfOAbarp",
              "handle": "retail_verified",
              "name": "",
              "description": "Talkatoo Verified User",
              "archived": false,
              "price_in_cents": "50000",
              "interval_months": "1",
              "formatted_price": "50000.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          }
      ],
      "metadata": {
          "archived": false,
          "display": false
      },
      "additional_attributes": null
  },
  {
      "product_id": "prod_Q3ZjCbr9h80XFx",
      "handle": "",
      "name": "Talkatoo Free User",
      "description": "",
      "prices": [
          {
              "id": "price_1PDUweIGl7aET9vn5RaR9T0u",
              "product_id": "prod_Q3ZjCbr9h80XFx",
              "handle": "",
              "name": "",
              "description": "",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "1",
              "formatted_price": "0.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          },
          {
              "id": "price_1PDUwWIGl7aET9vnl0kIJrDz",
              "product_id": "prod_Q3ZjCbr9h80XFx",
              "handle": "",
              "name": "",
              "description": "",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "3",
              "formatted_price": "0.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          },
          {
              "id": "price_1PDUoPIGl7aET9vnKRCQSQlA",
              "product_id": "prod_Q3ZjCbr9h80XFx",
              "handle": "",
              "name": "",
              "description": "",
              "archived": true,
              "price_in_cents": "0",
              "interval_months": "0",
              "formatted_price": "0.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          },
          {
              "id": "price_1PDSaLIGl7aET9vnPAIh9zOW",
              "product_id": "prod_Q3ZjCbr9h80XFx",
              "handle": "",
              "name": "",
              "description": "",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "1",
              "formatted_price": "0.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          }
      ],
      "metadata": {
          "archived": false,
          "display": false
      },
      "additional_attributes": null
  },
  {
      "product_id": "prod_Q3ZiKUlp8dwgQi",
      "handle": "",
      "name": "Talkatoo Verified Usage",
      "description": "",
      "prices": [
          {
              "id": "price_1PDSYtIGl7aET9vnHeEU6Z6K",
              "product_id": "prod_Q3ZiKUlp8dwgQi",
              "handle": "",
              "name": "",
              "description": "",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "1",
              "formatted_price": "0.00",
              "tiers": [
                  {
                      "id": "",
                      "price_in_cents": "0",
                      "formatted_price": "0.00",
                      "up_to_amount": "50"
                  },
                  {
                      "id": "",
                      "price_in_cents": "1000",
                      "formatted_price": "1000.00",
                      "up_to_amount": "0"
                  }
              ],
              "currency": "usd",
              "additional_currencies": []
          }
      ],
      "metadata": {
          "archived": false,
          "display": false
      },
      "additional_attributes": null
  },
  {
      "product_id": "prod_PuGWI7pgMqy36s",
      "handle": "",
      "name": "Talkatoo Seat",
      "description": "Talkatoo dictation user seat",
      "prices": [
          {
              "id": "price_1PLoNeIGl7aET9vn1Io6BMiH",
              "product_id": "prod_PuGWI7pgMqy36s",
              "handle": "retail_monthly",
              "name": "",
              "description": "Retail Monthly",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "1",
              "formatted_price": "0.00",
              "tiers": [
                  {
                      "id": "",
                      "price_in_cents": "10700",
                      "formatted_price": "10700.00",
                      "up_to_amount": "1"
                  },
                  {
                      "id": "",
                      "price_in_cents": "9450",
                      "formatted_price": "9450.00",
                      "up_to_amount": "2"
                  },
                  {
                      "id": "",
                      "price_in_cents": "7833",
                      "formatted_price": "7833.00",
                      "up_to_amount": "3"
                  },
                  {
                      "id": "",
                      "price_in_cents": "6675",
                      "formatted_price": "6675.00",
                      "up_to_amount": "4"
                  },
                  {
                      "id": "",
                      "price_in_cents": "6160",
                      "formatted_price": "6160.00",
                      "up_to_amount": "5"
                  },
                  {
                      "id": "",
                      "price_in_cents": "6017",
                      "formatted_price": "6017.00",
                      "up_to_amount": "6"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5914",
                      "formatted_price": "5914.00",
                      "up_to_amount": "7"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5838",
                      "formatted_price": "5838.00",
                      "up_to_amount": "8"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5789",
                      "formatted_price": "5789.00",
                      "up_to_amount": "9"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5740",
                      "formatted_price": "5740.00",
                      "up_to_amount": "10"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5700",
                      "formatted_price": "5700.00",
                      "up_to_amount": "11"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5667",
                      "formatted_price": "5667.00",
                      "up_to_amount": "12"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5646",
                      "formatted_price": "5646.00",
                      "up_to_amount": "13"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5621",
                      "formatted_price": "5621.00",
                      "up_to_amount": "14"
                  },
                  {
                      "id": "",
                      "price_in_cents": "5600",
                      "formatted_price": "5600.00",
                      "up_to_amount": "0"
                  }
              ],
              "currency": "usd",
              "additional_currencies": [
                  {
                      "currency": "cad",
                      "tiers": [
                          {
                              "id": "",
                              "price_in_cents": "15000",
                              "formatted_price": "150.00",
                              "up_to_amount": "1"
                          },
                          {
                              "id": "",
                              "price_in_cents": "13000",
                              "formatted_price": "130.00",
                              "up_to_amount": "2"
                          },
                          {
                              "id": "",
                              "price_in_cents": "10700",
                              "formatted_price": "107.00",
                              "up_to_amount": "3"
                          },
                          {
                              "id": "",
                              "price_in_cents": "9200",
                              "formatted_price": "92.00",
                              "up_to_amount": "4"
                          },
                          {
                              "id": "",
                              "price_in_cents": "8500",
                              "formatted_price": "85.00",
                              "up_to_amount": "5"
                          },
                          {
                              "id": "",
                              "price_in_cents": "8300",
                              "formatted_price": "83.00",
                              "up_to_amount": "6"
                          },
                          {
                              "id": "",
                              "price_in_cents": "8100",
                              "formatted_price": "81.00",
                              "up_to_amount": "7"
                          },
                          {
                              "id": "",
                              "price_in_cents": "8000",
                              "formatted_price": "80.00",
                              "up_to_amount": "8"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7950",
                              "formatted_price": "79.50",
                              "up_to_amount": "9"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7900",
                              "formatted_price": "79.00",
                              "up_to_amount": "10"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7825",
                              "formatted_price": "78.25",
                              "up_to_amount": "11"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7800",
                              "formatted_price": "78.00",
                              "up_to_amount": "12"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7750",
                              "formatted_price": "77.50",
                              "up_to_amount": "13"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7725",
                              "formatted_price": "77.25",
                              "up_to_amount": "14"
                          },
                          {
                              "id": "",
                              "price_in_cents": "7700",
                              "formatted_price": "77.00",
                              "up_to_amount": "0"
                          }
                      ]
                  }
              ]
          },
          {
              "id": "price_1PDTvEIGl7aET9vnKrUukBot",
              "product_id": "prod_PuGWI7pgMqy36s",
              "handle": "",
              "name": "",
              "description": "Enterprise Annual",
              "archived": false,
              "price_in_cents": "100",
              "interval_months": "1",
              "formatted_price": "100.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          },
          {
              "id": "price_1PDTMXIGl7aET9vn2uM7kqvZ",
              "product_id": "prod_PuGWI7pgMqy36s",
              "handle": "",
              "name": "",
              "description": "Enterprise Quarterly",
              "archived": false,
              "price_in_cents": "100",
              "interval_months": "3",
              "formatted_price": "100.00",
              "tiers": [],
              "currency": "usd",
              "additional_currencies": []
          },
          {
              "id": "price_1P4S2XIGl7aET9vnvICaelOq",
              "product_id": "prod_PuGWI7pgMqy36s",
              "handle": "retail_annual",
              "name": "",
              "description": "Retail Annual",
              "archived": false,
              "price_in_cents": "0",
              "interval_months": "1",
              "formatted_price": "0.00",
              "tiers": [
                  {
                      "id": "",
                      "price_in_cents": "128000",
                      "formatted_price": "128000.00",
                      "up_to_amount": "1"
                  },
                  {
                      "id": "",
                      "price_in_cents": "113500",
                      "formatted_price": "113500.00",
                      "up_to_amount": "2"
                  },
                  {
                      "id": "",
                      "price_in_cents": "94000",
                      "formatted_price": "94000.00",
                      "up_to_amount": "3"
                  },
                  {
                      "id": "",
                      "price_in_cents": "80000",
                      "formatted_price": "80000.00",
                      "up_to_amount": "4"
                  },
                  {
                      "id": "",
                      "price_in_cents": "73800",
                      "formatted_price": "73800.00",
                      "up_to_amount": "5"
                  },
                  {
                      "id": "",
                      "price_in_cents": "72150",
                      "formatted_price": "72150.00",
                      "up_to_amount": "6"
                  },
                  {
                      "id": "",
                      "price_in_cents": "70971",
                      "formatted_price": "70971.00",
                      "up_to_amount": "7"
                  },
                  {
                      "id": "",
                      "price_in_cents": "70088",
                      "formatted_price": "70088.00",
                      "up_to_amount": "8"
                  },
                  {
                      "id": "",
                      "price_in_cents": "69400",
                      "formatted_price": "69400.00",
                      "up_to_amount": "9"
                  },
                  {
                      "id": "",
                      "price_in_cents": "68850",
                      "formatted_price": "68850.00",
                      "up_to_amount": "10"
                  },
                  {
                      "id": "",
                      "price_in_cents": "68400",
                      "formatted_price": "68400.00",
                      "up_to_amount": "11"
                  },
                  {
                      "id": "",
                      "price_in_cents": "68025",
                      "formatted_price": "68025.00",
                      "up_to_amount": "12"
                  },
                  {
                      "id": "",
                      "price_in_cents": "67708",
                      "formatted_price": "67708.00",
                      "up_to_amount": "13"
                  },
                  {
                      "id": "",
                      "price_in_cents": "67436",
                      "formatted_price": "67436.00",
                      "up_to_amount": "14"
                  },
                  {
                      "id": "",
                      "price_in_cents": "67200",
                      "formatted_price": "67200.00",
                      "up_to_amount": "0"
                  }
              ],
              "currency": "usd",
              "additional_currencies": []
          },
      ],
      "metadata": {
          "archived": false,
          "display": false
      },
      "additional_attributes": null
  }
];