<template>
  <div v-click-outside="hideMenu" class="base-icon-dropdown">
    <div :class="['icon-container',
                  square ? 'square' : 'round',
                  disabled ? 'disable' : '',
                  iconColor ? `border-${iconColor}` : 'border-[#9BACB2]',
                  noBorder ? 'border-none' : 'border-2' ]"
         role="button"
         :selected="showMenu"
         aria-haspopup="true"
         :aria-expanded="showMenu"
         @click="toggleMenu">
      <i :class="['material-icons px-2 icon my-auto', iconColor ? `text-${iconColor}` : 'text-[#9BACB2]']">{{ icon }}</i>
      <span v-if="menuText"
            :class="['pr-2 my-auto overflow-x-hidden whitespace-nowrap text-sm font-bold font-roboto max-w-xs',
                     iconColor ? `text-${iconColor}` : 'text-[#9BACB2]']">
        {{ menuText }}
      </span>
      <div v-if="notifCount" :class="['notif-bubble', square ? 'notif-square' : 'notif-circle']">
        {{ notifCount > 99 ? "!" : notifCount }}
      </div>
    </div>

    <Transition name="fade">

      <div v-show="showMenu"
           :class="['menu mt-0.5 default-fade-duration',
                    centerMenu ? 'center' :
                    menuX ? menuX : 'right-0 translate-x-0 lg:translate-x-1/4',
                    menuY ? menuY : '',]">
        <slot />
      </div>
    </Transition>
  </div>
</template>
<script>
// this will be our notification container but making it multi purpose doesn't seem that hard
// so this is an icon that is clickable for a dropdown that can take in an integer
// to show a notification bubble
export default {
  name: "BaseIconDropdown",
  emits: ['click-out'],
  props: {
    icon: String,
    iconColor: String,
    notifCount: Number,
    // determines the shape of the container
    square: Boolean,
    centerMenu: Boolean,
    menuX: [String, undefined],
    menuY: [String, undefined],
    // text provided to include along-side the icon
    menuText: String,
    disabled: Boolean,
    noBorder: Boolean,
  },
  data () {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu () {
      if (this.disabled) return;
      this.showMenu = !this.showMenu;
    },
    hideMenu (e) {
      if (this.showMenu) {
        this.$emit("click-out", { showMenu: this.showMenu });
        this.showMenu = false;
      }
      e.stopPropagation();
    },
  },
};
</script>
<style scoped>
.base-icon-dropdown {
  position: relative;
}

.base-icon-dropdown .icon-container {
  transition: background-color 0.1s ease-in;
  @apply flex;
  @apply relative;
  @apply cursor-pointer;
  @apply select-none;
  @apply text-center;
  @apply align-middle;
  @apply justify-center;
  height: 100%;
  width: 100%;
}

.base-icon-dropdown .icon-container.disable {
  @apply cursor-default;
}

.base-icon-dropdown .icon-container.round {
  @apply rounded-full;
}

.base-icon-dropdown .icon-container.square {
  @apply bg-white;
  @apply rounded-xl;
}

.base-icon-dropdown .icon-container:not(.disable):hover {
  transition: background-color 0.1s ease-in;
  @apply bg-gray-100;
  @apply bg-opacity-10;
}

.base-icon-dropdown .icon-container[selected="true"] {
  @apply bg-gray-100;
  @apply bg-opacity-10;
}

.base-icon-dropdown .icon {
  position: static;
  font-size: inherit;
}

.base-icon-dropdown .menu {
  @apply z-20;
  @apply bg-white;
  @apply absolute;
  @apply py-2;
  @apply rounded;
  @apply shadow;
  @apply whitespace-nowrap;
  @apply border;
  @apply border-gray-200;
}

.base-icon-dropdown .menu.center {
  left: 50%;
  right: inherit;
  transform: translate(-50%, 0);
}

@media (min-width: 1024px) {}

.base-icon-dropdown .menu * {
  @apply font-semibold;
  @apply py-2;
  @apply border-b;
  @apply border-black;
}

.base-icon-dropdown .notif-bubble {
  /* sizing and positioning */
  position: absolute;
  right: 0;
  font-size: inherit;
  /* center the text */
  text-align: center;
  @apply flex;
  @apply flex-col;
  @apply justify-center;
}

.base-icon-dropdown .notif-bubble.notif-circle {
  @apply h-7;
  @apply w-7;
  @apply rounded-full;
  /* colour and size the text */
  @apply font-roboto-condensed;
  @apply bg-error;
  @apply text-white;
  @apply border-2;
  @apply border-white;
  @apply text-lg;
}

.base-icon-dropdown .notif-bubble.notif-square {
  @apply h-5;
  @apply w-5;
  @apply rounded;
  /* colour and size the text */
  @apply bg-main-alt;
  @apply text-white;
  @apply border;
  @apply border-white;
  @apply text-sm;
  @apply font-semibold;
}
</style>