<template>
  <div id="base-multi-select"
       class="base-multi-select relative"
       :disabled="disabled">
    <!-- placeholder of the menu -->
    <div v-if="label" class="base-label-container">
      <div class="base-label">
        {{ label }}
      </div>
    </div>
    <div :id="`base-multi-select-${$.uid}`" class="flex bg-white border-gray-400 border rounded-md content-center p-3"
         role="button" @click="toggleMenu">
      <div class="flex w-full my-auto pr-6 capitalize text-sm">
        <div v-if="showCount && selectedItemsCount > 0"
             class="min-w-[1.25rem] min-h-[1.25rem] flex flex-col my-auto mr-2
                    justify-center text-center rounded bg-main-dark text-white text-xs font-bold leading-[initial]">
          {{ selectedItemsCount }}
        </div>
        <div :class="ellipsis ? 'ellipsis-overflow' : ''">
          {{ selectedItemsText }}
        </div>
      </div>
      <i :class="[showMenu ? 'arrow-up' : 'arrow-down', 'absolute right-3 top-3 material-icons select-none text-border-gray-400 bg-white']">
        keyboard_arrow_down
      </i>
    </div>
    <div v-if="!teleport && showMenu" v-click-outside="clickOut">
      <div :class="['absolute bg-white right-0 shadow-lg rounded-md py-1 z-50 max-h-[30rem] overflow-y-auto',
                    fullMenu ? 'w-full' : 'w-full md:w-3/4 lg:w-full xl:w-2/3']">
        <div v-if="showSearch"
             class="sticky top-0 bg-white flex py-2 px-4 text-sm z-10">
          <div class="flex w-full relative">
            <input v-model="searchFilter" placeholder="Search"
                   class="w-full h-10 px-3 border border-gray-500 rounded-md text-gray-500
                            transition-colors focus:border-main-alt focus:text-black" />
          </div>
        </div>
        <div v-if="anyKeys" class="flex justify-center py-3 px-3 pr-6 my-0.5 text-sm text-gray-700">
          Nothing to show!
        </div>
        <div v-for="(val, item) in filteredValues" :key="item"
             :class="['flex py-3 px-3 pr-6 my-0.5 text-sm capitalize cursor-pointer',
                      'duration-100 transition-colors text-black',
                      val ? 'bg-rare2-lighter' : 'bg-white hover:bg-gray-100',
                      disabledCheck(val, item) ? 'disabled' : '']"
             @click="(e) => itemClick(e, val, item)">
          <input type="checkbox" class="tk-check mr-6 my-auto" :checked="val" />
          <label class="my-auto cursor-pointer"> {{ item.replace(/_/g, " ") }} </label>
        </div>
      </div>
    </div>
    <!-- repeating my self here which is pretty ugly. I'd use <Component is="Teleport" />
         to conditionally render a teleport if it worked -->
    <Teleport v-else to="body">
      <div v-if="showMenu" v-click-outside="clickOut" :style="teleportedMenuPosition">
        <div :class="['bg-white right-0 shadow-lg rounded-md py-1 z-50 max-h-[30rem] overflow-y-auto',
                      fullMenu ? 'w-full' : 'w-full md:w-3/4 lg:w-full xl:w-2/3']">
          <div v-if="showSearch"
               class="sticky top-0 bg-white flex py-2 px-4 text-sm z-10">
            <div class="flex w-full relative">
              <input v-model="searchFilter" placeholder="Search"
                     class="w-full h-10 px-3 border border-gray-500 rounded-md text-gray-500
                            transition-colors focus:border-main-alt focus:text-black" />
            </div>
          </div>
          <div v-if="anyKeys" class="flex justify-center py-3 px-3 pr-6 my-0.5 text-sm text-gray-700">
            Nothing to show!
          </div>
          <div v-for="(val, item) in filteredValues" :key="item"
               :class="['flex py-3 px-3 pr-6 my-0.5 text-sm capitalize cursor-pointer',
                        'duration-100 transition-colors text-black',
                        val ? 'bg-rare2-lighter' : 'bg-white hover:bg-gray-100',
                        disabledCheck(val, item) ? 'disabled' : '']"
               @click="(e) => itemClick(e, val, item)">
            <input type="checkbox" class="tk-check mr-4 my-auto" :checked="val" />
            <label class="my-auto cursor-pointer"> {{ item.replace(/_/g, " ") }} </label>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
/**
* base select works on an object, having the fields of each property be the text of the select
* and value of each property be a bool of if it is selected or not it will only emit an event about a change
* click-out is triggered to close the list and provides the new values of the list
* when the user clicks an element in the list an unchanged event is sent to enable the parent component to update
*/
export default {
  name: "BaseMultiSelect",
  emits: ["click-out", "unchanged", "update:value"],
  // where field is the display text, bool is if it is selected or not
  props: {
    // value is expected to be an object in the format {field: bool}
    value: Object,
    disabledList: Array,
    placeholderText: String,
    label: String,
    // immediately show the dropdown on render
    show: Boolean,
    // show a count of the selected items
    showCount: Boolean,
    fullMenu: Boolean,
    // places the menu with teleport to the body instead of relative to the component. Use in special cases where over flow may happen
    teleport: Boolean,
    // if true, will add ellipsis to the text and not wrap text
    ellipsis: Boolean,
    // inverses the disable list rules to disable selection when items are unchecked
    disableOnUnchecked: Boolean,
    // enables a search field for filtering the set of selections
    showSearch: Boolean,
    // disables the whole dropdown
    disabled: Boolean,
  },
  data () {
    return {
      showMenu: this.show ?? false,
      shown: false,
      // copy to track changes
      original: { ... this.value },
      domSelf: undefined,
      teleportListenerCancel: undefined,
      blockClickOut: false,
      searchFilter: "",
    };
  },
  created () {
    this.$emit("unchanged", true);
    // adding listeners for when the teleported menu detatches from the correct location
    if (this.teleport) {
      this.$watch('showMenu', (showVal) => {
        // only watch the dom element when the menu is open
        if (showVal) {
          window.addEventListener("resize", this.teleportedCancelCallback);
          window.addEventListener("wheel", this.teleportedCancelCallback);
        } else if (!showVal && this.teleportListenerCancel) {
          window.removeEventListener("resize", this.teleportedCancelCallback);
          window.removeEventListener("wheel", this.teleportedCancelCallback);
        }
      });
    }
  },
  unmounted () {
    window.removeEventListener("resize", this.teleportedCancelCallback);
    window.removeEventListener("wheel", this.teleportedCancelCallback);
  },
  mounted () {
    this.domSelf = document.getElementById(`base-multi-select-${this.$.uid}`);
  },
  computed: {
    teleportedMenuPosition () {
      if (!this.showMenu) return '';
      if (!this.domSelf) return '';
      let rect = this.domSelf.getBoundingClientRect();
      return `position: absolute; z-index:100; top: ${rect.y + rect.height}px; left: ${rect.x}px; width: ${rect.width}px;`;
    },
    selectedItemsCount () {
      let count = 0;
      Object.keys(this.value).forEach(key => { if (this.value[key]) count++; });
      return count;
    },
    selectedItemsText () {
      return this.commatizeObject(this.value);
    },
    anyKeys () {
      return Object.keys(this.filteredValues).length === 0;
    },
    filteredValues () {
      if (!this.showSearch || !this.searchFilter) return this.value;
      let exp = RegExp(this.searchFilter, "ig");
      let filteredList = Object.keys(this.value)
        .reduce((acc, key) => {
          if (key.match(exp)) {
            acc[key] = this.value[key];
          }
          return acc;
        }, {});
      return filteredList;
    }
  },
  methods: {
    disabledCheck (val, item) {
      if (!this.disabledList)
        return false;
      // disable for un-checked items, checking gets blocked
      if (this.disableOnUnchecked)
        return this.disabledList.find(x => x[item] ? val ? false : true : false);

      // disable for checked items, un-checking gets blocked
      return this.disabledList.find(x => x[item] === val);
    },
    toggleMenu (e) {
      this.showMenu = !this.showMenu;
      this.$emit("click-out", this.value);
      // to prevent double toggle events with clicking toggle and the clickout, we block click out on toggle
      // can't just use stop prop because this would prevent other click out directives from triggering.
      this.blockClickOut = this.showMenu;
    },
    teleportedCancelCallback () {
      this.showMenu = false;
    },
    clickOut (e) {
      // hack to deal with the clickout directive triggering on the click that shows the menu
      if (this.blockClickOut) {
        this.blockClickOut = false;
        return;
      }
      // if we pre-emptively show the dropdown on render, block first clickout.
      if (this.show && !this.shown) {
        this.shown = true;
        return;
      }
      if (this.teleport && this.domSelf.contains(e.target)) return;

      if (this.showMenu) {
        this.showMenu = false;
        this.$emit("click-out", this.value);
      }
    },
    itemClick (event, val, item) {
      this.value[item] = !val;
      this.change();
    },
    change () {
      // check to see if the data of the dropdown unchanged
      this.$emit("update:value", this.value);
      let unchanged = true;
      Object.keys(this.value).forEach((key) => unchanged = unchanged && this.original[key] === this.value[key]);
      this.$emit("unchanged", unchanged);
    },
    commatizeObject (obj) {
      let keys = [];
      Object.keys(obj).forEach(key => { if (obj[key]) keys.push(key.replace(/_/g, " ")); });
      if (keys.length == 0) keys.push(this.placeholderText ?? "None");
      return keys.join(", ");
    },
  },
};
</script>
<style scoped>
.base-multi-select {}

.base-multi-select[disabled="true"] {
  @apply opacity-50;
  @apply pointer-events-none;
}

.base-multi-select .arrow-up {
  transform: rotate3d(1, 0, 0, 180deg);
  transition: 0.2s ease-out;
}

.base-multi-select .arrow-down {
  transition: 0.2s ease-out;
}

.base-label-container {
  position: relative;
  /* display:flex; */
  inline-size: fit-content;
  height: 2px;
  margin-bottom: -2px;
  margin-left: 0.75rem;
  margin-right: auto;
  background-color: white;
  z-index: 10;
}

.base-label {
  @apply font-roboto;
  font-style: normal;
  font-weight: 400;
  color: rgba(119, 119, 119, 1);
  /* @apply ml-2; */
  font-size: 0.75rem;
  line-height: 0.875rem;
  /* identical to box height */
  transform: translate(0%, -50%);
}

.ellipsis-overflow {
  max-width: 100%;
  @apply overflow-hidden;
  @apply overflow-ellipsis;
  @apply whitespace-nowrap;
}
</style>