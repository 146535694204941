<template>
  <!-- Document section for editing the transcripts -->
  <StandardTemplate>

    <div id="document-section" class="mb-2 mx-2 sm:mx-4 xl:ml-6 flex flex-col 4xl:max-w-[2000px]">
      <!-- Header links and actions -->
      <div class="flex justify-between mt-4 mb-2 sm:my-4 xl:my-6">
        <a class="text-main-alt hover:text-main-dark text-base hover:no-underline" @click="navBack">
          <i class="material-icons text-lg mr-2 no-underline"> west</i>
          <div class="my-auto underline font-normal text-sm font-roboto">
            Back
          </div>
        </a>
        <!-- simultaneous editing warning -->
        <div v-if="simuEditingWarning" class="hidden sm:flex xl:hidden ml-auto mr-4 items-center ">
          <span class="material-symbols-outlined text-error">
            error
          </span>
          <span class="pl-2 font-bold font-roboto">
            Another {{ isUserScribe ? "scribe" : "user" }} is currently editing this note
          </span>
        </div>
        <!-- needs attention dropdown actions for mobile -->
        <div class="flex xl:hidden my-auto text-sm text-gray-500 font-bold py-1 bg-white border rounded-md font-roboto">

          <BaseDropdown menuText="Actions" menuX="right-0">
            <template v-if="showVerifiedFeatures">
              <button id="create-issue-link"
                      class="inline-anchor text-main-alt underline font-medium"
                      @click="toggleAttentionModal">
                Note needs attention
              </button>
              <button id="create-issue-link"
                      class="inline-anchor text-main-alt underline font-medium"
                      @click="getAllIssues">
                {{ toggleShowAllIssuesMessaging ? 'Hide Resolved Issues' : 'Show All Issues' }}
              </button>
            </template>

            <button id="archive-note-link" class="inline-anchor text-main-alt underline font-medium"
                    :disabled="!userOwnsNote || loading" @click="archiveNote(!note.archived)">
              {{ note && note.archived ? "Un-Archive" : "Archive" }}
            </button>
            <a id="nav-note-link" class="inline-anchor text-center  text-main-alt underline font-medium"
               @click="navOldNoteView">
              Text View
            </a>
          </BaseDropdown>
        </div>

        <!-- needs attention actions -->
        <div class="hidden xl:flex my-auto mr-2">
          <!-- simultaneous editing warning -->
          <div v-if="simuEditingWarning" class="flex items-center mr-12 2xl:mr-20 3xl:mr-32">
            <span class="material-symbols-outlined text-error">
              error
            </span>
            <span class="pl-2 font-bold font-roboto">
              Another {{ isUserScribe ? "scribe" : "user" }} is currently editing this note
            </span>
          </div>
          <!-- needs attention actions -->
          <div class="flex whitespace-nowrap text-sm">
            <div class="my-auto">
              <span class="text-gray-500 font-bold mr-2"> Actions: </span>
            </div>
            <template v-if="showVerifiedFeatures">
              <button id="create-issue-link" class="inline-anchor text-main-alt underline font-medium"
                      @click="toggleAttentionModal">
                Note needs attention
              </button>
              <div class="border-x mx-2 my-1 sm:my-1 xl:my-1 border-gray-500" />

              <button class="inline-anchor text-main-alt underline font-medium"
                      @click="getAllIssues">
                {{ toggleShowAllIssuesMessaging ? 'Hide Resolved Issues' : 'Show All Issues' }}
              </button>
              <div class="border-x mx-2 my-1 sm:my-1 xl:my-1 border-gray-500" />
            </template>

            <button id="archive-note-link" class="inline-anchor text-main-alt underline font-medium"
                    :disabled="!userOwnsNote || loading" @click="archiveNote(!note.archived)">
              {{ note && note.archived ? "Un-Archive" : "Archive" }}
            </button>
            <div class="border-x mx-2 my-1 sm:my-1 xl:my-1 border-gray-500" />

            <a id="nav-note-link" class="inline-anchor text-center  text-main-alt underline font-medium"
               @click="navOldNoteView">
              Text View
            </a>

          </div>
        </div>
      </div>
      <!-- simultaneous editing warning -->
      <div v-if="simuEditingWarning" class="flex sm:hidden items-center justify-center my-2 ">
        <span class="material-symbols-outlined text-error">
          error
        </span>
        <span class="pl-2 font-bold font-roboto">
          Another {{ isUserScribe ? "scribe" : "user" }} is currently editing this note
        </span>
      </div>
      <!-- main note container -->
      <div class="flex flex-col xl:flex-row">
        <!-- media bar and note field container -->
        <div class="flex flex-col w-full xl:pr-5 2xl:pr-10 3xl:pr-16 order-2 space-y-3 ">

          <!-- note header section, title, state, messaging and text field -->
          <div id="note-header-section" class="transcript-container-shadow w-full border border-neutral-alt rounded-[2px] mr-12 sticky z-[2]"
               :style="noteSectionTopOffset">
            <NoteHeaderSection
              :note="note"
              @NoteTimeline:changeState="timelineStateHandler" />

            <!-- note body. Title, note info, original transcript -->
            <div v-if="note" id="note-wrapper" class="mt-5 z-[1]">
              <div id="note-heading" class="flex pl-4 xl:pl-6 xl:mx-4 justify-between">
                <div class="flex flex-col w-full">
                  <!-- title container, has all the things needed for editing -->
                  <div class="flex w-full pr-4 xl:pr-8">
                    <div v-if="editingTitle" id="title-edit-wrapper" class="relative">
                      <textarea id="edit-title-input" v-model="note.title" maxlength="80"
                                :style="`height:${titleTarget.height}px; width:${titleTarget.width + 24}px; max-width:${titleTarget.max}px;`"
                                class="title-input w-fit bg-transparent mb-2 sm:text-2xl font-bold h-8
                                      rounded-md border-main-alt box-border lg:max-w-[40rem] overflow-clip"
                                @input="trimInput">
                      </textarea>

                      <div v-if="note.title.length >= 80 && pendingTitleChange" class="error-message absolute -bottom-2">
                        Titles can be 80 characters or less
                      </div>
                      <Transition name="fade">
                        <baseLoading v-if="pendingTitleChange" style="transition-duration:200ms;" class="transform scale-[40%] h-10 absolute -right-16 bottom-0 -translate-y-1/4" />
                        <div v-else style="transition-duration:200ms;" class="material-icons absolute text-2xl -right-9 bottom-0 -translate-y-1/4">
                          check
                        </div>
                      </Transition>
                      <div id="title-target" class="invisible absolute flex text-wrap font-bold text-lg sm:text-2xl mb-2 min-h-[28px] sm:min-h-[32px]"
                           :style="titleTarget.max == 0 ? '' : `width:${titleTarget.max}px;`">
                        <div>
                          {{ note.title }}
                        </div>
                      </div>
                    </div>

                    <div v-if="!editingTitle" id="title" class="font-bold text-lg sm:text-2xl mb-2 min-h-[28px] sm:min-h-[32px]">
                      {{ note.title ? note.title : "Untitled Note" }}
                    </div>
                    <button v-if="!editingTitle" class="material-icons text-gray-500 text-2xl h-8 w-8 rounded-full hover:bg-gray-700 hover:bg-opacity-25 transition-colors"
                            @click="toggleTitleEditing">
                      edit
                    </button>
                  </div>
                  <div id="author" class="flex grow mb-2 text-sm items-center font-bold">
                    <span class="text-gray-400 font-roboto-slab">
                      by &nbsp;
                    </span>
                    <a :href="`mailto:${note.metadata.recorded_by_email}`" class="underline text-main-alt">
                      <span class="text-sm font-bold">{{ note.metadata.recorded_by_name }}</span>
                    </a>
                    <!-- copy button container -->
                    <div class="flex flex-grow justify-end mr-4 xl:mr-7 text-gray-500 pr-[2px]">
                      <CopyButton
                        text="Copy Entire Note"
                        :plaintextSubstitute="copyableFullNoteDraftMarkdown"
                        target="#text-area-rich-note" @click="updateFullNoteCopyField" @copyClick="trackCopy('full note')" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- media bar for playback, modifying the audio controls and state -->
              <div class=" sticky z-[1]">
                <div id="media-bar" class="media-bar-container w-full sm:py-2">
                  <div class="py-2 px-6 xl:pl-10 xl:pr-[3.25rem]">
                    <div :class="['media-bar-wrapper relative sm:static flex flex-wrap sm:justify-between', canPlay ? '' : 'disable-contents']">
                      <!-- 5f play/pause 5b-->
                      <div id="playback" class="flex my-auto flex-grow sm:flex-grow-0 basis-full sm:basis-0 sm:ml-0 sm:mr-4 order-1 select-none whitespace-nowrap">
                        <div class="flex h-6 mx-auto">
                          <button class="playback-icon inline mr-1 px-[0.125rem]" :disabled="!canPlay ?? undefined"
                                  title="ctrl + J" @click="changeCurrTime(-5)">
                            <img src="@/assets/images/rewind-5.svg" class="h-5 w-5 m-auto mb-1">
                          </button>
                          <button v-if="!isPlaying" class="playback-icon inline my-auto" :disabled="!canPlay ?? undefined"
                                  title="ctrl + K" @click="togglePlayback">
                            <i class="block material-icons my-auto">
                              play_arrow
                            </i>
                          </button>
                          <button v-else class="playback-icon inline my-auto" :disabled="!canPlay ?? undefined"
                                  title="ctrl + J" @click="togglePlayback">
                            <i class="material-icons my-auto">
                              pause
                            </i>
                          </button>
                          <button class="playback-icon inline ml-1 px-[0.125rem]" :disabled="!canPlay ?? undefined"
                                  title="ctrl + L" @click="changeCurrTime(5)">
                            <img src="@/assets/images/fast-forward-5.svg" class="h-5 w-5 m-auto mb-1">
                          </button>
                        </div>
                      </div>
                      <!-- playback timeline -->
                      <div id="timeline" class="flex flex-grow order-3 sm:order-2">
                        <div class="my-auto mr-4 w-12 text-neutral-alt">
                          {{ currTimeMinutes }}
                        </div>
                        <div id="playback-bar" :class="['relative my-auto rounded-md w-full h-1 bg-gray-200', canPlay ? 'cursor-pointer' : '']"
                             @mousemove="seekClip"
                             @mousedown="seekClip">
                          <div
                            :class="['flex relative h-full bg-rare-alt rounded-md ', playbackBarDragging ? 'select-none' : 'transition-all ease-linear duration-300']"
                            :style="`width:${audioConfig && audioConfig.duration > 0 ?
                              (audioConfig.currTime / audioConfig.duration) * 100 : 0}%;`">
                            <div id="playback-knob"
                                 class="bg-white h-4 w-4 rounded-full border shadow-md cursor-pointer
                                        absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2">
                            </div>
                          </div>
                        </div>
                        <div class="my-auto ml-4 w-12 text-neutral-alt">
                          {{ durationMinutes }}
                        </div>
                      </div>
                      <!-- volume controls speed -->
                      <div class="flex absolute sm:static  right-0 sm:left-0 my-auto sm:ml-4 basis-1/5 sm:basis-0 order-2 sm-order-3">
                        <div class="flex mx-auto">
                          <BaseIconDropdown class="text-xl h-6 w-6"
                                            menuX="right-0 lg:right-auto lg:left-1/2 lg:-translate-x-1/2 lg:transform"
                                            :icon="volumeIcon"
                                            :disabled="!canPlay">
                            <div id="slider-container" class="mx-4">
                              <div id="volume-slider" class="bg-gray-200 mx-auto w-28 h-1 rounded-md cursor-pointer"
                                   @mousedown="volumeChange"
                                   @mouseup="volumeChange">
                                <div id="slider" class="relative bg-rare-alt h-full rounded-md"
                                     :style="`width:${(audioConfig?.volume / 1) * 100}%;`">
                                  <div id="volume-knob"
                                       :class="['bg-white h-4 w-4 rounded-full border shadow-md cursor-pointer absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2',
                                                volumeDragging ? 'select-none' : '']" />
                                </div>
                              </div>
                            </div>
                          </BaseIconDropdown>
                          <!-- playback rate controls -->
                          <BaseIconDropdown icon="speed"
                                            class="text-xl h-6 w-6 ml-2"
                                            menuX="right-0 lg:right-auto lg:left-1/2 lg:-translate-x-1/2 lg:transform"
                                            :disabled="!canPlay">
                            <div class="flex text-sm font-bold py-2">
                              <span class="px-8">
                                Playback Rate:
                              </span>
                            </div>
                            <div class="rate-dropdown flex flex-col text-xs">
                              <button v-for="index in 7" :key="index" :class="audioConfig?.playbackRate === (index + 1) * 0.25 ? 'bg-gray-300' : ''"
                                      :data-rate="audioConfig?.playbackRate === (index + 1) * 0.25"
                                      @click="changePlaybackRate((index + 1) * 0.25)">
                                {{ ((index + 1) * 0.25) === 1 ? "Normal" : ((index + 1) * 0.25) }}
                              </button>
                            </div>
                          </BaseIconDropdown>
                        </div>
                      </div>
                      <div v-if="noteState === 'Draft'" class="absolute flex w-full h-full top-0 left-0 bg-white">
                        <div class="flex bg-white mx-auto text-xs font-bold text-center rounded-md">
                          <div class="my-auto">
                            Draft audio cannot be played back. Please submit the note to review the audio.
                          </div>
                        </div>
                      </div>
                      <div v-if="noAudio" class="absolute flex w-full h-full top-0  left-0 bg-white">
                        <div class="flex bg-white mx-auto text-xs font-bold text-center rounded-md">
                          <div class="my-auto">
                            Audio cannot be played back. There may be no audio available for this note.
                          </div>
                        </div>
                      </div>
                      <BaseLoading v-else-if="!canPlay" style="transition-duration:200ms;" class="z-50 transform scale-[50%] -translate-x-1/2 -translate-y-1/2 left-1/2 top-7 absolute" />
                    </div>
                  </div>

                </div>
                <!-- original transcript drop down -->
                <div id="original-transcript" class="flex flex-col">
                  <div class="flex justify-between items-center py-2 px-4 xl:px-11 bg-neutral-alt border-2 border-neutral-alt text-white">
                    <div class="font-extrabold text-xs">
                      Transcript (
                      <button class="underline text-main-lighter hover:text-main-light transition-colors"
                              @click="toggleShowTranscript">
                        <span class="text-sm font-bold">{{ showTranscript ? 'show less' : 'show more' }}</span>
                      </button>
                      )
                    </div>
                    <CopyButton class="font-normal text-xs" target="#text-area-original-transcript" @copyClick="trackCopy" />
                  </div>
                  <Transition name="expand" appear :duration="300"
                              @before-enter="setEnterLeaveHeight"
                              @before-leave="setEnterLeaveHeight"
                              @enter="setTransitionHeight"
                              @after-enter="transitionDone"
                              @after-leave="transitionDone">
                    <div v-if="showTranscript"
                         :class="['flex h-full w-full overflow-hidden transition-all font-normal']">
                      <div class="block h-full p-6 bg-white w-full">
                        {{ originalTranscript }}
                      </div>
                    </div>
                  </transition>
                </div>
              </div>

            </div>
          </div>

          <template v-if="note && note.id && note.note_templates.length !== 0">
            <template v-for="field in ['subjective', 'objective', 'assessment', 'plan']" :key="field">
              <div :id="`${field}-editor`" :class="`template-container-${field} transcript-container-shadow rounded-[2px]`">
                <div class="transcript-container-header capitalize">
                  {{ field }}
                  <div class="flex items-center self-end text-xs">
                    <div class="tooltip-basic relative mr-2">
                      <div class="tooltip-text text-center whitespace-nowrap">
                        This section is accurate
                      </div>
                      <button class="material-symbols-outlined thumb up"
                              :data-thumbed="selectedTemplate.ratings[field]"
                              @click="(e) => upThumb(e, field)">
                        thumb_up
                      </button>
                    </div>
                    <div class="tooltip-basic relative mr-2">
                      <div class="tooltip-text text-center  whitespace-nowrap">
                        This section is inaccurate
                      </div>
                      <button class="material-symbols-outlined thumb down" :data-thumbed="selectedTemplate.ratings[field]"
                              @click="(e) => downThumb(e, field)">thumb_down</button>
                    </div>

                    <CopyButton
                      :target="`#pm-editor-${field}`"
                      :plaintextSubstitute="copyableDraftMarkdown(field)"
                      @copyClick="trackCopy(field)" />
                  </div>

                </div>
                <div class="section-text-area">
                  <RichTextField :id="field"
                                 :isPlaying="isPlaying"
                                 :editable="enableEditing"
                                 :transcriptString="selectedTemplate[field]"
                                 :noteState="note.state"
                                 @focus="() => focusedField = true"
                                 @blur="focusedField = false"
                                 @textChangeEvent="checkState"
                                 @transcriptEditedEvent="(text) => handleTranscriptChange(field, text)" />
                </div>
              </div>

            </template>

            <!-- this needs to not be hidden in some sort of way that isn't display:none;  theres a few redundant ways that this is hidden.
              Text areas are position:absolute for browser reasons I guess. the text will not select and copy without it -->
            <div class=" relative opacity-0 overflow-hidden h-0 w-full">
              <textarea id="text-area-full-note" v-model="copyableNote" class="absolute">
                </textarea>
              <div id="text-area-rich-note"
                   class="absolute bg-white bg-opacity-0">
              </div>
              <textarea id="text-area-original-transcript" v-model="originalTranscript" class="absolute">
              </textarea>
            </div>
          </template>

        </div>
        <div class="flex flex-col order-1 xl:order-2 xl:min-w-[22rem] xl:max-w-[22rem] 2xl:min-w-[24rem] 2xl:max-w-[24rem]">
          <div class="flex flex-col xl:mr-2">
            <NoteTimeline :note="note"
                          :isUserVerified="isUserVerified"
                          @NoteTimeline:respondIssue="respondIssue"
                          @NoteTimeline:resolveIssue="resolveIssue"
                          @NoteTimeline:deleteIssue="deleteIssue"
                          @NoteTimeline:changeState="timelineStateHandler" />
            <!-- notes to scribe -->
          </div>
          <div v-if="windowWidth >= 1200" class="flex flex-col my-3 xl:mt-0 xl:mr-2">
            <BaseBox v-if="isUserScribe" title="Additional Notes" class="xl:min-h-[31.25rem]">
              <template v-if="note && preferences">
                <BaseSelectList label="Preferred Localization"
                                :initialValue="preferences.language_code"
                                placeholderText="" :list="languageSelection"
                                @select="selectLanguagePreference">
                </BaseSelectList>
                <BaseTextArea v-model="preferences.text"
                              class="h-80 mt-2" placeholderText="Doctor's note preferences"
                              @input="updatePreferences"
                              @focus="focusedField = true"
                              @blur="focusedField = false" />
                <!-- <BaseInput v-model="note.metadata.patient" label="Patient" placeholderText="Patient"
                          @input="updateMetadata"
                          @focus="focusedField = true"
                          @blur="focusedField = false" />
                <BaseInput v-model="note.metadata.owner" label="Owner" placeholderText="Owner"
                          @input="updateMetadata"
                          @focus="focusedField = true"
                          @blur="focusedField = false" />
                <BaseTextArea v-model="note.metadata.note_to_editor" class="h-64 mt-2" placeholderText="Memo to Scribe"
                              @input="updateMetadata"
                              @focus="focusedField = true"
                              @blur="focusedField = false" />-->
                <!-- loading/saving animation -->
                <div class="relative flex flex-row-reverse">
                  <TransitionGroup name="fade">
                    <i v-if="mdSaveState == 'done'"
                       :key="0" style="transition-duration:200ms;" class="material-icons select-none -top-1 -right-5 lg:-right-4 absolute">
                      done
                    </i>
                    <BaseLoading v-if="mdSaveState == 'pending'"
                                 :key="1"
                                 style="transition-duration:200ms;" class=" transform scale-[40%] translate-x-1/3 -top-1 -right-5 lg:-right-4 absolute"
                    />
                  </TransitionGroup>
                </div>
              </template>
            </BaseBox>
          </div>
        </div>
      </div>
    </div>
    <BaseModal id="create-issue-modal" title="Note Needs Attention" :toDisplay="showAttentionModal" @toggleEvent="toggleAttentionModal">
      <div class="max-w-lg lg:w-128">
        <div class="px-4 sm:px-7 pt-7 font-roboto">
          <div class="py-4 text-gray-500 text-base font-medium">
            Describe the issue this note has so that the author can <br class="hidden sm:block">
            resolve the problem and you can complete your review.
          </div>

          <div :class="['pt-4 pb-3 flex flex-col transition-[height] duration-300 ease-in-out',
                        selectedProblem == 999 ? 'h-[10.5rem]' : 'h-[4.5rem]']">
            <BaseSelectList :list="problemsList" initialValue="1" @select="(val) => selectedProblem = val" />
            <BaseInput v-if="selectedProblem == 999" v-model="otherProblem"
                       class="pt-6" label="Other:" placeholderText="Please specify" />
          </div>
          <BaseTextArea v-model="problemDescription"
                        class="h-56 text-sm font-normal" placeholderText="Describe the issue"
          />
        </div>
        <div class="flex modal-end px-7 py-4 ">
          <div class="flex justify-end flex-grow">
            <button class="cancel-button" @click="toggleAttentionModal">
              CANCEL
            </button>
            <button
              :disabled="submittedNeedsAttn || problemDescription === '' || (selectedProblem == 999 && otherProblem === '')"
              class="bg-main-alt hover:bg-main text-white rounded-lg text-xs py-2 px-3 transition-colors"
              @click="SubmitIssue">
              SUBMIT ISSUE
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  </StandardTemplate>

</template>

<script src="./TemplatedNoteView.js">
</script>
<style src="./TemplatedNoteView.css" scoped></style>