<template>
  <div v-click-outside="() => { showActions = false; }"
       class="relative flex items-center  pl-2.5 pr-1.5 text-sm font-roboto text-[#9BACB2] rounded-xl border border-[#9BACB2]
            bg-white hover:bg-gray-100 transition-colors" role="button"
       @click="showActions = !showActions">
    <input
      v-model="checkCopy"
      type="checkbox"
      class="tk-check minus"
      @click.stop="checkClick(checkCopy)">

    <span class="px-2">
      Actions
    </span>
    <span :class="['material-icons select-none pointer-events-none transform  transition-transform',
                   showActions ? 'flip-z' : '']">
      keyboard_arrow_down
    </span>
    <!-- menu -->
    <Transition name="fade">
      <div v-if="showActions"
           class="default-fade-duration absolute z-20 rounded-md bg-white border border-gray-200 transform
             -bottom-0.5 translate-y-full left-0">
        <div>
          <slot>
          </slot>
        </div>
      </div>

    </Transition>

  </div>

</template>
<script>

export default {
  name: "ActionMenu",
  emits: ["check-click"],
  components: {
  },
  props: { checkVal: Boolean },
  data () {
    return {
      showActions: false,
      checkCopy: false,
    };
  },
  watch: {
    checkVal (newVal, oldVal) {
      this.checkCopy = newVal;
    }
  },
  methods: {
    checkClick (val) {
      this.$emit("check-click", val);
    }
  }
};

</script>