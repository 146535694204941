<template>
  <BaseModal class="modal-container"
             title="Update Billing Information"
             :toDisplay="toDisplay"
             @toggleEvent="toggleModal">

    <div v-if="loading" class="absolute w-full h-full z-50 bg-black bg-opacity-10">
      <BaseLoading class="z-50 m-auto" />
    </div>
    <div class="block pb-6 pt-8 max-w-xl lg:max-w-2xl">
      <template v-if="subscription.stripe_id">
        <StripeForm
          :loading="loading"
          :billingAddress="subscription.payment_profile.billing_address"
          :countryList="countryList"
          :subscription="subscription"
          @toggleLoading="loading = !loading"
          @cancelEvent="toggleModal"
          @validTokenEvent="updateStripeToken"
        />
      </template>
      <template v-else>

        <ChargifyForm
          :disableButton="loading"
          :billingAddress="subscription.payment_profile.billing_address"
          :countryList="countryList"
          @cancelEvent="toggleModal"
          @validTokenEvent="updateChargifyToken"
          @toggleLoading="loading = !loading" />
      </template>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "Components/ui/BaseModal.vue";
import ChargifyForm from "./ChargifyForm/ChargifyForm.vue";
import StripeForm from "./StripeForm/StripeForm.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";

export default {
  name: "BillingModal",
  components: { BaseModal, ChargifyForm, StripeForm, BaseLoading },
  props: ["toDisplay", "subscription", "countryList"],

  data () {
    return {
      chargifyToken: undefined,
      stripeToken: undefined,
      title: "Update Your Payment Information",
      loading: false,
    };
  },
  watch: {
    toDisplay (newVal) {
      // makes sure when the modal is shown we will not have the loading animation on modal re-open
      this.loading = !newVal;
    },
  },
  methods: {
    toggleModal: function () {
      this.$emit("billingToggleEvent", this.toDisplay);
    },
    updateChargifyToken (event) {
      this.loading = true;
      this.chargifyToken = event.token;
      this.$emit("updateEvent", { productId: this.subscription.product.product_id, chargifyToken: this.chargifyToken });
    },
    updateStripeToken (event) {
      this.loading = true;
      this.stripeToken = event.token;
      this.$emit("updateEvent", { stripeToken: this.stripeToken });
    },
  },
};
</script>
<style scoped>
.bottom-border {
  border-bottom: solid 1px;
  @apply border-gray-400;
}
.modal-container {
  width: calc(100vw - 2rem);
  position: absolute;
  @apply mx-2;
}
@media (max-width: 640px) {
  .modal-container {
    width: auto;
  }
}
</style>