<template>
  <FadeInWrapper>

    <div id="spoof"
         class="pb-4 flex flex-col bg-white min-h-[60vh] rounded-t-md lg:rounded-t-0">
      <div class="heading flex-row mb-5">
        <div class="flex flex-grow justify-between 2xl:max-w-[1240px] 3xl:max-w-[1240px] 4xl:max-w-[1403px]">
          <div class="my-auto text-3xl font-bold">
            Super User Spoof (Top Secret 🤫)
          </div>
          <div class="flex flex-col-reverse lg:flex-row justify-center lg:h-full text-sm font-bold ">

          </div>
        </div>

      </div>
      <div class="body-container flex flex-col 2xl:max-w-[1280px] 4xl:max-w-[1442px]">

        <div class="flex flex-col-reverse lg:flex-row w-fit rounded-md border border-[#9BACB2] p-4">
          <div class="block pr-4">
            <div class="flex flex-col ">
              <div class="inline-block">
                Input the subscription id of the subscription <br /> that you wish to log into.
              </div>
              <BaseInput v-model="subscriptionId" class="w-[360px]" label="Subscription Id:" type="number" :disabled="loading" :min="1" :max="Number.MAX_SAFE_INTEGER" @enter="spoof" />
              <div class="flex">
                <button class="btn-primary py-2 px-6 rounded-full font-bold"
                        :disabled="loading || !isSubIdValid" @click="spoof">
                  Submit
                </button>
                <transition name="fade">
                  <div v-if="loading" class="relative default-fade-duration h-full">
                    <BaseLoading class="absolute transform scale-[40%]" />
                  </div>
                </transition>
              </div>


              <div v-if="success" class="mt-2">
                <span class="font-bold text-rare2 text-base">success!</span>
                <div class="flex">
                  <RouterLink to="/users" class="px-3 py-2 mr-2 btn-primary rounded-full">
                    Manage Users
                  </RouterLink>
                  <RouterLink to="/billing" class="px-3 py-2 btn-primary rounded-full">
                    Manage Billing
                  </RouterLink>
                </div>
              </div>
              <div v-if="error" class="text-error mt-2">
                error!
                {{ error }}
              </div>
            </div>
          </div>
          <div class="flex flex-col mb-4 max-w-[512px]">

            <div class="inline-block font-semibold text-base mt-1">
              Info:
            </div>
            <ul class="inline-block">
              <li>All of your actions will be logged while squatting on a subscription.</li>
              <li>Once you have finished with the subscription squat, log out of the subscription.</li>
              <li>You will have full permissions on the subscription to edit users, change billing.</li>
              <!-- 5404099650519040 -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </FadeInWrapper>


</template>

<script>

import { computed } from 'vue';
import UserAuth from "Services/UserAuth";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BaseInput from "Components/ui/BaseInput.vue";
import FadeInWrapper from 'Components/layout/FadeInWrapper.vue';
import jwtDecode from 'jwt-decode';
import SubscriptionService from "Services/SubscriptionService";
import { data } from 'autoprefixer';

export default {
  name: "Templated Note View",
  components: {
    BaseInput, BaseLoading, FadeInWrapper,
  },
  data () {
    return {
      loading: false,
      subscriptionId: NaN,
      success: false,
      error: undefined,
    };
  },
  created () {
    if (!this.isUserSuper) this.$router.push('/');
  },
  computed: {
    isUserSuper () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Subscription Manager") !== -1;
    },
    isSubIdValid () {
      return !isNaN(this.subscriptionId) && this.subscriptionId !== "" && this.subscriptionId < Number.MAX_SAFE_INTEGER && this.subscriptionId > 0;
    }
  },
  methods: {
    spoof () {
      if (this.loading || !this.isSubIdValid) return;
      let userId = this.$store.getters.getUserId;
      let clientId = new Date().getTime().toString();

      this.loading = true;
      UserAuth.Spoof(userId, parseInt(this.subscriptionId), clientId)
        .then(resp => {
          let decodedJWT = jwtDecode(resp.data.jwt);
          this.$store.
            commit('auth_success',
              { token: resp.data.jwt, decodedToken: decodedJWT, clientId: clientId }
            );
          // call this to update the saved ref to the subscription
          SubscriptionService.GetSubscription(decodedJWT.user.subscription_id)
            .then(() => {
              this.success = true;
            })
            .catch(err => {
              if (err.status == 404) {
                this.success = false;
                this.error = "subscription doesn't exist.";
              } else {
                this.error = "squatted but there was an error: " + err.data.message;
              }
            });
          this.error = undefined;
        })
        .catch(resp => {
          console.log(resp);
          this.error = "failed to spoof: " + resp.data.message;
          this.success = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped></style>