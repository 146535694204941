import UserAuth from "Services/UserAuth";
const UserService = require('Services/UserService');
import jwtDecode from 'jwt-decode';
import { createStore } from 'vuex';
import router from './router';
import { WebsocketEventHandler } from "Modules/Websocket.js";
import MixpanelService from "Services/MixpanelService";

export const store = createStore({
  state () {
    return {
      status: { isLoggedIn: false },
      //token preserved in store for non-cookie using users
      token: '',
      userId: parseInt(localStorage.getItem('userId')) || '',
      industry: localStorage.getItem('industry') || '',
      emailConfirmed: false,
      subscriptionId: parseInt(localStorage.getItem('subscriptionId')) || '',
      subscriptionState: localStorage.getItem('subscriptionState') || '',
      userPerms: localStorage.getItem('userPerms') ? JSON.parse(localStorage.getItem('userPerms')) : {},
      //reauth keys
      clientId: localStorage.getItem('clientId') || '',
      //from user endpoint
      userProfile: {},
      profilePromise: undefined,
      userGroups: [],
      featureFlags: localStorage.getItem('featureFlags') ? JSON.parse(localStorage.getItem('featureFlags')) : {},
      lastVerifiedState: localStorage.getItem('lastVerifiedState') ? JSON.parse(localStorage.getItem('lastVerifiedState')) : false,
      //initial value, clientId is set for when a user auths and persists for the duration of the session
      canAttemptRefresh: localStorage.getItem('clientId') != undefined,
      subscription: {},
      landing: {},
      // default formatter, set on subscription update
      currencyFormatter: new Intl.NumberFormat('en'),
      isSsoUser: localStorage.getItem('isSsoUser') ? localStorage.getItem('isSsoUser') === 'true' : false,
      websocketEventHandler: undefined,
      notesView: localStorage.getItem('notesView') ? localStorage.getItem('notesView') : 'table',

      billingProvider: ''
    };
  },
  mutations: {
    auth_success: (state, { token, decodedToken, clientId }) => {

      state.status = { isLoggedIn: true };
      state.token = token;
      state.userId = decodedToken.user.id;
      state.industry = decodedToken.user.industry;
      state.emailConfirmed = decodedToken.user.email_confirmed;
      state.subscriptionId = decodedToken.user.subscription_id;
      state.subscriptionState = decodedToken.user.subscription_state;
      state.userPerms = decodedToken.user.permissions;
      state.billingProvider = decodedToken.user.billing_provider;
      // Saml groups
      state.userGroups = decodedToken.user.user_groups;
      state.isSsoUser = decodedToken.user.user_groups?.length >= 0;
      // array to object for easier reference to flags. This is what we store and and use throughout the app
      state.featureFlags = decodedToken.user.feature_flags?.reduce((prop, val) => { prop[val] = true; return prop; }, {}) ?? {};
      if (__ENV === "development") {
        state.featureFlags = { llmtesting: true, spybot: true };
      }

      //clientId is unique to the userSession on the particular device
      //to allow refresh auth request without resetting everywhere a user has an active session
      state.clientId = clientId;
      localStorage.setItem('userId', decodedToken.user.id);
      localStorage.setItem('industry', decodedToken.user.industry);
      localStorage.setItem('subscriptionId', decodedToken.user.subscription_id);
      localStorage.setItem('subscriptionState', decodedToken.user.subscription_state);
      localStorage.setItem('userPerms', JSON.stringify(decodedToken.user.permissions));
      localStorage.setItem('featureFlags', JSON.stringify(state.featureFlags));
      localStorage.setItem('clientId', clientId);
      localStorage.setItem('isSsoUser', state.isSsoUser);
      state.canAttemptRefresh = true;

      if (state.websocketEventHandler)
        state.websocketEventHandler.connect(token, decodedToken.user.id);
      else
        state.websocketEventHandler = new WebsocketEventHandler(token, decodedToken.user.id);

      if (__ENV !== 'test' && __ENV !== 'development'
        && state.featureFlags.spybot
        && decodedToken.user.user_groups
        && -1 !== decodedToken.user.user_groups.findIndex(x => x === 'Scribe')) {
        (function (c, l, a, r, i, t, y) {
          c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
          t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
          y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "irj3ztor9c");
      }
    },
    auth_failure: (state) => {
      state.status = { isLoggedIn: false };
      state.user = {};
      state.canAttemptRefresh = false;
    },
    auth_request: (state) => {
      state.status = { authenticating: true };
      state.landing.refresh = true;
    },
    set_user_profile_name: (state, profileData) => {
      state.userProfile.firstName = profileData.firstName;
      state.userProfile.lastName = profileData.lastName;
    },
    set_user_profile: (state, profileData) => {
      state.userProfile = profileData;
    },
    set_user_Id: (state, userId) => {
      state.userId = userId;
    },
    set_subscription_id: (state, subscriptionId) => {
      state.subscriptionId = subscriptionId;
    },
    set_subscription: (state, subscription) => {

      state.lastVerifiedState = subscription.components?.some(x => x.chargify_component_handle === 'verified');
      if (state.lastVerifiedState !== undefined) localStorage.setItem('lastVerifiedState', state.lastVerifiedState);
      state.subscription = subscription;
      state.currencyFormatter = new Intl.NumberFormat('en', { style: 'currency', currency: subscription.currency });
    },
    set_client_Id: (state, clientId) => {
      state.clientId = clientId;
    },
    set_refresh_attempt: (state, canAttemptRefresh) => {
      state.canAttemptRefresh = canAttemptRefresh;
    },
    set_landing: (state, { path, query }) => {
      state.landing.path = path;
      state.landing.query = query;
    },
    landing_refresh: (state) => {
      state.landing.refresh = true;
    },
    clear_landing: (state) => {
      state.landing = {};
    },
    set_notes_view: (state, view) => {
      if (!['kanban', 'table'].includes(view)) {
        console.warn("Invalid view provided to store for set_notes_view. View not set with provided value");
        return;
      }
      state.notesView = view;
    },
    reset: (state) => {
      state.status = { isLoggedIn: false };
      state.token = '';
      state.userId = '';
      state.emailConfirmed = false;
      state.subscriptionId = '';
      state.subscriptionState = '';
      state.subscription = {};
      state.userPerms = {};
      state.featureFlags = { notes: true };
      state.user = {};
      state.userProfile = {};
      state.clientId = '';
      state.canAttemptRefresh = false;
      state.currencyFormatter = new Intl.NumberFormat('en');
      state.billingProvider= '';
      // reset local storage
      localStorage.removeItem("remindLater");
      localStorage.removeItem('userId');
      localStorage.removeItem('industry');
      localStorage.removeItem('userPerms');
      localStorage.removeItem('featureFlags');
      localStorage.removeItem('subscriptionId');
      localStorage.removeItem('subscriptionState');
      localStorage.removeItem('clientId');

      if (state.websocketEventHandler) {
        state.websocketEventHandler.close();
      }
      if (window.clarity) window.clarity("stop");
    }
  },
  actions: {
    // landing redirect action handles users landing on a site that would redirect them
    // but we should attempt to send them along their way should they have a SUT and/or a specified path
    landing_redirect ({ commit, dispatch, state }, { path, query }) {
      // first eval if the user was trying to get to a page which requires auth. if not (login, etc) we can set path to '/' to avoid a login attempt that redirect to a page which doesn't require auth.

      if (path.length > 0 && path.substring(0,1) != '/'){
        path = '/' + path;
      }
      let route = router.getRoutes().find(x => x.path == path);
      if (route && (route.meta && !route.meta.requiresAuth)) {
        path = '/notes';
      }
      // * Attempt Sut login
      if (query.token && query.user_id) {
        commit("auth_request");
        return dispatch("SUT_login", { token: query.token, userId: query.user_id })
          .then(() => dispatch("loadUserProfile"))
          .then(() => {
            // clears url, sends to requested page
            query.token = undefined; query.user_id = undefined;
            commit("clear_landing");
            router.push({ path: path, query: query }).catch(err => { console.log(err); });
          })
          .catch((e) => {
            console.log("failure: provided sut token not valid", e);
            router.push("/login").catch(err => { console.log(err); });
          });
      }
      // * SSO
      if (query.user_id) {
        commit('set_user_Id', query.user_id);
        commit("set_landing", { path, query });
        return dispatch("refresh")
          .then(() => {
            commit("clear_landing");
            dispatch("loadUserProfile");
          })
          .then(() => {
            query.user_id = undefined;
            router.push({ path: path, query: query }).catch(err => { console.log(err); });
          })
          .catch((err) => {
            console.log(err);
            commit("reset");
            router.push("/login").catch(err => { console.log(err); });
          });
      }
      // * Attempt refresh if theres no sut and SSO Data data
      if (state.canAttemptRefresh) {
        commit("set_landing", { path, query });
        return dispatch("refresh")
          .then(() => dispatch("loadUserProfile"))
          .catch((err) => {
            console.log(err);
            commit("reset");
            router.push("/login").catch(err => { console.log(err); });
          });
        // * User Requires login
      } else {
        commit("set_landing", { path, query });
      }
    },

    // login action initiated by user
    login ({ commit, dispatch, state }, { email, password }) {
      // toggle bool for authenticating
      commit('auth_request');
      // use current timestamp for a client_id
      const clientId = Date.now().toString();
      //return promise from auth request.
      return UserAuth.Login(email, password, clientId)
        .then(resp => {
          let decode = jwtDecode(resp.data.jwt);
          commit('auth_success', { token: resp.data.jwt, decodedToken: decode, clientId: clientId });
          // reroute to initially requested path
          if (decode.user.subscription_state === "provisioning") {
            router.push('/getting-started').catch(err => { console.log(err); });
          } else if (!state.landing.path) {
            router.push('/notes').catch(err => { console.log(err); });
            // edge case for profile, we need to load the profile before sending the user to profile
          } else if (state.landing.path && state.landing.path != "profile") {
            router.push({ path: state.landing.path, query: state.landing.query })
              .then(() => commit("clear_landing"))
              .catch(err => { console.log(err); });
          }
          dispatch('loadUserProfile')
            .then(profile => {
              commit('set_user_profile', profile);
              if (state.landing.path && state.landing.path == "profile") {
                router.push({ path: state.landing.path, query: state.landing.query })
                  .then(() => commit("clear_landing"))
                  .catch(err => { console.log(err); });
              }
              /* MP Track on login, people visiting our site while provisioning */
              if (state.subscriptionState === "provisioning") {
                MixpanelService.Track("AdminPortal:ProvisioningVisit", {});
              }
            });
          return resp;
        })
        .catch(err => {
          commit('auth_failure', err);
          return Promise.reject(err);
        });
    },

    // logout action initiated by user
    logout ({ commit, state }) {
      if (this.getters.isLoggedIn) {
        return UserAuth.Logout()
          .catch(err => {
            console.log('logout error: ' + err);
          })
          .finally(() => {
            commit('reset');
            if (state.isSsoUser) {
              router.push("/login/sso");
            } else {
              router.push("/login");
            }
          });
      }
    },

    // SUT_login is initiated whenever a user lands on the website and has tokens in their URL
    SUT_login ({ commit }, { token, userId }) {
      const clientId = Date.now().toString();

      return UserAuth.ValidateSUT(token, userId, clientId)
        .then(resp => {
          const decode = jwtDecode(resp.data.jwt);
          commit('auth_success', { token: resp.data.jwt, decodedToken: decode, clientId: clientId });
          return Promise.resolve(resp);
        })
        .catch(err => {
          commit("auth_failure");
          return Promise.reject(err);
        });
    },

    // refresh initiated when a user lands on the website and has previous session
    refresh ({ commit, state }) {
      if (this.getters.isLoggedIn && this.getters.getCanAttemptRefresh) {
        commit('auth_request');
        commit('set_refresh_attempt', false);

        return UserAuth.Refresh(state.userId, state.clientId)
          .then(resp => {
            const decode = jwtDecode(resp.data.jwt);
            commit('auth_success', { token: resp.data.jwt, decodedToken: decode, clientId: this.state.clientId });
            return Promise.resolve(resp);
          })
          .catch(err => {
            commit("reset");
            router.push("/login").catch(err => { console.log(err); });
            return Promise.reject(err);
          });
      }
    },

    // loadUserProfile pulls
    loadUserProfile ({ commit, state }) {
      if (!this.getters.isLoggedIn) {
        return Promise.reject("user not logged in, cannot request for profile data");
      }

      let profilePromise = Promise.all([UserService.GetUser(), UserService.GetUserProfile()])
        .then(resp => {
          if (resp[1].data.profile_photo == '') {
            let defaultPhoto = `profile-${parseInt(state.userId / 2) % 20 + 1}.png`;
            resp[1].data.profile_photo = defaultPhoto;
          }
          let userProfile = {
            firstName: resp[1].data.first_name,
            lastName: resp[1].data.last_name,
            jobTitle: resp[1].data.job_title,
            phoneNumber: resp[1].data.phone_number,
            profilePhoto: resp[1].data.profile_photo,
            email: resp[0].data.email,
          };
          commit('set_user_profile', userProfile);
          return Promise.resolve(userProfile);
        })
        .catch(err => {
          console.log('loadUserProfile error ', err);
          return Promise.reject(err);
        })
        .finally(() => {
          state.profilePromise = undefined;
        });
      state.profilePromise = profilePromise;
      return profilePromise;
    },

    updateUserProfile ({ commit }, profileData) {
      if (this.getters.isLoggedIn) {
        return UserService.UpdateUserProfile(profileData)
          .then(resp => {
            // response does not contain email
            let profileName = {
              firstName: resp.data.first_name,
              lastName: resp.data.last_name
            };
            // Just set the name
            commit('set_user_profile_name', profileName);
            return Promise.resolve(resp);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }
    }
  },
  getters: {
    getState: state => state,
    isLoggedIn: state => !!state.clientId,
    authStatus: state => state.status,
    getUserId: state => state.userId,
    getIndustry: state => state.industry,
    getEmailConfirmation: state => state.emailConfirmed,
    getSubscription: state=> state.subscription, // not explicitly retrieved on page load but it is set on any request to get the subscription
    getSubscriptionId: state => state.subscriptionId,
    getSubscriptionState: state => state.subscriptionState,
    getClientId: state => state.clientId,
    getToken: state => state.token,
    getUserPerms: state => state.userPerms,
    getUserGroups: state => state.userGroups,
    isSsoUser: state => state.isSsoUser,
    getFeatureFlags: state => state.featureFlags,
    getUserProfile: state => state.userProfile,
    getCanAttemptRefresh: state => state.canAttemptRefresh,
    getLandingData: state => state.landing,
    getWebsocketEventHandler: state => state.websocketEventHandler,
    getNotesView: state => state.notesView,
    getBillingProvider: state => state.billingProvider,
    // subscription state helpers
    isTrialing: state => state.subscription.subscription_state === 'trialing' && state.subscription.payment_profile.masked_card_number === "",
    isTrialEnded: state => state.subscription.subscription_state === 'trial_ended',
    isActive: state => state.subscription.subscription_state === 'active' || (state.subscription.subscription_state === 'trialing' && state.subscription.payment_profile.masked_card_number !== ""),
    isCancelled: state => state.subscription.subscription_state === 'cancelled',
    isPastDue: state => state.subscription.subscription_state === 'past_due',
    currencyFormatter: state => state.currencyFormatter,
    subHasVerifiedSeats: state => state?.subscription.stripe_id ? state.subscription.verified_seats != 0 : state?.subscription?.components == undefined ? state.lastVerifiedState : state?.subscription?.components?.some(x => x.chargify_component_handle === 'verified'),
  }
});