import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";

import { Slice, Fragment } from 'prosemirror-model';
import {
  // schema,
  defaultMarkdownParser, MarkdownParser,
  defaultMarkdownSerializer, MarkdownSerializer
} from "prosemirror-markdown";
// can be modified to get a decent start at an editor
// https://github.com/prosemirror/prosemirror-example-setup
import { exampleSetup } from "prosemirror-example-setup";
import { mySchema } from "./Schema";
// import { tokenizerParagraph } from "./Paragraph.js";

// flattens the parser tree for debug console.log(recurse(parser.parse(someText)))

// Function to handle Tab key for indentation
function handleTabIndentation (state, dispatch, view) {
  dispatch(state.tr.insertText("\t", state.tr.curSelection.$from.pos, state.tr.curSelection.$to.pos));
  return true;
}

const tabIndentPlugin = keymap({
  Tab: handleTabIndentation
});

var recurse = (node) => {
  node.t = node?.type?.name;
  let childList = [node];
  if (node?.content?.content.length === 0) {
    return childList;
  }
  else if (node?.content?.content) {
    for (let i = 0; i < node.content.content.length; i++) {
      childList = childList.concat(recurse(node.content.content[i]));
    }
  }
  return childList;
};

// Define a custom tokenizer that treats \n\n as hard breaks
// take the markdownit instance from default parser and modify it
let customTokenizer = defaultMarkdownParser.tokenizer;
customTokenizer.disable(["link"]);
customTokenizer.inline.ruler.disable(["link"]);
customTokenizer.inline.ruler.disable(["linkify"]);
customTokenizer.inline.ruler.disable(["image"]);
customTokenizer.inline.ruler.disable(["autolink"]);

customTokenizer.disable(["heading"]);
customTokenizer.block.ruler.disable(["heading"]);

customTokenizer.disable(["lheading"]);
customTokenizer.block.ruler.disable(["lheading"]);

customTokenizer.disable(["code"]);
customTokenizer.block.ruler.disable(["code"]);

customTokenizer.disable(["blockquote"]);
customTokenizer.block.ruler.disable(["blockquote"]);

customTokenizer.disable(["fence"]);
customTokenizer.block.ruler.disable(["fence"]);

customTokenizer.disable(["backticks"]);
customTokenizer.inline.ruler.disable(["backticks"]);

delete defaultMarkdownParser.tokens.heading;

delete defaultMarkdownParser.tokens.code_inline;

delete defaultMarkdownParser.tokens.code_block;

delete defaultMarkdownParser.tokens.fence;

delete defaultMarkdownParser.tokens.blockquote;

delete defaultMarkdownParser.tokens.image;

delete defaultMarkdownParser.tokens.link;

// modifying the tokenizer rule for paragraphs to not remove leading tabs.
customTokenizer.block.ruler.__rules__.find(x => x.name == "paragraph").fn = function paragraph (state, startLine, endLine) {
  const terminatorRules = state.md.block.ruler.getRules('paragraph');
  const oldParentType = state.parentType;
  let nextLine = startLine + 1;
  state.parentType = 'paragraph';

  // jump line-by-line until empty one or EOF
  for (; nextLine < endLine && !state.isEmpty(nextLine); nextLine++) {
    // this would be a code block normally, but after paragraph
    // it's considered a lazy continuation regardless of what's there
    if (state.sCount[nextLine] - state.blkIndent > 3) { continue; }

    // quirk for blockquotes, this line should already be checked by that rule
    if (state.sCount[nextLine] < 0) { continue; }

    // Some tags can terminate paragraph without empty line.
    let terminate = false;
    for (let i = 0, l = terminatorRules.length; i < l; i++) {
      if (terminatorRules[i](state, nextLine, endLine, true)) {
        terminate = true;
        break;
      }
    }
    if (terminate) { break; }
  }

  const content = state.getLines(startLine, nextLine, state.blkIndent, false)
    .replaceAll(/^[^\S\t]+|[^\S\t]+$/g, ''); // trim();
  state.line = nextLine;

  const token_o    = state.push('paragraph_open', 'p', 1);
  token_o.map      = [startLine, state.line];

  const token_i    = state.push('inline', '', 0);
  token_i.content  = content;
  token_i.map      = [startLine, state.line];
  token_i.children = [];

  state.push('paragraph_close', 'p', -1);

  state.parentType = oldParentType;

  return true;
};

customTokenizer.inline.ruler.__rules__.find(x => x.name === "newline").fn = function newline (state, silent) {
  let pos = state.pos;

  if (state.src.charCodeAt(pos) !== 0x0A/* \n */) { return false; }

  const pmax = state.pending.length - 1;
  const max = state.posMax;

  // '  \n' -> hardbreak
  // Lookup in pending chars is bad practice! Don't copy to other rules!
  // Pending string is stored in concat mode, indexed lookups will cause
  // convertion to flat mode.
  if (!silent) {
    if (pmax >= 0 && state.pending.charCodeAt(pmax) === 0x20) {
      if (pmax >= 1 && state.pending.charCodeAt(pmax - 1) === 0x20) {
        // Find whitespaces tail of pending chars.
        let ws = pmax - 1;
        while (ws >= 1 && state.pending.charCodeAt(ws - 1) === 0x20) ws--;

        state.pending = state.pending.slice(0, ws);
        state.push('hardbreak', 'br', 0);
      } else {
        state.pending = state.pending.slice(0, -1);
        state.push('softbreak', 'br', 0);
      }
    } else {
      state.push('softbreak', 'br', 0);
    }
  }

  pos++;

  // skip heading spaces for next line
  while (pos < max && 0x20 == state.src.charCodeAt(pos)) { pos++; }
  state.pos = pos;
  return true;
};

// replacing this function https://github.com/markdown-it/markdown-it/blob/0fe7ccb4b7f30236fb05f623be6924961d296d3d/lib/rules_block/state_block.mjs#L119
// this function replacement represents 3 hours of stepping through code and debugging :^)
customTokenizer.block.State.prototype.skipEmptyLines = (from) => { return from; };
const customMarkdownParser = new MarkdownParser(mySchema, customTokenizer, {
  ...defaultMarkdownParser.tokens,
});

// Extend the default markdown serializer to parse <p><br/></p>  as a \n\n
const customMarkdownSerializer = new MarkdownSerializer(
  {
    ...defaultMarkdownSerializer.nodes,
    paragraph (state, node) {
      // makes sure empty paragraph tags get serialized
      if (node.childCount === 0 || node.textContent == "") {
        // node.content.forEach(x => {
        //   if (x.type?.name == "hard_break") {
        //     state.write("\n\n");
        //   }
        // });
        state.write("\n\n");
        return;
      }
      state.renderInline(node);
      state.closeBlock(node);
    },
    hard_break (state, node, parent, index) {
      for (let i = index + 1; i < parent.childCount; i++)
        if (parent.child(i).type != node.type) {
          state.write("  \n");
          return;
        }
    },
    bullet_list (state, node) {
      state.renderList(node, "", () => (node.attrs.bullet || "-") + " ");

    },
  },
  defaultMarkdownSerializer.marks
);
// handle paste handles edge case of pasting from a document types that always format paragraphs with hard breaks inline
let handlePaste = function (view, event, slice) {
  // Function to recursively remove hard_break nodes from a fragment
  const removeHardBreaks = (fragment) => {
    let filteredNodes = [];
    fragment.forEach((child) => {
      if (child.type.name !== 'hard_break') {
        // If it's not a hard_break, keep the node
        let newChild = child;

        // Recursively check and clean the content if it's a block or inline
        if (child.content) {
          newChild = child.copy(removeHardBreaks(child.content));
        }
        filteredNodes.push(newChild);
      }
    });
    return Fragment.fromArray(filteredNodes);
  };

  // Remove hard_break nodes from the pasted slice if the content comes from google docs
  let modifiedContent = slice.content;
  if (event.clipboardData.types.some(x=>x == 'application/x-vnd.google-docs-document-slice-clip+wrapped'))
    modifiedContent = removeHardBreaks(slice.content);
  const modifiedSlice = new Slice(modifiedContent, slice.openStart, slice.openEnd);

  // Replace the selection with the modified slice
  let tr = view.state.tr.replaceSelection(modifiedSlice);
  tr.updated = 5;
  view.dispatch(tr);
  // Return true to indicate that the event was handled
  return true;
};
class ProseMirrorView {
  constructor(target, content, options) {
    // console.log(content);
    // console.log(customMarkdownSerializer.serialize(customMarkdownParser.parse(content)));
    options = options ?? {};
    this.ChangeListeners = [];
    // this.parser = defaultMarkdownParser;
    this.parser = customMarkdownParser;
    // this.serializer = defaultMarkdownSerializer;
    this.serializer = customMarkdownSerializer;
    let view = new EditorView(target, {
      handlePaste,
      state: EditorState.create({
        doc: this.parser.parse(content),
        plugins: exampleSetup({ schema: mySchema, menuBar: false, }).concat(tabIndentPlugin),
      }),
      dispatchTransaction: (transaction) => {
        this.sendChangeEvent(transaction);
        let newState = view.state.apply(transaction);
        view.updateState(newState);
      },
      ...options
    });
    this.view = view;
  }
  updateContent (content) {
    this.view.updateState(EditorState.create({
      doc: customMarkdownParser.parse(content),
      plugins: exampleSetup({ schema: mySchema, menuBar: false, }).concat(tabIndentPlugin),
    }));
    // this.view.update({});
  }
  sendChangeEvent (e) {
    this.ChangeListeners.forEach(fn => { if (fn) fn(e); });
  }
  onChange (fn) {
    this.ChangeListeners.push(fn);
  }
  get content () {
    // console.log(this.serializer.serialize(this.view.state.doc));
    return this.serializer.serialize(this.view.state.doc);
  }
  focus () { this.view.focus(); }
  destroy () { this.view.destroy(); }
}

export { ProseMirrorView };