<template>
  <div>
    <div v-if="loading" class="md:w-120 lg:w-140 h-140">
      <BaseLoading class="m-auto" />
    </div>
    <div v-show="!loading" class="pt-6 max-w-[42rem]">
      <!-- card pictures -->
      <form id="chargify-form" class="payment-form mx-12">

        <!-- credit card field -->
        <div class="flex w-full mb-4 lg:mb-2">
          <div id="credit-card" class=" w-full h-20"></div>
        </div>

        <!-- expiration and cvv -->
        <div class="inline-flex justify-between w-full mb-5 lg:mb-2">
          <div id="security-code" class="h-20"></div>
          <div id="month" class="h-20 mx-4"></div>
          <div id="year" class="h-20"></div>
        </div>

        <!-- first and last name -->
        <div class="inline-flex w-full mb-2">
          <BaseInput v-model="firstName.value"
                     class="w-full mr-4" label="First Name *" :errorMessage="firstName.error" :placeholderText="'First Name'" type="text" required @input="(e)=>fieldChange(e,'firstName')"
          />
          <BaseInput v-model="lastName.value"
                     class="w-full" label="Last Name *" :errorMessage="lastName.error" :placeholderText="'Last Name'" type="text" required @input="(e)=>fieldChange(e,'lastName')"
          />
        </div>

        <!-- billing address -->
        <div class="inline-flex w-full mb-2">
          <BaseInput v-model="address1.value"
                     class="w-full" label="Address *" :errorMessage="address1.error" :placeholderText="'Address'" type="text" required @input="(e)=>fieldChange(e,'address1')"
          />
        </div>
        <div class="inline-flex w-full">
          <BaseInput v-model="address2.value"
                     class="w-full" label="Address 2 (Optional)" :errorMessage="address2.error" :placeholderText="'Apt 2, Suite 6'" type="text" @input="(e)=>fieldChange(e,'address2')"
          />
        </div>

        <!-- country  -->
        <div class="inline-flex w-full mb-4 pt-4">
          <BaseSelectList class="w-full"
                          label="Country *" :list="countryList" :initialValue="country.value" :errorMessage="country.error" placeholderText="Select a Country" @select="countryChange" @input="(e)=>fieldChange(e,'country')"
          />
        </div>

        <!-- state -->
        <div v-if="showStatePicker" class="inline-flex w-full mb-2">
          <BaseSelectList :class="['w-full', 'mt-2', stateList.length == 0 ? 'disabled' : '']"
                          label="State *" :list="stateList" :initialValue="state.value" :errorMessage="state.error" placeholderText="Select a State" @select="stateChange"
          />
        </div>
        <div v-if="!showStatePicker" class="inline-flex w-full mb-2">
          <BaseInput v-model="state.value"
                     class="w-full" label="State *" :errorMessage="state.error" :placeholderText="'State'" type="text" required @input="(e)=>fieldChange(e,'state')"
          />
        </div>
        <!-- billing city and zip -->
        <div class="inline-flex w-full mb-2">
          <BaseInput v-model="city.value"
                     class="w-full mr-4" label="City *" :errorMessage="city.error" :placeholderText="'City'" type="text" required @input="(e)=>fieldChange(e,'city')"
          />
          <BaseInput v-model="zip.value"
                     class="w-full" label="Zip Code *" :errorMessage="zip.error" :placeholderText="'Zipcode'" type="text" required @input="(e)=>fieldChange(e,'zip')"
          />
        </div>

        <!-- hidden host fields that chargify uses to take in results-->
        <div class="host-field" hidden>
          <input id="first-name" type="hidden" class="host-input" data-chargify="firstName" :value="firstName.value" />
        </div>
        <div class="host-field" hidden>
          <input id="last-name" type="hidden" class="host-input" data-chargify="lastName" :value="lastName.value" />
        </div>
        <div class="host-field" hidden>
          <input id="billing-address1" type="hidden" class="host-input" data-chargify="address" :value="address1.value" />
        </div>
        <div class="host-field" hidden>
          <input id="billing-address2" type="hidden" class="host-input" data-chargify="address2" :value="address2.value" />
        </div>
        <div class="host-field" hidden>
          <input id="billing-country" type="hidden" class="host-input" data-chargify="country" :value="country.value" />
        </div>
        <div class="host-field" hidden>
          <input id="billing-state" type="hidden" class="host-input" data-chargify="state" :value="state.value" />
        </div>

        <div class="host-field" hidden>
          <input id="billing-city" type="hidden" class="host-input" data-chargify="city" :value="city.value" />
        </div>
        <div class="host-field" hidden>
          <input id="billing-zipcode" type="hidden" class="host-input" data-chargify="zip" :value="zip.value" />
        </div>
      </form>
      <!-- general error message from the form input -->
      <div v-show="errorMessage" class="h-8 lg:h-4 mx-8 text-center text-error">
        {{ errorMessage }}
      </div>
      <!-- form input -->
      <div class="inline-flex w-full justify-end modal-end pt-4 px-6 ">
        <a class="lg:h-8 cancel-button" @click="cancelEvent">
          <template v-if="back">
            <i class="material-icons">keyboard_arrow_left</i><span class="text-xs">BACK</span>
          </template>
          <template v-else>
            <span> CANCEL </span>
          </template>
        </a>
        <input
          class="text-xs text-white bg-main-dark rounded-md px-6 center float-right lg:h-8"
          type="submit"
          :value="continueText ?? 'Continue'"
          :disabled="disableButton ?? undefined"
          @click="handleChargifyFormSubmit"
        />
      </div>
    </div>
  </div>
</template>
<script src="./ChargifyForm.js"></script>
<style src="./ChargifyForm.css" ></style>

