import axios from 'axios';
import { store } from '../store';

const SERVICE_URL_CGFY = __API_URL_V1 + 'products';
const SERVICE_URL_STRIPE = __API_URL_V2 + 'products';

const SERVICE_URL = () => {
  if (store.getters.getBillingProvider === "stripe") {
    return SERVICE_URL_STRIPE;
  }
  return SERVICE_URL_CGFY;
};
const GetProducts = () => {
  return axios({
    method: 'GET',
    url: SERVICE_URL(),
  });
};

const GetComponents = () => {
  return axios({
    method: 'GET',
    url: SERVICE_URL_CGFY + "/components",
  });
};

const GetComponentPricePoints = (componentHandle) => {
  return axios({
    method: 'GET',
    url: `${SERVICE_URL_CGFY}/components/${componentHandle}/price_points`,
  });
};
// same call as GetComponentPricePoints but static users component provided
const GetComponentUsersPricePoints = () => {
  return axios({
    method: 'GET',
    url: `${SERVICE_URL_CGFY}/components/users/price_points`,
  });
};

// same call as GetComponentPricePoints but static users component provided
const GetComponentVerifiedPricePoints = () => {
  return axios({
    method: 'GET',
    url: `${SERVICE_URL_CGFY}/components/verified/price_points`,
  });
};

export {
  GetProducts,
  GetComponents,
  GetComponentPricePoints,
  GetComponentUsersPricePoints,
  GetComponentVerifiedPricePoints,
};