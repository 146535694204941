import SubscriptionService from "Services/SubscriptionService";
const DocumentService = require('@/services/DocumentService.js');
import BaseTable from "Components/ui/BaseTable/BaseTable.vue";
import StandardTemplate from "Components/layout/StandardTemplate";
const fileDownload = require('js-file-download');

export default {
  name: 'billingHistory',
  components: {
    BaseTable,
    StandardTemplate,
  },
  data () {
    return {
      subscription: undefined,
      origStatementData: [],
      statementData: [],
      tableData: [],
      colDef: {},
      pagination: { page: 1, pageSize: 15 },
      callToAction: undefined,
      errorMessage: '',
      loading: true,
      cancel: false,
    };
  },

  created () {
    let subProm = SubscriptionService.GetSubscription();
    if (this.$store.getters.getBillingProvider !== "stripe") {
      Promise.all([subProm, SubscriptionService.GetStatementPage(1, 200)])
        .then(this.initChargifyPage)
        .catch(this.dataRetrieveFailure);
    } else {
      Promise.all([subProm, SubscriptionService.GetStripeStatementPage(undefined, 100)])
        .then(this.initStripePage)
        .catch(this.dataRetrieveFailure);
    }
  },
  computed: {
    userCurrencySymbol () {
      if (!this.$store.getters.getSubscription.currency) return;
      let currency = this.$store.getters.getSubscription.currency;
      // en-US puts the symbol to the front. could maybe use navigator.language but the method to retrieve the symbol would need to change
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency });
      return formatter.format('0').split('0')[0];
    },
  },
  methods: {
    dataRetrieveFailure (err) {
      console.log(err);
      this.callToAction = {
        text: "there was an error, please try again",
        action: () => this.$router.go()
      };
      this.$toast.error({ message: `There was an issue with loading data, if it persists please contact support` });
      this.loading = false;
    },

    initChargifyPage (resp) {
      this.subscription = resp[0].data;
      this.statementData = resp[1].data.statements;
      // if there are  remaining pages
      if (resp[1].data.remaining_pages === 0) {
        this.loading = false;
        this.createTable();
      } else {
        //else must retrieve other pages; add subsequent requests
        let requestTrack = 0;
        for (var i = 0; i < resp[1].data.remaining_pages; i++) {
          requestTrack++;
          SubscriptionService.GetStatementPage(i + 2, 200).then(
            (resp) => {
              requestTrack--;
              this.statementData = this.statementData.concat(resp.data.statements);
            }
          ).catch((err) => {
            console.log(err);
          }).finally(
            () => {
              if (requestTrack === 0) {
                this.loading = false;
                this.createTable();
              }
            });
        }
      }
    },
    async initStripePage (resp) {
      this.subscription = resp[0].data;
      this.origStatementData = resp[1].data.statements.map(x => x); // copy
      this.statementData = resp[1].data.statements;
      this.parseStatementInts(this.statementData);

      // if there are  remaining pages
      this.loading = false;
      this.createTable();
      // rather than managing page state, just get all the pages, it'll be many years before this code even runs for a user.
      if (resp[1].data.has_more && this.origStatementData.length > 0) {
        // get last statement id to request after
        let getAfter = this.origStatementData[this.origStatementData.length - 1].statement_id;
        let cont = true;
        //else must retrieve other pages; add subsequent requests
        while (cont) {
          if (this.cancel) return;
          await SubscriptionService.GetStripeStatementPage(getAfter, 100).then(
            (resp) => {
              if (resp.data.statements.length == 0) { cont = false; return; } // why is this happening

              this.origStatementData.concat(resp.data.statements.map(x => x));
              cont = resp.data.has_more;
              getAfter = resp.data.statements[resp.data.statements.length - 1].statement_id;
              this.parseStatementInts(resp.data.statements);
              this.statementData = this.statementData.concat(resp.data.statements);
              this.createTable();
            }
          ).catch((err) => {
            console.log(err);
          });
        }
      }
    },
    parseStatementInts (statements) {
      statements.forEach(x => {
        x.created_at_unix = parseInt(x.created_at_unix);
        x.opened_at_unix = parseInt(x.opened_at_unix);
        x.settled_at_unix = parseInt(x.settled_at_unix);
        x.total_cents = parseInt(x.total_cents);
        x.tax_cents = parseInt(x.tax_cents);
        x.starting_balance_cents = parseInt(x.starting_balance_cents);
        x.ending_balance_cents = parseInt(x.ending_balance_cents);
      });
    },

    createTable () {
      if (this.$store.getters.getBillingProvider !== "stripe") {
        this.colDef = {
          keyField: "statementId",
          col: [
            {
              field: "product",
              displayName: "Product",
              colClass: "w-52",
              filter: true,
              cellClass: "font-bold pl-4 pt-4 pb-2",
            }, {
              field: "term",
              displayName: "Term",
              colClass: "w-32",
              cellClass: "pl-4 pt-2",
              filter: true,
              showDisplayName: true,
            }, {
              field: 'due',
              displayName: '$ Due',
              colClass: "w-40",
              filter: true,
              showDisplayName: true,
              cellClass: "pl-4 pt-2",
            },
            {
              field: 'paid',
              displayName: `${this.userCurrencySymbol} Paid`,
              colClass: "w-40",
              cellClass: "pl-4 pt-2",
              filter: true,
              showDisplayName: true,
            },
            {
              field: 'date',
              displayName: 'Date Paid',
              colClass: "w-40",
              filter: true,
              showDisplayName: true,
              cellClass: "pl-4 pt-2 pb-4",
            },
            {
              field: 'statementId',
              displayName: 'Download',
              filter: false,
              isActionLink: true,
              colClass: "w-32",
              actionLinkIcon: "picture_as_pdf",
            },
          ]
        };
        this.handleChargifyStatementData();
      } else {
        this.colDef = {
          keyField: "statementId",
          col: [
            /* {
              field: "term",
              displayName: "Term",
              colClass: "w-32",
              cellClass: "pl-4 pt-2",
              filter: true,
              showDisplayName: true,
            }, */ {
              field: 'due',
              displayName: '$ Due',
              colClass: "w-40",
              filter: true,
              showDisplayName: true,
              cellClass: "pl-4 pt-2",
            },
            {
              field: 'paid',
              displayName: '$ Paid',
              colClass: "w-40",
              cellClass: "pl-4 pt-2",
              filter: true,
              showDisplayName: true,
            },
            {
              field: 'date',
              displayName: 'Date Paid',
              colClass: "w-40",
              filter: true,
              showDisplayName: true,
              cellClass: "pl-4 pt-2 pb-4",
            },
            {
              field: 'statementId',
              displayName: 'Download',
              filter: false,
              isActionLink: true,
              colClass: "w-32",
              actionLinkIcon: "picture_as_pdf",
            },
          ]
        };
        this.handleStripeStatementData();
      }
    },

    handleChargifyStatementData () {
      this.statementData = this.statementData.sort(   //sorts most recent statement to least recent
        (a, b) => {
          return parseInt(b.created_at_unix) - parseInt(a.created_at_unix);
        }
      );
      this.statementData.forEach((el) => {
        let charge = el.transactions.find((t) => { return t.type == "Charge"; });
        let payments = el.transactions.filter((t) => { return t.type == "Payment"; });
        // sum paid by user from successful payment transactions
        let paymentSum = 0;
        payments.forEach((x) => {
          if (x.success) paymentSum += parseInt(x.amount_cents);
        });

        let statementDate, dateString;
        // if statement is not settled (settled_at will be == -62135596800)
        const settled = 0 < parseInt(el.settled_at_unix);
        if (settled) {
          statementDate = new Date(el.settled_at_unix * 1000);
          dateString = statementDate.toString();
        }

        // there has been no charge on this statement yet. statement will be based on current sub information
        if (!charge) {
          this.tableData.push({
            product: this.subscription.product.name,
            term: this.subscription.product.interval_months === "1" ? "Monthly" : "Annual",
            due: this.commaDelineatedPrice(el.starting_balance_cents),
            paid: this.commaDelineatedPrice(paymentSum),
            date: settled ? dateString.substr(4, 6) + ", " + dateString.substr(11, 4) : "Not Settled",
            statementId: el.statement_id,
            // allowing download of non settled statements
            disabled: false // !settled,
          });
          // statement has been charged
        } else {
          let term = "";
          switch (charge.kind) {
            case ("baseline"): term = "Monthly"; break;
            case ("quantity_based_component"): term = "Add'l Users"; break;
            case ("one_time"): term = "One Time Purchase"; break;
            case ("tax"):
            default: term = charge.kind.charAt(0).toUpperCase() + charge.kind.slice(1);
          }
          this.tableData.push({
            product: charge.item_name,
            term: term,
            due: this.commaDelineatedPrice(el.total_cents),
            paid: this.commaDelineatedPrice(paymentSum),
            date: settled ? dateString.substr(4, 6) + ", " + dateString.substr(11, 4) : "Not Settled",
            statementId: el.statement_id,
            // allowing download of non settled statements
            disabled: false // !settled,
          });
        }
      });
      // test code for pagination
      // for (var i = 0; i < 4000; i++) {
      //   this.tableData.push({
      //     product: "test_" + i,
      //     term: "test_" + i,
      //     paid: this.commaDelineatedPrice("1234111"),
      //     date: "zdasae",
      //     statementId: -1,
      //   });
      // }
    },

    handleStripeStatementData () {
      this.statementData = this.statementData.sort(   //sorts most recent statement to least recent
        (a, b) => {
          return b.created_at_unix - a.created_at_unix;
        }
      );
      this.tableData = [];
      this.statementData.forEach((el) => {
        let statementDate, dateString;
        // if statement is not settled (settled_at will be == -62135596800)
        const settled = 0 < el.settled_at_unix;
        if (settled) {
          statementDate = new Date(el.settled_at_unix * 1000);
          dateString = statementDate.toString();
        }

        // there has been no charge on this statement yet. statement will be based on current sub information
        this.tableData.push({
          term: this.subscription.products.some(x => x.handle.match("retail_monthly")) ? 'monthly' : 'annual',
          due: this.commaDelineatedPrice(el.total_cents),
          paid: this.commaDelineatedPrice(el.total_cents),
          date: settled ? dateString.substr(4, 6) + ", " + dateString.substr(11, 4) : "Not Settled",
          statementId: el.statement_id,
          link: el.html_view,
          // allowing download of non settled statements
          disabled: false // !settled,
        });
        // statement has been charged
      });
      // test code for pagination
      // for (var i = 0; i < 4000; i++) {
      //   this.tableData.push({
      //     product: "test_" + i,
      //     term: "test_" + i,
      //     paid: this.commaDelineatedPrice("1234111"),
      //     date: "zdasae",
      //     statementId: -1,
      //   });
      // }
    },

    commaDelineatedPrice (price_in_cents) {
      if (!this.subscription) return '';
      var price = `${this.$store.getters.currencyFormatter.format(parseInt(price_in_cents) / 100)}`;
      return price;
    },
    // handles action-click event in the table
    actionClick (el) {
      if (el.disabled)
        return;
      //disable click on download link
      if (this.$store.getters.getBillingProvider !== "stripe") {
        el.disabled = true;
        this.$toast.success({ message: `Your receipt 'Talkatoo Statements ${el.date}.pdf' is downloading now!` });


        DocumentService.GetStatement(el.statementId).then(resp => {
          fileDownload(resp.data, `Talkatoo Statements ${el.date}.pdf`);
        }).catch(e => {
          this.$toast.error({ message: `Your receipt was unable to be downloaded, Please contact support` });
        }).finally(() => {
          el.disabled = false;
        });
      } else {
        let a = document.createElement('A');
        a.href = el.link;
        a.target = "_blank";
        a.click();
      }
    },
  },
  unmounted () {
    this.cancel = true;
  }
};


