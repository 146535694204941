<template>
  <BaseModal
    title="Update Your Shipping Information"
    :toDisplay="toDisplay"
    class="modal-container"
    @toggleEvent="toggleModal"
  >
    <div v-if="loading" class="absolute w-full h-full z-50 bg-black bg-opacity-10">
      <BaseLoading class="z-50 m-auto" />
    </div>
    <div class="pb-6 pt-8 max-w-xl lg:max-w-2xl">
      <ShippingForm
        :disableButton="loading"
        :shippingInfo="subscription.shipping_address"
        :asStripe="subscription.stripe_id != undefined"
        :countryList="countryList"
        @cancelEvent="toggleModal"
        @submitEvent="updateShippingInfo" />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal.vue";
import BaseLoading from "@/components/ui/BaseLoading.vue";
import ShippingForm from "./ShippingForm.vue";

export default {
  name: "ShippingModal",
  components: { BaseModal, BaseLoading, ShippingForm },
  props: ["toDisplay", "subscription", "countryList"],

  data () {
    return {
      loading: false,
    };
  },
  watch: {
    toDisplay (newVal) {
      if (newVal) {
        this.loading = false;
      }
    },
  },
  methods: {
    toggleModal: function () {
      this.$emit("shippingToggleEvent", this.toDisplay);
    },
    updateShippingInfo (event) {
      if (event.change) {
        this.loading = true;
        this.$emit("updateEvent", event);
      } else {
        this.toggleModal();
      }
    },
  },
};
</script>
<style scoped>
.bottom-border {
  border-bottom: solid 1px;
  @apply border-gray-400;
}

@media (max-width: 640px) {
  .modal-container {
    width: calc(100vw - 2rem);
  }
}
</style>