import SubscriptionService from "Services/SubscriptionService";
const ProductService = require("Services/ProductService");
import MixpanelService from "Services/MixpanelService";
import helpers from "Modules/SubscriptionHelper";
import BaseBox from "Components/ui/BaseBox.vue";
import BaseIcon from "Components/ui/BaseIcon.vue";
import BaseDog from "Components/ui/BaseDog.vue";
import BaseTable from "Components/ui/BaseTable/BaseTable.vue";
import BaseModal from "Components/ui/BaseModal.vue";
import UpdateModals from "Components/forms/UpdateModals.vue";
import HeaderBanner from "Components/layout/HeaderBanner.vue";
import BaseLoading from "Components/ui/BaseLoading";
import StandardTemplate from "@/components/layout/StandardTemplate.vue";

export default {
  name: "Subscription",
  components: {
    BaseBox,
    BaseIcon,
    BaseDog,
    BaseTable,
    BaseModal,
    BaseLoading,
    UpdateModals,
    HeaderBanner,
    StandardTemplate,
  },
  data () {
    // evaluate routing data for page rendering
    const queryParams = this.$store.getters.getLandingData.query;
    let upgrade, open;
    if (queryParams) {
      open = queryParams.open;
      upgrade = queryParams.upgrade;

      // user is back on the page after a successful redirect from 3ds authorization
      if (queryParams.threeds_success === "true") {
        MixpanelService.Track("AdminPortal:3dsSuccess", {
          "subscription_id": this.$store.getters.getSubscriptionId,
          "page": "Subscription"
        });
        this.$toast.success({ message: "Congratulations on your new subscription!" });
      }
      this.$store.commit("clear_landing");
    }
    return {
      tableData: [],
      colDef: {},
      // based on chargify card types definition
      cardMap: {
        visa: "Visa",
        master: "Mastercard",
        discover: "Discover",
        american_express: "American Express"
      },
      errorMessage: "",
      loading: true,
      productInactive: false,
      upgrade: upgrade,
      billingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" },
      shippingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" },

      // update Modal properties
      COUNTRY_ISO_MAP: require("@/assets/content/country_iso_map.json"),
      STATE_ISO_MAP: require("@/assets/content/state_iso_map.json"),
      countryList: [],
      subscription: {},
      subProduct: {},
      userComponentsPP: {},
      verifiedComponentsPP: {},
      productList: [],
      subscriptionModalDisplay: open === "upgrade",
      billingModalDisplay: open === "billing",
      shippingModalDisplay: open === "shipping",
      threeDSecureUpgradeModalDisplay: false,
    };
  },
  created () {
    this.countryList = helpers.setCountryList(this.COUNTRY_ISO_MAP, this.STATE_ISO_MAP);

    Promise.all([SubscriptionService.GetSubscription(), ProductService.GetProducts(), ProductService.GetComponentUsersPricePoints(), ProductService.GetComponentVerifiedPricePoints()])
      .then(
        this.handlePageData
      ).catch((e) => {
        console.error("failed to get page data:", e);
        this.errorMessage = "failed to get page data!";
      }).finally(() => {
        this.loading = false;
      });

  },
  computed: {
    concatCardNumber () {
      return this.subscription.payment_profile.masked_card_number.substr(-4);
    },
    formattedDate () {
      if (this.subscription?.payment_profile.next_billing_at === undefined) {
        return "";
      }
      var tmpDate = new Date(this.subscription.payment_profile.next_billing_at).toString();
      return tmpDate.substr(4, 6) + ", " + tmpDate.substr(11, 4);
    },
    billingInterval () {
      if (this.subscription.stripe_id) {
        return this.subscription.products.some(x => x.handle.match("retail_monthly")) ? 'monthly' : 'annual';
      }
      return this.subscription.product.interval_months == '12' ? 'monthly' : 'annual';
    },
    // provides an estimated charge on the next billing period of the plan
    userComponentsCost () {
      let cost = 0;
      if (this.subscription.stripe_id) {
        return cost;
      }
      const subPricePointHandle = this.subscription.product.price_points[0].handle;
      const usersComponent =
        this.subscription.components.find(c => c.chargify_component_handle === "users"
          && c.chargify_component_price_point_handle === subPricePointHandle);
      if (usersComponent) {
        let usersPP = this.userComponentsPP?.find(c => c.id == usersComponent.chargify_component_price_point_id);
        let ppPrice = 0;
        if (usersPP) {

          let usdUsersPrice = usersPP.prices.find(x => x.starting_quantity == this.subscription.product.number_of_users);
          if (usdUsersPrice === undefined) {
            // defaulting to cheapest price when selected user count isn't isn't fround. presuming that the subscription has > 15 users and every subsuquent user will cost the cheapest price
            usdUsersPrice = usersPP.prices.find(x => x.starting_quantity == 15);
          }
          if (this.subscription.currency == "USD") {
            // backwards support for old price points. Accounts will be migrated from this so after 1.21.0 release we can remove this ig
            if (subPricePointHandle === "standard" || subPricePointHandle === "premium") {
              ppPrice = parseInt(usersPP.prices[0].unit_price);
            } else {
              ppPrice = parseInt(usdUsersPrice.unit_price);
            }
          }
          else {
            if (subPricePointHandle === "standard" || subPricePointHandle === "premium") {
              let componentCurrencyPrice = usersPP.currency_prices.find(x => x.currency === this.subscription.currency);
              ppPrice = parseInt(componentCurrencyPrice.price);
            } else {
              let currencyPrice = usersPP.currency_prices.find(x => x.currency == this.subscription.currency && x.price_id === usdUsersPrice.id);
              if (currencyPrice === undefined) {
                console.warn("Could not find currency price for subscription's currency and selected seat count", this.subscription.currency, usersPP);
              }
              ppPrice = parseInt(currencyPrice.price);
            }
          }
          // componentCurrencyPrice.price is in dollars
          cost = ppPrice * parseInt(usersComponent.component_allocation_count);
        }
      }
      return cost;
    },
    verifiedComponentsCost () {
      let cost = 0;
      if (this.subscription.stripe_id) {
        return cost;
      }
      const verifiedComponent = this.subscription.components.find(c => c.chargify_component_handle === "verified");
      if (verifiedComponent) {
        let component = this.verifiedComponentsPP?.find(c => c.id == verifiedComponent.chargify_component_price_point_id);
        let ppPrice = 0;
        if (component) {
          if (this.subscription.currency == "USD") {
            ppPrice = parseInt(component.prices[0].unit_price);
          } else {
            let componentCurrencyPrice = component.currency_prices.find(x => x.currency === this.subscription.currency);
            ppPrice = parseInt(componentCurrencyPrice.price);
          }
          // componentCurrencyPrice.price is in dollars
          cost = ppPrice * parseInt(verifiedComponent.component_allocation_count);
        }
      }
      return cost;
    },
    estimatedCharge () {
      if (!this.subscription) return '';
      if (this.subscription.stripe_id) {
        let est = 0;
        this.subscription.products.forEach(subProd => {
          let prod = this.productList.find(x => x.product_id == subProd.product_id);
          let price = prod.prices.find(price => price.id == subProd.price_id);
          let finalPrice = price;
          if (this.subscription.currency !== "usd") {
            finalPrice = price.additional_currencies.find(x => x.currency == this.subscription.currency);
          }
          if (finalPrice?.tiers?.length) {
            if (subProd.quantity >= 15) {
              let tier = finalPrice.tiers.find(x => x.up_to_amount == 0);
              if (tier) {
                est += tier.price_in_cents * subProd.quantity;
              }
            } else {
              let tier = finalPrice.tiers.find(x => x.up_to_amount == subProd.quantity);
              if (tier) {
                est += tier.price_in_cents * subProd.quantity;
              }
            }
          } else {
            est += finalPrice.price_in_cents;
          }
        });
        return this.$store.getters.currencyFormatter.format(est / 100);
      }

      let productCost = + this.subProduct?.price_in_cents;
      if (productCost) {
        productCost = productCost / 100;
      } else
        productCost = 0;
      const price = this.$store.getters.currencyFormatter.format(this.userComponentsCost + this.verifiedComponentsCost + productCost);
      return `${price}`;
    },
    nextBillingAmount () {
      if (!this.subscription) return '';
      return this.$store.getters.currencyFormatter.format(this.subscription.current_billing_amount_in_cents / 100);
    },
    // returns a valid though time for for the subscription states and different payment contracts
    validThroughDate () {
      // if the user is trialing show trial end time
      if (this.$store.getters.isTrialing) {
        let tmpDate = new Date(parseInt(this.subscription.trial_ended_at_seconds) * 1000);
        if (isNaN(tmpDate.getTime())) return undefined;
        return this.getDateString(tmpDate.toString());

        // if the user is on an annual billed sub, (or chihuahua monthly) we can show the next billing period
      } else if (this.billingInterval == "monthly") {
        let tmpDate = new Date(this.subscription.payment_profile.next_billing_at);
        return this.getDateString(tmpDate.toString());

        // if the user is on an monthly billed sub, annual term contract, we show 1 year from activated date formatted from the current year
      } else {
        //subscription will be valid to a date 1 year out from active date in the current cycle for an annual contract
        let validTo = parseInt(this.subscription.activated_at_unix_seconds) * 1000;
        const now = new Date();
        // check if activated_at_unix_seconds date in the current year is past,
        // if so add 1 year so that right now is before the validTo date
        validTo = new Date(validTo).setFullYear(now.getFullYear());
        if (validTo < now.getTime()) {
          validTo = new Date(validTo).setFullYear(now.getFullYear() + 1);
        }

        return this.getDateString(new Date(validTo).toString());
      }
    },

    // gets days until card expires. Used to inform the customer to change card on the subscription
    cardExpiryDays () {
      const expiry = new Date(this.subscription.payment_profile.expiration_year, parseInt(this.subscription.payment_profile.expiration_month) - 1, 1);
      const msToExpiry = expiry.getTime() - new Date().getTime();
      if (msToExpiry < 0)
        return 0;
      return Math.round(msToExpiry / (1000 * 60 * 60 * 24));
    },

    //show expiring card message based on card expiry month
    cardExpiring () {
      return this.cardExpiryDays < 30;
    }
  },

  methods: {
    // shorthand method for displaying dates
    getDateString (date) {
      let tmpDate = new Date(date).toString();
      return tmpDate.substr(4, 6) + ", " + tmpDate.substr(11, 4);
    },

    subscriptionStatusDisplay (status) {
      let words = status.split('_');
      let result = '';
      words.forEach(function (word) {
        result += word.charAt(0).toUpperCase() + word.slice(1) + ' ';
      });
      return result;
    },

    handlePageData (resp) {
      // setting required page data
      this.subscription = resp[0].data;
      this.catchCaseSubscription();
      this.productList = resp[1].data.products;
      this.subProduct = this.subscription.product;
      if (this.subProduct === undefined) this.subProduct = this.subscription.products[0];
      this.userComponentsPP = resp[2].data.component_price_points;
      this.verifiedComponentsPP = resp[3].data.component_price_points;
      this.updatePageRender();

      if (this.upgrade) {
        this.toggleSubscriptionModal();
      }
    },
    catchCaseSubscription () {
      if (!this.subscription.shipping_address) {
        this.subscription.shipping_address = { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" };
      }
      if (!this.subscription.payment_profile.billing_address) {
        this.subscription.payment_profile.billing_address = { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "", name: "" };
      }
    },
    updatePageRender () {
      // defines the billing address for viewing pleasure
      this.setBillingAddress(this.subscription.payment_profile);
      this.setShippingAddress(this.subscription);
      // initializing data into a format that works with the table component
      this.defineTable();
    },
    // the following is used to copy billing address for mutation, and setting default values when a user is trialing
    // makes use of the country list
    setBillingAddress ({ billing_address }) {
      helpers.setBillingAddress.call(this, billing_address);
    },
    setShippingAddress ({ shipping_address }) {
      helpers.setShippingAddress.call(this, shipping_address);
    },
    // sets the table data based on subscription information
    defineTable () {
      //reset table
      this.tableData.length = 0;

      let userDescription = '';
      if (this.subscription.stripe_id) {
        userDescription = this.subscription.dictation_seats == 1 ?
          "1 User" : 1 + ' - ' + this.subscription.dictation_seats + ' Users';
      } else {
        userDescription = this.subProduct.number_of_users == 1 ?
          "1 User" : 1 + ' - ' + this.subProduct.number_of_users + ' Users';
      }

      // add subscription information for the table
      this.tableData.push({
        id: this.subscription.id,
        productName: "Talkatoo Subscription",
        userDescription: userDescription,
        tier: this.billingInterval.substr(0, 1).toUpperCase() + this.billingInterval.substr(1),
        status: this.subscriptionStatusDisplay(this.subscription.subscription_state),
      });

      // setup initial table rows
      this.colDef = {
        keyField: "id",
        col: [{
          field: "productName",
          displayName: "Plan",
        }, {
          field: "userDescription",
          displayName: "Users",
        }, {
          field: "tier",
          displayName: "Tier",
        }]
      };

      // activated_at_unix_seconds won't be set (ie == -62135596800) on accounts that have not purchased
      if (parseInt(this.subscription.activated_at_unix_seconds) > 0) {
        const purchaseDate = new Date(parseInt(this.subscription.activated_at_unix_seconds) * 1000);

        this.tableData[0].purchased = this.getDateString(purchaseDate.toString());
        this.colDef.col.push({
          field: "purchased",
          displayName: "Purchased",
        });
      }

      // finally add the status column
      this.colDef.col.push({
        field: "status",
        displayName: "Status",
      });
    },

    // update modal event handlers
    toggleSubscriptionModal () {
      this.subscriptionModalDisplay = !this.subscriptionModalDisplay;
    },

    toggleBillingModal () {
      this.billingModalDisplay = !this.billingModalDisplay;
    },

    toggleShippingModal () {
      this.shippingModalDisplay = !this.shippingModalDisplay;
    },

    toggleThreeDSecureModal () {
      this.threeDSecureUpgradeModalDisplay = !this.threeDSecureUpgradeModalDisplay;
    },
    reactivateSubscription () {
      this.loading = true;
      SubscriptionService.ReactivateSubscription()
        .then(resp => {
          this.subscription = resp.data;
          this.catchCaseSubscription();
          this.$toast.success({ message: "Congrats on reactivating your subscription! Start saving time with Talkatoo!" });
        }).catch(err => {
          console.log(err);
          this.$toast.error({ message: "Reactivating your subscription failed! Please get in touch with support to receive help" });
        }).finally(_ => {
          this.loading = false;
        });
    },
    // incoming new sub from Update modals. triggered on completed form submissions
    subscriptionUpdate (sub) {
      this.subscription = sub;
      this.catchCaseSubscription();
      this.subProduct = this.subscription.product;
      if (this.subProduct === undefined) this.subProduct = this.subscription.products[0];
      this.updatePageRender();
      this.loading = false;
    },

    setLoading (bool) {
      this.loading = bool;
    },

    pendingRequest (req) {
      if (req == "subscription")
        this.loading = true;
    }
  },
};
