<template>
  <BladeHeader>
    <div class="text-neutral-alt font-extrabold text-base pl-7 my-auto">
      Team Settings
    </div>
  </BladeHeader>
  <div class="admin-blade flex flex-col flex-grow mx-auto">
    <FadeInWrapper>
      <div class="image-hide block pt-12 pb-7 px-2 mx-auto">
        <img :src="require('Assets/images/characters.svg')" />
      </div>

      <div class="image-hidden">

        <div v-if="userPerms.user_management" class="link-group">
          <div>
            <span class="pl-4 font-bold font-roboto text-sm text-neutral"> Team & Tools</span>
          </div>
          <div class="link-subgroup">

            <router-link to="/users" class="link-button">
              Manage Users
              <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
            </router-link>
            <!-- <router-link to="/dafuq" class="link-button">
                Team Dictionary
                <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
              </router-link>
              <router-link to="/daheky" class="link-button">
                Team Templates
                <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
              </router-link> -->
          </div>
        </div>

        <div v-if="userPerms.billing_management" class="link-group">
          <div>
            <span class="pl-4 font-bold font-roboto text-sm text-neutral"> Plans & Payments</span>
          </div>
          <div class="link-subgroup">
            <router-link to="/billing" class="link-button">
              Billing History
              <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
            </router-link>
            <router-link to="/subscription" class="link-button ">
              Manage Subscriptions
              <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
            </router-link>
          </div>
        </div>
      </div>
    </FadeInWrapper>

  </div>


</template>
<script>
import FadeInWrapper from "../FadeInWrapper.vue";
import BladeHeader from "./BladeHeader.vue";
export default {
  name: "AdminBlade",
  components: { BladeHeader, FadeInWrapper },
  props: {},
  data () {
    return {

    };
  },
  computed: {
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
  },
};
</script>
<style>
.link-group {
  @apply pb-4;
  @apply mx-auto;
  min-width: 244px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: left;

}

.image-hide {
  display: none;
}
.image-hidden {
  @apply my-auto;
}
@media (min-height: 630px) {
  .image-hide {
    display: block;
  }
  .image-hidden {
    @apply my-0;
  }
}

</style>
