<template>
  <BaseModal
    :title="title"
    :toDisplay="toDisplay"
    class="max-w-4xl"
    @toggleEvent="toggleModal"
  >
    <!-- transition-all -->
    <div v-if="planChange">
      <!-- Plan selection portion of the modal -->
      <div class="flex flex-col max-w-5xl">
        <!-- switch for premium and standard -->
        <fieldset class="relative flex  my-2 lg:mt-8 mb-8 lg:mb-8 mx-auto border-3 border-main-dark rounded-full">
          <!-- hide if current sub is an annual premium plan -->
          <template v-if="!isSubPremiumPlan || showVerifiedSeatsSelector">
            <input id="standard" v-model="standardToggle" type="radio" name="plan" :checked="standardToggle" :value="true" class="hidden">
            <label for="standard"
                   :class="['rounded-full py-2 px-3 m-[2px] text-xs text-center  transition-all',
                            standardToggle ? 'bg-rare2-dark filter text-white'
                            : 'text-main-alt hover:text-main hover:bg-gray-100',
                            'font-bold capitalize select-none cursor-pointer']">
              MONTHLY STANDARD PLANS
            </label>
          </template>

          <input id="premium" v-model="standardToggle" type="radio" name="plan"
                 :checked="!standardToggle"
                 :value="false" class="hidden"
                 :disabled="showVerifiedSeatsSelector ? true : undefined"
                 @change="guardAnnualSelection">
          <label for="premium"
                 :class="['rounded-full py-2 px-3 m-[2px] text-xs text-center transition-all',
                          !standardToggle ? 'bg-rare2-dark text-white'
                          : 'text-main-alt hover:text-main hover:bg-gray-100',
                          'font-bold capitalize select-none cursor-pointer',
                          showVerifiedSeatsSelector ? 'select-none opacity-25 pointer-events-none' : '']"
          >
            ANNUAL PREMIUM PLANS
          </label>
        </fieldset>

        <div class="flex flex-col md:flex-row">
          <!-- User seat/verified seat selection -->
          <div class="flex flex-col w-full mt-8 md:mt-16">
            <!-- seat Selector -->
            <div class="mx-auto font-bold text-lg mb-2">
              I’m looking for a plan with:
            </div>
            <div class="flex flex-col mx-auto  font-roboto">
              <div class="flex bg-neutral-lightest rounded-md">
                <button class="change-count-button material-icons"
                        @click="removeDictationSeat"> remove
                </button>
                <div class="m-1 border-r border-neutral-lighter"></div>
                <div class="flex mr-auto  min-w-[12.5rem]">
                  <input v-model="selectedDictationSeats" type="number" :min="minDictationSeats" :max="maxDictationSeats" tabindex="1"
                         class="hide-features w-11 text-center my-1
                                text-xl text-main-alt rounded-md font-bold border border-neutral-lighter font-montserrat"
                         @blur="dictationSeatChange" @input="(e) => dictationInputGuard(e, selectedDictationSeats)"
                         @keypress.enter="dictationSeatChange" />
                  <div class="my-auto mx-2 text-xl text-main-alt font-bold whitespace-nowrap">
                    Dictation Users
                  </div>
                </div>
                <div class="m-1 border-r border-neutral-lighter"></div>
                <button class="change-count-button material-icons"
                        @click="addDictationSeat"> add
                </button>
              </div>
              <div class="relative mb-4">
                <transition name="fade-pull">
                  <div v-if="showVerified && !showVerifiedSeatsSelector" class="flex flex-col mt-4 pull-duration replace-fade-pull">
                    <button class="w-full py-2 text-center rounded-md
                                   transition-colors bg-neutral-lightest hover:bg-rare2-lightest active:bg-rare2-lighter
                                 text-rare2 font-bold border border-rare2 "
                            @click="addVerified"> Add Talkatoo Verified </button>

                    <span class="text-xs text-rare2 mx-auto font-bold"> *A monthly plan is required for talkatoo Verified</span>
                  </div>
                  <div v-else-if="showVerified" class="flex my-4 bg-neutral-lightest rounded-lg pull-duration replace-fade-pull">
                    <button class="change-count-button material-icons"
                            @click="removeVerifiedSeat"> remove
                    </button>
                    <div class="m-1 border-r border-neutral-lighter"></div>
                    <div class="flex mr-auto min-w-[12.5rem]">
                      <input v-model="selectedVerifiedSeats" type="number" :min="minVerifiedSeats" :max="maxDictationSeats" tabindex="2"
                             class="hide-features w-11 text-center my-1
                                    text-xl text-rare2 rounded-md font-bold border border-neutral-lighter font-montserrat"
                             @blur="verifiedSeatChange" @input="verifiedInputGuard"
                             @keypress.enter="verifiedSeatChange" />
                      <span class="my-auto mx-2 text-xl text-rare2 font-bold whitespace-nowrap"> Verified Users </span>
                    </div>
                    <div class="m-1 border-r border-neutral-lighter"></div>
                    <button class="change-count-button material-icons"
                            @click="addVerifiedSeat"> add
                    </button>
                  </div>
                </transition>
              </div>

            </div>

            <div class="hidden sm:flex relative mt-12 md:mt-auto h-[14.25rem]">
              <BirdsOnAWire :birdCount="selectedDictationSeats - 1" :animateSun="!isSelectionCurrentPlan" />
            </div>
          </div>
          <div class="relative flex flex-col md:w-4/5 lg:w-[50rem] max-w-[50rem] min-h-[35.5rem]  xs:mx-6 sm:ml-0 md:mr-6 lg:mr-16
                    pt-2 pb-6 sm:py-8 mb-4
                    border-3 border-main rounded-xl blue-shadow-lg">
            <TransitionGroup name="fade-pull">
              <!-- downgrade message -->
              <div v-if="false && parseInt(subscription.product.number_of_users) > selectedDictationSeats" :key="1"
                   class="replace-fade-pull pull-duration flex flex-col sm:flex-row py-2 pl-14 pr-10">
                <div class="w-full flex flex-col ">
                  <span class="text-main-darker font-bold text-[1.75rem] leading-[2.0625rem]">
                    Looking to downgrade?
                    Please get in touch and we'll help you meet your needs.
                  </span>
                  <a class="btn-primary btn-lg font-base text-decoration-none mr-auto mt-4" href="https://talkatoo.com/contact-us/">
                    <div class="px-8">Contact Support </div>
                  </a>
                </div>
                <div class="w-full flex flex-col mt-6 sm:mt-0 ">
                  <img src="@/assets/images/upgrade/support.svg" class="h-[12.75rem]" />
                </div>
              </div>


              <div v-else-if="selectedDictationSeats < maxDictationSeats && selectedVerifiedSeats < maxVerifiedSeats" :key="2" class="replace-fade-pull pull-duration flex justify-between flex-col flex-grow">
                <!-- price container -->
                <div class="pt-4 flex flex-col flex-grow">
                  <div class="flex flex-col flex-grow justify-center text-center">
                    <!-- monthly/annual price -->
                    <div class="h-[6rem] w-full">
                      <Transition name="fade-pull">
                        <span v-if="productPrice" class="font-roboto-slab font-bold text-[3.875rem] leading-[5.8125rem] text-shadow-rare2 pull-duration">
                          ${{ productPrice }}
                        </span>
                      </Transition>
                    </div>
                    <div class="flex mx-auto">
                      <div class="h-[0.125rem] bg-rare2-light w-20 my-auto"></div>
                      <div class="w-24 flex justify-center relative mx-4 font-roboto font-bold text-xs text-rare2-dark whitespace-nowrap ">
                        <TransitionGroup name="fade-pull">
                          <div v-if="standardToggle" :key="1" class="replace-fade-pull pull-duration w-full">
                            <div class="mx-auto">
                              PER MONTH
                            </div>
                          </div>
                          <div v-else :key="2" class="replace-fade-pull pull-duration w-full">
                            <div class="mx-auto">
                              PER YEAR
                            </div>
                          </div>
                        </TransitionGroup>
                      </div>
                      <div class="h-[0.125rem] bg-rare2-light w-20 my-auto"></div>
                    </div>
                    <!--  v-if="!showVerified" -->
                    <div class="mt-4 text-main-dark font-semibold font-roboto text-lg sm:text-sm">
                      That's only {{ dictationSeatMonthlyPriceFormatted }}/month per user
                    </div>
                    <div class="relative">
                      <transition name="fade-pull">
                        <div v-if="showVerified && isVerifiedUpgrade" class="pull-duration mt-4 text-main-dark font-semibold font-roboto text-lg sm:text-sm">
                          {{ verifiedSeatPriceFormatted }}/month per Verified user
                        </div>
                      </transition>
                    </div>
                    <div class="relative flex flex-col mt-auto mb-4 justify-center">
                      <div v-if="isSelectionCurrentPlan" id="current-plan" :key="1"
                           class="px-12 py-1 my-[1px] sm:my-0 mx-auto text-main-dark text-xl sm:text-base font-bold bg-rare2-lighter border-2 border-rare2">
                        Current Plan
                      </div>
                      <div v-else :key="2" class="mx-auto">
                        <button class="btn-primary btn-slim px-12 font-semibold active:bg-main-dark" tabindex="3" @click="selectedPlan">
                          Upgrade Now
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

                <!-- plan features container -->
                <div class="justify-self-end">
                  <div class="flex flex-col font-roboto sm:w-2/3 mx-auto sm:mx-0 sm:max-w-fit text-lg pt-6">
                    <div class="ml-6 pb-3 text-main-darker font-bold sm:whitespace-nowrap">
                      Your Plan Includes:
                    </div>
                    <ul class="sm:min-w-96 plan-inclusions alternate block">
                      <!-- <li :class="standardToggle? 'standard': ''"> <div class="my-auto"> Unlimited Speech Notes </div></li> -->
                      <li> <div class="my-auto">
                        <a href="https://apps.apple.com/us/app/id1591619032" target="_blank" class="inline-anchor text-main-alt underline">Mobile Microphone App </a>
                      </div></li>
                      <!-- <li :class="standardToggle? 'standard': ''"> <div class="my-auto"> {{userSelection}} <a href="https://store.talkatoo.com/" target="_blank" class="inline-anchor text-main-alt underline">Olympus Recmic</a> IIs (<span class="text-rare2">Retail value ${{userCountMap[sliderVal] * 300}} USD</span>)</div></li> -->
                      <!-- <li :class="standardToggle? 'standard': ''"> <div class="my-auto"> Lifetime replacement warranty on Olympus Microphones</div></li> -->
                      <li> <div class="my-auto">
                        Premium Lifetime Support
                      </div></li>
                      <li v-if="showVerified" :class="isVerifiedUpgrade ? '' : 'standard'">
                        <div class="my-auto">
                          Verified notes by Talkatoo Scribes
                        </div>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>

              <!-- White glove messaging, shown when user selects >15 users -->
              <div v-else :key="3" class="replace-fade-pull pull-duration flex flex-col sm:flex-row py-7 sm:pt-6 sm:pb-[1.5625rem] pl-14 pr-10 ">
                <div class="md:w-full flex flex-col py-6 sm:py-0">
                  <span class="text-main-darker font-bold text-[1.75rem] leading-[2.0625rem] mx-auto sm:mx-auto text-center sm:text-left">
                    Have more than {{ maxDictationSeats }} users? <br class="block sm:hidden" /> Get a custom plan <br class="block sm:hidden" />made just for you.
                  </span>
                  <div class=" w-3/4 md:w-full flex flex-col mx-auto mt-6 sm:mt-0 ">
                    <img src="@/assets/images/upgrade/parler.svg" />
                  </div>
                  <a class="btn-primary btn-lg w-full mx-auto sm:ml-0 mr-auto mt-8  font-base text-decoration-none" href="https://talkatoo.com/contact-us/">
                    Contact our Team
                  </a>
                </div>


              </div>
            </TransitionGroup>
          </div>
        </div>

      </div>

      <!-- modal footer -->
      <div v-if="planChange" class="flex justify-end modal-end py-6">
        <a class="cancel-button" @click="toggleModal">
          <span class="text-xs">
            CANCEL
          </span>
        </a>
      </div>
    </div>

    <!-- pane for previewing billing, Prompt to update when -->
    <div v-else-if="billingPreview">
      <div class="px-8 pt-8 pb-6">
        <BillingInfoPreview
          :subscription="subscription"
          :countryList="countryList"
        />
        <div class="flex justify-center pt-6">
          <a class="font-medium text-xl sm:text-base underline" @click="statePaymentUpdate">
            Update Billing Information
          </a>
        </div>
      </div>
      <!-- modal footer -->
      <div class="flex justify-end modal-end p-6">
        <!-- mx-6 text-gray-600 sm:text-base text-2xl font-normal -->
        <a class="cancel-button" @click="statePlanSelection">
          <span class="sm:text-xs">
            CANCEL
          </span>
        </a>
        <button class="btn-primary btn-sm" @click="stateShippingStep">
          <div class="sm:text-xs px-2 my-auto">
            Next
          </div>
        </button>
      </div>
    </div>

    <!-- Billing update portion of the modal -->
    <div v-else-if="paymentUpdate" class="relative">
      <div v-if="loading" class="absolute w-full h-full z-50 bg-black bg-opacity-10">
        <BaseLoading class="z-50 m-auto" />
      </div>
      <div class="pt-14 px-8 text-2xl sm:text-lg text-center font-bold mx-4">
        Update your billing information
      </div>
      <div class="pb-4">
        <!-- mode might need to be subscription : https://docs.stripe.com/js/elements_object/create_without_intent#stripe_elements_no_intent-options-mode -->
        <ChargifyForm
          :back="true"
          :disableButton="loading"
          :billingAddress="billingInfo"
          :countryList="countryList"
          :continueText="'Next'"
          @cancelEvent="billingFormCancel"
          @validTokenEvent="updateChargifyToken"
          @toggleLoading="loading = !loading" />

      </div>
    </div>

    <!-- Shipping update portion of the modal -->
    <div v-else-if="shippingUpdate">
      <div class="flex flex-col pt-14 px-8 text-2xl sm:text-lg text-center font-bold mx-4">
        Please Update your Shipping Information
      </div>
      <div class="flex ml-12 mt-4">
        <input id="copy-billing" v-model="sameAsBilling" type="checkbox" class="tk-check mr-2" @change="toggleSameAsBilling" />
        <label for="copy-billing" class="my-auto text-lg md:text-sm">
          Ship to my billing address
        </label>
      </div>
      <div class="pb-4 ">
        <ShippingForm
          :back="true"
          :disable="sameAsBilling"
          :shippingInfo="shippingInfo"
          :countryList="countryList"
          :ContinueText="'Next'"
          @submitEvent="updateShippingInfo"
          @cancelEvent="statePlanSelection" />
      </div>
    </div>

    <!-- confirmation section of the modal -->
    <div v-else-if="confirmStep && productList.length != 0 && subscription.product != undefined"
         class="sm:min-w-136 flex flex-col"
    >
      <div class="text-2xl pt-10 pb-6 w-full text-center font-bold">
        New Subscription Plan
      </div>
      <!-- bird image display -->
      <div class="max-w-[41.25rem] flex relative mx-auto">
        <BirdsOnAWire :birdCount="selectedDictationSeats - 1" :animateSun="!isSelectionCurrentPlan" />
      </div>

      <!-- purchase summary -->
      <div class="max-w-[40rem]
                  pt-2 pb-6 sm:py-8 mx-auto mb-4 sm:-mt-4
                  border-3 border-main rounded-xl blue-shadow-lg">

        <div class="flex flex-col justify-center px-6">
          <div class="font-roboto-slab mx-auto text-2xl font-extrabold"> {{ productUserDescription }}
          </div>
          <div class="pl-4 flex flex-col text-center">
            <div v-if="!showVerified" class="mt-4 mx-auto font-roboto text-main-dark font-semibold text-xl sm:text-lg">
              {{ dictationSeatMonthlyPriceFormatted }}/month per user billed {{ billingPeriod }}ly
            </div>
            <div class="mb-4 mt-2 mx-auto font-roboto text-rare2-darker font-extrabold text-[1.375rem] leading-7">
              Your new plan will be ${{ productPrice }}/{{ standardToggle ? "Month" : "Year" }}
            </div>

            <div v-if="$store.getters.isTrialing" class="text-sm mt-2 text-neutral-alt">
              Next billing will take affect immediately upon checkout
            </div>
            <div v-else class="text-sm font-semibold mt-2 text-neutral-alt">
              You will be charged for the prorated cost of your new plan. <br class="hidden sm:block" />  The next full charge will occur on your next renewal date.
            </div>
          </div>
        </div>
      </div>
      <div v-if="!fastTrack" class="flex pt-4  mb-4 justify-center">
        <input v-model="enableCheckout" type="checkbox" class="tk-check my-auto mx-6">
        <div class="text-sm text-neutral mx-4 sm:mx-0">
          Checking this box confirms you understand the changes to your <br class="hidden sm:block" />  subscription. Please call <a class="inline-anchor whitespace-nowrap text-main-alt" href="tel:+18558862156">1-855-886-2156</a> if you have any questions
        </div>
      </div>

      <div class="flex justify-end modal-end py-6">
        <a class="cancel-button" @click="statePlanSelection">
          <i class="material-icons">keyboard_arrow_left</i> BACK
        </a>
        <button v-if="!fastTrack"
                class="btn-primary btn-slim text-xl mr-6"
                :disabled="!enableCheckout ?? undefined"
                @click="confirmUpgrade"
        >
          Checkout
        </button>
        <button v-if="fastTrack"
                class="btn-primary btn-slim text-xl mr-6"
                @click="billingUpdateRequired ? statePaymentUpdate() : statePreviewBilling()"
        >
          Billing Information
        </button>
      </div>
    </div>
  </BaseModal>
</template>

<script src="./SubscriptionModal.js"></script>
<style src="./SubscriptionModal.css" scoped></style>