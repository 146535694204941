/* UpdateModals
* Used as an abstraction of subscription action modals, controls the set of actions and requests.
* This component concerns itself with the changing subscription information allowing the parent
* component to act on the changes to update rendered information.
*/
<template>
  <div>

    <SubscriptionModal v-if="!loading && subscriptionModalDisplay !== undefined && !subscription.stripe_id" class="m-auto"
                       :productList="productList"
                       :subscription="subscription"
                       :toDisplay="subscriptionModalDisplay"
                       :countryList="countryList"
                       :upgrade="upgrade"
                       @subscriptionToggleEvent="toggleSubscriptionModal"
                       @upgradeEvent="migrateSubscription"
                       @updateBillingEvent="updateBilling"
                       @updateShippingEvent="updateShipping" />

    <StripeSubscriptionModal v-if="!loading && subscriptionModalDisplay !== undefined && subscription.stripe_id" class="m-auto"
                             :productList="productList"
                             :subscription="subscription"
                             :toDisplay="subscriptionModalDisplay"
                             :countryList="countryList"
                             :upgrade="upgrade"
                             @subscriptionToggleEvent="toggleSubscriptionModal"
                             @upgradeEvent="stripeUpgradeSubscription"
                             @updateBillingEvent="updateBilling"
                             @updateShippingEvent="updateShipping" />

    <BillingModal v-if="!loading && billingModalDisplay !== undefined" class="m-auto"
                  :subscription="subscription"
                  :toDisplay="billingModalDisplay"
                  :countryList="countryList"
                  @billingToggleEvent="toggleBillingModal"
                  @updateEvent="updateBilling" />

    <ShippingModal v-if="!loading && shippingModalDisplay !== undefined" class="m-auto"
                   :subscription="subscription"
                   :toDisplay="shippingModalDisplay"
                   :countryList="countryList"
                   @shippingToggleEvent="toggleShippingModal"
                   @updateEvent="updateShipping" />

    <three-d-secure-modal v-if="!loading && threeDSecureUpgradeModalDisplay !== undefined"
                          :link="threeDSecureRedirect"
                          :toDisplay="threeDSecureUpgradeModalDisplay"
                          @threeDSecureToggleEvent="toggleThreeDSecureModal" />
  </div>
</template>
<script>
import SubscriptionService from "Services/SubscriptionService";
import helpers from "Modules/SubscriptionHelper";
import SubscriptionModal from "Components/forms/SubscriptionModal/SubscriptionModal.vue";
import StripeSubscriptionModal from "Components/forms/StripeSubscriptionModal/StripeSubscriptionModal.vue";
import BillingModal from "Components/forms/BillingModal/BillingModal.vue";
import ShippingModal from "Components/forms/ShippingModal/ShippingModal.vue";
import ThreeDSecureModal from "Components/forms/ThreeDSecureModal/ThreeDSecureModal.vue";

export default {

  name: 'UpdateModals',
  components: {
    BillingModal,
    ShippingModal,
    SubscriptionModal,
    StripeSubscriptionModal,
    ThreeDSecureModal,
  },
  props: ["loading", "subscription", "productList", "upgrade", "countryList",
    "subscriptionModalDisplay", "billingModalDisplay", "shippingModalDisplay", "threeDSecureUpgradeModalDisplay"],
  data () {
    return {
      billingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "" },
      shippingInfo: { address: "", address2: "", city: "", zip: "", country: "", state: "", first_name: "", last_name: "" },
      tableData: [],
      colDef: {},
      threeDSecureRedirect: "",
      errorMessage: "",
      productInactive: false,
    };
  },
  computed: {
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
  },
  methods: {

    toggleSubscriptionModal () {
      this.$emit("toggleSubscriptionModal");
    },

    toggleBillingModal () {
      this.$emit("toggleBillingModal");
    },

    toggleShippingModal () {
      this.$emit("toggleShippingModal");
    },

    toggleThreeDSecureModal () {
      this.$emit("toggleThreeDSecureModal");
    },

    // subscription update functions
    updateShipping (updateEvent) {
      const subscription = {
        "subscription_id": this.subscription.id,
        "shipping_address": updateEvent.shippingInfo,
      };
      // emit update Shipping
      // this.$emit("pendingRequest", "shipping");
      SubscriptionService.PostSubscription(subscription)
        .then((resp) => {
          this.$emit("subscriptionUpdate", resp.data);// emit subscription
          this.$toast.success({ message: "Your shipping details have been updated" });
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
        })
        .finally(() => {
          if (this.shippingModalDisplay)
            this.toggleShippingModal();
        });
    },

    updateBilling (updateData) {
      // emit update billing
      this.$emit("pendingRequest", "billing");
      if (updateData.chargifyToken) {
        SubscriptionService.UpdateSubscription(updateData.productId, updateData.chargifyToken)
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);// emit subscription
            this.$toast.success({ message: "Your billing information has been updated!" });
          })
          .catch((err) => {
            if (err.status === 422) {
              this.handle3ds(err);
              return;
            }
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your billing information! Please contact support so we can lend a hand." });
          })
          .finally(() => {
            if (this.billingModalDisplay)
              this.toggleBillingModal();
          });
      } else {
        SubscriptionService.UpdateStripeSubscription(updateData.stripeToken)
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);// emit subscription
            this.$toast.success({ message: "Your billing information has been updated!" });
          })
          .catch((err) => {
            if (err.data.message === "Payment requires 3D Secure Authentication") {
              this.handleStripe3ds(err);
              return;
            }
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your billing information! Please contact support so we can lend a hand." });
          })
          .finally(() => {
            if (this.billingModalDisplay)
              this.toggleBillingModal();
          });
      }

    },

    migrateSubscription ({ productId, pricePoint, additionalDictationSeatCount, verifiedSeatCount, chargifyToken }) {
      // emit update subscription, adds loading animations to the parent component
      this.$emit("pendingRequest", "subscription");
      // determine if the subscription is changing component count
      const userComponentChange = additionalDictationSeatCount - parseInt(this.subscription.components.find(x => x.chargify_component_handle === "users")?.component_allocation_count ?? 0);
      const verifiedComponentChange = verifiedSeatCount !== parseInt(this.subscription.verified_seats_available);

      // * no product change, just update components. bill immediately w/ accrual false
      if (userComponentChange > 0 && this.subscription.product.product_id === productId && this.subscription.product.price_points[0].id === pricePoint.id) {
        this.updateComponents(additionalDictationSeatCount, pricePoint.handle, false)
          .then((resp) => {
            if (verifiedComponentChange) {
              return this.updateVerifiedCount(verifiedSeatCount);
            }
            return Promise.resolve(resp);
          }).then(() => {
            return SubscriptionService.GetSubscription();
          })
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);
            this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
            return resp;
          }).catch((err) => {
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
          });

        // * pricepoint change and/or product changed w/ adding additional users
      } else if (userComponentChange > 0 && (this.subscription.product.product_id !== productId || this.subscription.product.price_points[0].id !== pricePoint.id)) {
        // components + accrual = true
        //upgrade
        this.updateComponents(additionalDictationSeatCount, pricePoint.handle, true)
          .then((resp) => {
            return this.updateSubscription(productId, pricePoint.id, chargifyToken);
          })
          .then((resp) => {
            if (verifiedComponentChange) {
              return this.updateVerifiedCount(verifiedSeatCount);
            }
            return Promise.resolve(resp);
          })
          .then((cResp) => {
            return SubscriptionService.GetSubscription();
          })
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);
            this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
            return resp;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
          });
        // * product changed w/o adding additional users
      } else if (userComponentChange === 0 && (this.subscription.product.product_id !== productId || this.subscription.product.price_points[0].id !== pricePoint.id)) {
        //upgrade
        this.updateSubscription(productId, pricePoint.id, chargifyToken)
          .then((resp) => {
            if (verifiedComponentChange) {
              return this.updateVerifiedCount(verifiedSeatCount);
            }
            return Promise.resolve(resp);
          })
          .then((cResp) => {
            return SubscriptionService.GetSubscription();
          })
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);
            this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
            return resp;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
          });
      } else if (verifiedComponentChange) {
        this.updateVerifiedCount(verifiedSeatCount)
          .then((cResp) => {
            return SubscriptionService.GetSubscription();
          })
          .then((resp) => {
            this.$emit("subscriptionUpdate", resp.data);
            this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
            return resp;
          })
          .catch((err) => {
            console.error(err);
            this.$toast.error({ message: "There was an error with updating your subscription! Please contact support so we can lend a hand." });
          });
      }
    },
    stripeUpgradeSubscription ({ dictationSeats, verifiedSeats, products, stripeToken }) {
      this.$emit("pendingRequest", "subscription");
      return SubscriptionService.UpgradeSubscriptionProduct(products, stripeToken)
        .then((resp) => {
          this.$toast.success({ message: "Congratulations on your new subscription! You'll find all your updated subscription information below" });
          this.$emit("subscriptionUpdate", resp.data);
          return resp;
        })
        .catch((err) => {
          if (err.data.message === "Payment requires 3D Secure Authentication") {
              this.handleStripe3ds(err);
              return;
            }
          return Promise.reject(err);
        });
    },
    // can be used to update the subscirption product and/or the pricepoint (premium/standard)
    updateSubscription (productId, pricePointId, chargifyToken) {
      return SubscriptionService.MigrateSubscription(productId, pricePointId, chargifyToken)
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          if (err.status === 422) {
            this.handle3ds(err);
            return;
          }
          return Promise.reject(err);
        });
    },

    // adds components to a subscription an accrual == true change doesn't show a toast message
    updateComponents (additionalUserCount, ppHandle, accrual) {
      return SubscriptionService.SubscriptionComponents(additionalUserCount, ppHandle, accrual);
    },

    updateVerifiedCount (verifiedSeats) {
      return SubscriptionService.UpdateVerifiedCount(verifiedSeats)
        .then(resp => {
          this.$toast.success({ message: "Congratulations on purchasing Verified by Talkatoo!" });
          return resp;
        });
    },

    handleStripe3ds (threeDSecureErr) {
      this.$emit("setLoading", false);
      this.threeDSecureRedirect = helpers.handle3dsStripeError.call(this, threeDSecureErr);
      this.$emit("toggleThreeDSecureModal");
    },
    handle3ds (threeDSecureErr) {
      this.$emit("setLoading", false);

      this.threeDSecureRedirect = helpers.handle3dsError.call(this, threeDSecureErr);
      console.log(this.threeDSecureRedirect);
      this.$emit("toggleThreeDSecureModal");
    },
  },
};


</script>
<style scoped></style>


