<template>
  <div class="pt-6">
    <div :class="['shipping-form mx-12', disable ? 'disable-form' : '']">
      <!-- first and last name -->
      <div class="inline-flex w-full mb-1">
        <template v-if="asStripe">
          <BaseInput v-model="name.value"
                     class="w-full" label="Full Name *" :errorMessage="name.error" :placeholderText="'Full Name'" type="text" required @input="(e) => fieldChange(e, 'name')"
          />
        </template>
        <template v-else>
          <BaseInput v-model="firstName.value"
                     class="w-full mr-4" label="First Name *" :errorMessage="firstName.error" :placeholderText="'First Name'" type="text" required @input="(e)=>fieldChange(e,'firstName')"
          />
          <BaseInput v-model="lastName.value"
                     class="w-full" label="Last Name *" :errorMessage="lastName.error" :placeholderText="'Last Name'" type="text" required @input="(e)=>fieldChange(e,'lastName')"
          />
        </template>
      </div>

      <!-- shipping address -->
      <div class="inline-flex w-full mb-1">
        <BaseInput v-model="address1.value"
                   class="w-full" label="Address *" :errorMessage="address1.error" :placeholderText="'Address'" type="text" required @input="(e)=>fieldChange(e,'address1')"
        />

      </div>
      <div class="inline-flex w-full">
        <BaseInput v-model="address2.value"
                   class="w-full" label="Address 2 (Optional)" :errorMessage="address2.error" :placeholderText="'Apt 2, Suite 6'" type="text" @input="(e)=>fieldChange(e,'address2')"
        />
      </div>

      <!-- country  -->
      <div class="inline-flex w-full mb-4 pt-4">
        <BaseSelectList class="w-full"
                        label="Country *" :list="countryList" :initialValue="country.value" :errorMessage="country.error" placeholderText="Select a Country" @select="countryChange" @input="(e)=>fieldChange(e,'country')"
        />
      </div>

      <!-- state -->
      <div v-if="showStatePicker" class="inline-flex w-full mb-2">
        <BaseSelectList :class="['w-full', 'mt-2', stateList.length == 0 ? 'disabled' : '']"
                        label="State *" :list="stateList" :initialValue="state.value" :errorMessage="state.error" placeholderText="Select a State" @select="stateChange"
        />
      </div>
      <div v-if="!showStatePicker" class="inline-flex w-full mb-2">
        <BaseInput v-model="state.value"
                   class="w-full" label="State *" :errorMessage="state.error" :placeholderText="'State'" type="text" required @input="(e)=>fieldChange(e,'state')"
        />
      </div>

      <!-- shipping city and zip -->
      <div class="inline-flex w-full mt-1">
        <BaseInput v-model="city.value"
                   class="w-full mr-4" label="City *" :errorMessage="city.error" :placeholderText="'City'" type="text" required @input="(e)=>fieldChange(e,'city')"
        />
        <BaseInput v-model="zip.value"
                   class="w-full" label="Zip Code *" :errorMessage="zip.error" :placeholderText="'Zipcode'" type="text" required @input="(e)=>fieldChange(e,'zip')"
        />
      </div>
    </div>
    <div class="inline-flex w-full justify-end pt-4 px-6 modal-end">
      <a class="lg:h-8 cancel-button" @click="cancelEvent">
        <template v-if="back">
          <i class="material-icons">keyboard_arrow_left</i><span class="text-xs">BACK</span>
        </template>
        <template v-else>
          <span> CANCEL </span>
        </template>

      </a>
      <button
        class="btn-primary btn-sm text-center float-right lg:h-8"
        :disabled="disableButton ?? undefined"
        @click="handleSubmit">
        <div class="text-xs px-2">
          {{ ContinueText?? 'Continue' }}
        </div>
      </button>
    </div>
  </div>
</template>
<script src="./ShippingForm.js"></script>
<style src="./ShippingForm.css"></style>
