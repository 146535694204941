<!-- the point of this component is to get around the ass-backwards teleport usage on the interior component
(insert the date picker element on the body, with absolute positioning and then add inline styling to put it exactly under the input box anyway)
which was breaking the click-outside directive, to make the styling of the component fit our designs and
to make a modular component that we don't tightly couple our other components to this 3rd party one
 -->
<template>
  <div
    v-click-outside="clickOut"
    class="tk-datepicker relative">
    <!-- placeholder of the menu -->
    <div class="flex bg-white border-gray-400 border rounded-md content-center p-3 cursor-pointer"
         role="button" @click="() => toggleMenu(innerDates)">
      <div class="my-auto pr-6 capitalize overflow-x-hidden text-sm flex">
        {{ dateRangeText }}
      </div>
      <i :class="['absolute right-3 top-1/4 material-icons select-none text-border-gray-400 bg-white', showMenu ? 'arrow-up' : 'arrow-down']">
        keyboard_arrow_down
      </i>
    </div>
    <div v-if="showMenu" :class="['absolute bg-white right-0 shadow-lg rounded-md w-full z-50']">
      <Datepicker range inline
                  :enableTimePicker="false"
                  :placeholder="placeholder"
                  :v-model="innerDates"
                  @update:modelValue="toggleMenu"
      />
    </div>
  </div>


</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "BaseDatepicker",
  components: { Datepicker },
  props: {
    date: Object,
    placeholder: String,
  },
  data () {
    return {
      innerDates: this.date,
      shown: false,
      showMenu: false,
    };
  },
  watch: {
    date (newVal) {
      this.innerDates = newVal;
    }
  },
  computed: {
    dateRangeText () {
      if (!this.innerDates || this.innerDates.length == 0) {
        return this.placeholder;
      }
      const start = this.innerDates[0].toLocaleDateString('en-us', { day: "2-digit", month: "short", year: "numeric" });
      const end = this.innerDates[1].toLocaleDateString('en-us', { day: "2-digit", month: "short", year: "numeric" });
      // locale date as 'Jul 21, 2023 - Jul 28, 2023'
      return `${start} - ${end}`;
    }
  },
  methods: {
    toggleMenu (newDates) {
      this.showMenu = !this.showMenu;
      if (!this.showMenu && newDates && newDates.length == 2 && newDates[0] && newDates[1]) {
        this.innerDates = newDates;
        this.$emit("update:date", this.innerDates);
      }
    },

    clickOut () {
      // hack to deal with the clickout directive triggering on the click that shows the menu
      if (!this.shown) {
        this.shown = true;
        return;
      }
      if (this.showMenu) {
        this.showMenu = false;
        this.$emit("click-out", this.innerDates);
      }
    },
  }
};
</script>

<style>
.tk-datepicker .arrow-up {
  transform: rotate3d(1, 0, 0, 180deg);
  transition: 0.2s ease-out;
}

.tk-datepicker .arrow-down {
  transition: 0.2s ease-out;
}

/* Datepicker styling to make the component more in line with our styling */
.dp__range_end,
.dp__range_start {
  @apply bg-main;
  /* background-color: #4C9DB8 !important; */
}

.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #52bae2 !important;
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
}

.dp__input {
  font-family: "Montserrat", sans-serif !important;
  @apply text-gray-500 !important;
  @apply text-sm !important;
  /* @apply font-montserrat; */
  @apply p-3 !important;
  @apply border-gray-400 !important;
  @apply rounded-md !important;
}

.dp__select {
  color: white !important;
  background-color: #0386b7;
  @apply text-sm !important;
  /* @apply ml */
}

.dp__icon {
  /* fill:rgba(0, 0, 0, 0) !important; */
  left: initial !important;
  right: 0 !important;
  /* font-family: "Material Icons";
  content: "e316"; */
}

.dp__clear_icon {
  right: 20px !important;
}

.dp__selection_preview>div {
  height: 14px;
  line-height: 15px;
}

.dp__menu {
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 100% !important;
}

.dp__calendar_header .dp__calendar_header_item {
  padding: 0.3125rem !important;
  /* reverting static pixel width and height to relative to root */
  height: 2.125rem !important;
  width: 2.125rem !important;
}

.dp__cell_inner {
  /* reverting static pixel width and height to relative to root */
  height: 2.125rem !important;
  width: 2.125rem !important;
}
</style>