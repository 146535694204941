

<template>
  <StandardTemplate>
    <h2 class="heading">Getting Started</h2>
    <div class="xl:max-w-3xl">
      <BaseBox title="So What's Next?" class="text-center text-3xl sm:text-2xl">

        <img :src="require('Assets/images/talkatoo_logo_icon.svg')" class="m-auto mt-10 mb-6" height="160" width="160"/>
        <div v-show="userOS !== 'mobile'" class="mt-4 mb-6">
          Download and install Talkatoo on your <br class="sm:visible invisible"/> workstation to begin dictation!
        </div>
        <div class="flex flex-col justify-center">

          <div v-if="userOS === 'mobile' || !this.options[this.userOS]" class="pb-12 pt-8 px-6 sm:px-12">
            <div class="font-bold pb-2">Talkatoo is a desktop application.</div>
            Revisit on your desktop workstation to download and install Talkatoo! <br class="none sm:visible"/>
            Stay tuned, we have a mobile app on the way!
          </div>
          <!-- always show some form of download -->
          <template  v-else>
            <div class="font-semibold capitalize">{{userOS}} Download</div>
            <div class="flex  justify-center">
              <div class="flex justify-center">
                <a :class="[primaryDownload(0), 'btn-primary px-12 my-5 mx-4 sm:text-lg text-2xl font-medium ']"
                  id="download-button"
                  @click="provisioningDownload"
                  :href="options[userOS][0].url">
                    Download ({{options[userOS][0].archDisplay}})
                    <svg v-if="arch === options[userOS][0].arch" class="pl-2 inline h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M472 313v139c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20V313H0v139c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60V313h-40z"></path><path d="M352 235.716l-76 76V0h-40v311.716l-76-76L131.716 264 256 388.284 380.284 264z"></path></svg>
                </a>
              </div>
              <div class="flex justify-center">
                <a :class="[primaryDownload(1), 'px-12 my-5 mx-4 sm:text-lg text-2xl font-medium']"
                  id="download-button"
                  @click="provisioningDownload"
                  :href="options[userOS][1].url">
                  Download  ({{options[userOS][1].archDisplay}})
                    <svg v-if="arch === options[userOS][1].arch" class="pl-2 inline h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M472 313v139c0 11.028-8.972 20-20 20H60c-11.028 0-20-8.972-20-20V313H0v139c0 33.084 26.916 60 60 60h392c33.084 0 60-26.916 60-60V313h-40z"></path><path d="M352 235.716l-76 76V0h-40v311.716l-76-76L131.716 264 256 388.284 380.284 264z"></path></svg>
                </a>
              </div>
            </div>
          </template>
        </div>
        <div v-show="userOS !== 'mobile'" class="text-lg sm:text-base font-normal my-12">
          Want to download for another operating <br/>system?
          <a
            href="https://talkatoo.com/downloads/"
            class="inline-anchor transition-all ease-in-out underline hover:text-gray-400 text-gray-500 font-semibold"
          >
            Do that here
          </a>
        </div>
      </BaseBox>
    </div>
  </StandardTemplate>
</template>

<script src="./GettingStarted.js"/>