import axios from 'axios';

const SERVICE_URL = __API_URL_V1;

const GetStatement = (statementId) => {

  return axios({
    method: 'GET',
    url: SERVICE_URL + 'statement/' + statementId,
    responseType: 'blob',
  });
};

export {
  GetStatement,
};