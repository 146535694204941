
<template>
  <BaseModal
    id="invite-modal"
    title="Invite Users"
    :to-display="toDisplay"
    @toggleEvent="toggleModal"
  >
    <div v-if="loading" class="absolute w-full h-full z-50 bg-black bg-opacity-10">
      <BaseLoading class="z-50 m-auto" />
    </div>
    <div class="invite-users-container">
      <div id="invite-form" class="pt-4 flex-grow">
        <div class="lg:pl-8 md:pr-5">
          <div class="flex flex-wrap w-full">
            <span class="w-full text-base font-normal text-gray-600 px-6 lg:px-4 mt-4 mb-6">
              New users will be sent an email asking them to <br class="hidden md:block" />
              confirm their user details to activate their account.
            </span>
          </div>
          <!-- headers -->
          <div class="flex justify-between">
            <div class="w-full flex flex-col sm:flex-row sm:justify-between pb-4 pl-6 lg:pl-4 sm:pr-3">
              <span class="font-bold text-gray-600 mt-auto whitespace-nowrap">
                Send an email invite to:
              </span>
              <div class="relative">
                <!-- permissions tooltip -->
                <div class="tooltip font-normal text-gray-600 underline sm:text-right z-50">
                  What are permissions?
                  <div class="tooltip-text -left-4 md:left-auto md:right-0 w-80 text-left mr-1">
                    <div><b>Dictation: </b> Needed to dictate with Talkatoo. </div>
                    <div><b>User Management: </b> Send invites, update permissions, reset passwords, and remove users.</div>
                    <div><b>Billing Management: </b> Update payment and shipping information, upgrade the subscription, and download invoices. </div>
                    <div v-if="subHasVerifiedSeats">
                      <b>Verified: </b> Verified Scribes will review and correct this user's Notes.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- form -->
        <div class="input-group-container">
          <div v-for="(row, index) in invitees" :id="`input-group-${row.id}`" :key="row.id" :data-focus="row.showPerms"
               :class="['input-group collapse-box content-box flex pl-6 lg:pl-8 border-gray-300',
                        row.showPerms ? '' : '']"
               :data-show="!!row.showPerms">
            <div class="input-constraint flex flex-col w-full">
              <div class="flex" @keyup.enter="inviteEvent">
                <div class="relative flex flex-grow">
                  <BaseInput :id="'invite-input-' + row.id"
                             v-model="row.email" :tabindex="index + 1"
                             class="input-field min-w-[10rem] w-full"
                             :placeholderText="`Invite ${index + 1}`"
                             type="text"
                             :maxLength="255"
                             name="email-address"
                             :errorMessage="row.errorMessage"
                             pattern="[^@]+@[^.]+\..+"
                             :autocomplete="'off'"
                             @input="fieldChange(row)"
                             @focus="toggleRow(row, true)"
                  />
                  <button v-if="index != 0" class="absolute right-2 top-1/2 transform -translate-y-1/2 bg-opacity-0"
                          @click="removeField(row.id)">
                    <i class="material-icons text-2xl hover:opacity-100 opacity-60 transition-all cursor-pointer"> close </i>
                  </button>
                </div>
              </div>
              <Transition name="fade">
                <div v-if="!row.showPerms && row.errorMessage == ''" class="fade-transition-duration relative">
                  <div class="absolute left-4 -top-3 ellipsis-overflow max-w-[90%]
                              text-main-darker text-xs capitalize font-roboto font-normal">
                    {{ permsToText(row.permissions) }}
                  </div>
                </div>
              </Transition>
              <div class="collapse-section ">
                <div class="mt-2">
                  <BaseMultiSelect v-model:value="row.permissions" class="w-full pb-4"
                                   label="Roles and Permissions*"
                                   placeholderText="Select Permissions"
                                   :disabledList="disabledList(row.permissions)"
                                   fullMenu
                                   ellipsis
                                   teleport
                  />
                </div>
              </div>
            </div>
            <div class="relative min-w-[2.5rem] w-[2.5rem] lg:min-w-[6rem] lg:w-[6rem] bg-opacity-0">
              <button class="absolute top-10 right-2 lg:right-10 transform -translate-y-1/2 bg-opacity-0"
                      @click="toggleRow(row)">
                <i :class="[row.showPerms ? 'arrow-up' : 'arrow-down', 'material-icons select-none text-border-gray-400']">
                  keyboard_arrow_down
                </i>
              </button>
            </div>
          </div>
        </div>
        <div class="flex pl-6 lg:pl-8 my-4">
          <button v-if="!disableAddField" id="add-field-link" class="link-thin m-auto mx-0 text-main-alt first-letter:font-bold"
                  :disabled="disableAddField ?? undefined" @click="addField">
            + <span class="underline">Invite another user</span>
          </button>
          <span v-else id="upgrade-prompt" class="opacity-50 text-main text-sm cursor-default">
            Upgrade to get more seats!
          </span>
        </div>
      </div>
      <span :class="[errorMessage !== '' ? 'error-message' : '', ' py-2 text-center h-8']">
        {{ errorMessage }}
      </span>
      <!-- footer items -->
      <div class="flex flex-col md:flex-row justify-between border-t sm:px-4 p-4 border-gray-300">
        <div class="flex flex-col w-full text-center md:text-left pb-3 my-auto mr-4 md:mr-12 md:pb-0">
          <span class="whitespace-nowrap">Remaining Invites:</span>
          <div class="font-bold">
            <b>Slots:</b> <span class="text-main-alt">{{ invitesRemaining }}</span>&nbsp;
            <b>Dictation:</b> <span class="text-main-alt">{{ dictationSeatsRemaining }}</span>
            <template v-if="subHasVerifiedSeats">
              &nbsp;<b>Verified:</b> <span class="text-main-alt">{{ verifiedSeatsRemaining }}</span>
            </template>
          </div>
        </div>
        <div class="my-auto flex justify-center">
          <button class="w-36 md:w-20 mr-4 px-4 py-2 text-gray-400 border border-gray-400 md:border-none rounded-md text-xs font-bold" @click="cancelModal">
            CANCEL
          </button>
          <button id="send-invite-button" :disabled="disableSubmit ?? undefined"
                  class="w-36 px-4 py-2 rounded-md transition-colors bg-main-alt hover:bg-main text-white text-xs font-bold" @click="inviteEvent">
            INVITE
          </button>
        </div>
      </div>

    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal.vue";
import BaseInput from "@/components/ui/BaseInput.vue";
import BaseLoading from 'Components/ui/BaseLoading';
import BaseMultiSelect from 'Components/ui/BaseMultiSelect';

export default {
  name: "InviteModal",
  components: { BaseModal, BaseInput, BaseLoading, BaseMultiSelect },
  props: { toDisplay: Boolean, loading: Boolean, users: Array, invites: Array, subscription: Object },
  data () {
    const mobileView = screen.width < 640;
    // copied from dictation users count. Can't be used in the data method, computed functions are not initialized yet
    const dictationUsersCount = this.users.filter(user => user.permissions.dictation).length + this.invites.filter(invite => invite.permissions.dictation).length;
    let dictationSeatCount;
    if (this.subscription.stripe_id) {
      dictationSeatCount = parseInt(this.subscription.dictation_seats);
    } else {
      dictationSeatCount = this.subscription.product.number_of_users;
    }

    let permissionsTemplate =
    {
      dictation: dictationUsersCount < dictationSeatCount,
      user_management: false,
      billing_management: false,
      //  editor = false,
    };
    if (this.$store.getters.subHasVerifiedSeats) {
      permissionsTemplate.verified = false;
    }
    let perms = this.copyPerms(permissionsTemplate);
    return {
      id: 0,
      invitees: [{ id: 0, email: "", errorMessage: "", permissions: perms }],
      errorMessage: "",
      permissionsTemplate: permissionsTemplate,
      disableSubmit: false,
      mobileView: mobileView,
    };
  },
  watch: {
    toDisplay (newVal) {
      if (!newVal) return;

      // re-initialize based on current state
      this.disableSubmit = false;
      let permissionsTemplate = {
        dictation: true,
        user_management: false,
        billing_management: false,
      };
      if (this.subHasVerifiedSeats) {
        // permissionsTemplate["editor"] = false;
      }
      if (this.subHasVerifiedSeats) {
        permissionsTemplate["verified"] = false;
      }
      // reset invitee list for the display
      this.invitees = [{ id: 0, email: "", errorMessage: "", permissions: this.copyPermsTemplate() }];
      this.errorMessage = "";
      this.$nextTick(() => {
        document
          .getElementById("invite-input-0")
          .getElementsByTagName("input")[0]
          .focus();
      });
    }
  },
  computed: {
    subHasVerifiedSeats () {
      return this.$store.getters.subHasVerifiedSeats;
    },
    // set of computed methods for calculating the edge cases around inviting and enforcing business logic and displaying to the user
    dictationUsersCount () {
      return this.users.filter(user => user.permissions.dictation).length + this.invites.filter(invite => invite.permissions.dictation).length;
    },
    // tallies the dictation user count and provides a boolean for whether more dictation seats can be added
    dictationEnabled () {
      const tentativeDictationCount = this.dictationUsersCount + this.inviteeDictationCount;
      if (this.subscription.stripe_id) {
        return tentativeDictationCount < this.subscription.dictation_seats;
      }
      return tentativeDictationCount < this.subscription.product.number_of_users;
    },
    inviteeDictationCount () {
      return this.invitees.filter(invitee => {
        return invitee.permissions.dictation;
      }).length;
    },
    invitesRemaining () {
      if (this.subscription.stripe_id) {
        return parseInt(this.subscription.number_of_user_seats) - (this.users.length + this.invites.length + this.invitees.length);
      }
      let verifiedSeatsUsed = this.users.filter(user => user.permissions.verified).length;
      let verifiedSeats = parseInt(this.subscription.verified_seats_available) - verifiedSeatsUsed;
      return verifiedSeats + parseInt(this.subscription.product.number_of_seats) - (this.users.length + this.invites.length + this.invitees.length);
    },

    dictationSeatsRemaining () {
      if (this.subscription.stripe_id) {
        const count = parseInt(this.subscription.dictation_seats) - (this.dictationUsersCount + this.inviteeDictationCount);
        return this.disableAddField ? Math.min(1, count) : count;
      }
      const count = this.subscription.product.number_of_users - (this.dictationUsersCount + this.inviteeDictationCount);
      return this.disableAddField ? Math.min(1, count) : count;
    },
    verifiedUsersCount () {
      return this.users.filter(user => user.permissions.verified).length + this.invites.filter(invite => invite.permissions.verified).length;
    },
    inviteeVerifiedCount () {
      return this.invitees?.filter(invitee => {
        return invitee.permissions['verified'];
      })?.length || 0;
    },
    // true/false for if the final set of verified users after inviting will be less than the available seats.
    // used to prevent over inviting verified users
    verifiedEnabled () {
      const tentativeVerifiedCount = this.verifiedUsersCount + this.inviteeVerifiedCount;
      if (this.subscription.stripe_id) {
        return tentativeVerifiedCount < this.subscription.verified_seats;
      }
      return tentativeVerifiedCount < this.subscription.verified_seats_available;
    },
    verifiedSeatsRemaining () {
      if (this.subscription.stripe_id) {
        return this.subscription.verified_seats - (this.verifiedUsersCount + this.inviteeVerifiedCount);
      }
      return this.subscription.verified_seats_available - (this.verifiedUsersCount + this.inviteeVerifiedCount);
    },
    disableAddField () {
      return 0 >= this.invitesRemaining;
    },
  },
  methods: {
    toggleRow (row, toggle) {
      this.invitees.forEach(x => { if (x.id !== row.id) { x.showPerms = false; } });
      if (toggle !== undefined) {
        row.showPerms = toggle;
      } else {
        row.showPerms = !row.showPerms;
      }
    },
    permsToText (perms) {
      if (!perms) {
        return "None";
      }
      let text = [];
      Object.keys(perms).forEach(x => { if (perms[x]) text.push(x.replace("_", " ")); });
      if (text.length === 0) {
        return 'None';
      }
      return text.join(", ");
    },
    toggleModal (toDisplay, source) {
      if (source === "button") {
        this.cancelModal();
        return;
      } else if (
        this.invitees.some((el) => {
          return el.email != "";
        })
      ) {
        this.errorMessage = 'You have pending work, either clean the inputs or click "Cancel"';
        return;
      }
      this.$emit("invitetoggleEvent", this.toDisplay);
      this.invitees = [{ id: 0, email: "", errorMessage: "", permissions: this.copyPermsTemplate() }];
    },
    cancelModal () {
      this.errorMessage = "";
      this.$emit("invitetoggleEvent", this.toDisplay);
      this.invitees = [{ id: 0, email: "", errorMessage: "", permissions: this.copyPermsTemplate() }];
    },
    copyPermsTemplate () {
      return { ...this.permissionsTemplate };
    },
    copyPerms (perms) {
      return { ...perms };
    },
    permChange (invitee) {
      invitee.permChange = true;
    },
    // disables the checkboxes based business logic:
    // dictation cap reached, does not have required permissions, not asigning dictation + verified permissions
    disabledList (permissions) {
      let list = [];
      if (!this.dictationEnabled) {
        list.push({ dictation: false });
      }
      if (!this.verifiedSeatsRemaining) {
        list.push({ verified: false });
      }
      //  if dictation is checked, only let users uncheck verified
      if (permissions.dictation && !permissions.verified) {
        list.push({ verified: false });
      }
      //  if verified is checked, only let users uncheck dictation
      if (permissions.verified && !permissions.dictation) {
        list.push({ dictation: false });
      }


      // cannot assign dictation or verified of the opposite is assigned
      if (permissions.dictation) {
        list.push({ verified: false });
      }
      if (permissions.verified) {
        list.push({ dictation: false });
      }
      return list;
    },
    addField () {
      // at limit of the subscription invitations
      if (this.disableAddField) {
        return;
      }
      var lastInvitee = this.invitees[this.invitees.length - 1];
      this.id++;
      let invitee = { id: this.id, email: "", errorMessage: "", permissions: this.copyPerms(lastInvitee.permissions) };
      // if prior invite had dictation/verified selected but dictation/verified limit is hit, set dictation/verified perm to false
      if (invitee.permissions.dictation) {
        invitee.permissions.dictation = this.dictationEnabled;
      }
      if (invitee.permissions.verified) {
        invitee.permissions.verified = this.verifiedEnabled;
      }
      invitee.showPerms = false;
      this.invitees.push(invitee);
      this.invitees.forEach(x => { x.showPerms = false; });
      this.$nextTick(() => {
        document
          .getElementById("invite-input-" + this.id)
          .getElementsByTagName("input")[0]
          .focus({ preventScroll: true });
        window.setTimeout(() => {
          document.getElementsByClassName("input-group-container")[0].scrollTo({
            top: 9999,
            behavior: "smooth",
          });
        }, 50);
      });
    },
    removeField (id) {
      this.errorMessage = "";
      this.disableSubmit = false;
      this.invitees = this.invitees.filter((el) => {
        return el.id != id;
      });
    },
    inviteEvent () {
      if (this.disableSubmit) return;
      this.disableSubmit = true;
      // preventing form detault
      if (this.invitees.length == 0) {
        this.invitees[0].errorMessage = "Invalid email";
        this.disableSubmit = false;
        return;
      }
      // old regex for emergency restore
      // let emailRegEx = /[^@]+@[^.]+\..+/;
      // let validEmails = true;
      // for (var i = 0; i < this.invitees.length; i++) {
      //   if (!emailRegEx.test(this.invitees[i].email)) {

      let validEmails = true;
      for (var i = 0; i < this.invitees.length; i++) {
        // eslint-disable-next-line
        let emailRegEx = /[^@]+@.+\..+/;
        let testEmail = emailRegEx.test(this.invitees[i].email);
        if (!testEmail) {
          this.invitees[i].errorMessage = "Invalid email";
          validEmails = false;
          this.disableSubmit = false;
        }
      }

      if (validEmails) {
        this.compareEmails();
      }
    },
    compareEmails () {
      var sendEmails = true;
      //loop evaluates if an email already exists and whether to send invites to invitees
      this.invitees.forEach(invitee => {
        var exists = this.invites.some(invite => invite.user_email === invitee.email);
        if (exists) {
          invitee.errorMessage = "Invite already exists";
          sendEmails = false;
        } else {
          // check if user exists
          exists = this.users.some(user => user.email === invitee.email);
          if (exists) {
            invitee.errorMessage = "User is already in the flock!";
            sendEmails = false;
          }
        }
      });

      if (sendEmails) {
        const finalInvitees = this.invitees.map((el) => {
          return {
            user_email: el.email.trim().toLowerCase(),
            //reduce array that looks like template array to a typical userPermissions object
            permissions: Object.keys(el.permissions).reduce((acc, key) => {
              acc[key] = el.permissions[key];
              return acc;
            }, {})
          };
        });
        this.$emit(
          "inviteEvent",
          finalInvitees
        );
      }
    },
    fieldChange (row) {
      this.errorMessage = "";
      row.errorMessage = "";
      this.disableSubmit = false;
    },
  },
};
</script>

<style scoped>
.invite-users-container {
  @apply relative;
  @apply flex;
  @apply flex-col;
  min-height: 33.75rem;
  max-height: calc(100vh - 8rem);
  max-width: calc(100vw - 2rem);
}

@media (min-width: 640px) {
  .invite-users-container {
    max-width: calc(100vw - 4rem);
  }
}

@media (min-width: 768px) {
  .invite-users-container {
    max-width: calc(100vw - 4rem);
    width: 53rem;
  }
}

.input-constraint {
  max-width: calc(100% - 2.5rem);
}

@media (min-width: 640px) {
  .input-constraint {
    max-width: 100%;
  }
}

.btn-invite {
  @apply text-xs;
  @apply py-3;
  @apply px-6;
}

@media (max-width: 640px) {
  .btn-invite {
    @apply text-xs;
    @apply py-3;
    @apply px-6;
  }
}

.input-group-container {
  max-height: 40vh;
  height: 100%;
  overflow-y: auto;
}

@media (min-height: 900px) {
  .input-group-container {
    max-height: 28rem;
  }

  #invite-form {
    min-height: 40rem;
  }
}

/* box borders */
.input-group {
  @apply border-gray-200;
  box-sizing: border-box;
  @apply border-b;
}

.input-group:first-child {
  @apply border-t;
}

/* animation constraints */
.collapse-box.input-group {
  @apply overflow-hidden;
  flex: 0;
  max-height: 5.5rem;
  @apply transition-all;
  @apply duration-300;
  transition-timing-function: ease-in-out;
}

.collapse-box .collapse-section {
  @apply overflow-hidden;
  min-height: 0;
  flex: 0;
}

.collapse-section {
  @apply delay-100;
  transition-timing-function: ease-in-out;
}

.collapse-box[data-show="false"] .collapse-section {
  min-height: 0rem;
}

.collapse-box[data-show="true"] .collapse-section {
  min-height: 4.5rem;
  flex: 1;
}



.input-group[data-focus="true"] {
  @apply bg-gray-100;
}

.collapse-box[data-show="true"].input-group {
  flex: 1;
  max-height: 9.25rem;
}


.input-field {
  padding: 1rem 0 1rem 0;
  margin-top: 0;
  display: block;
}

.fade-transition-duration {
  transition-duration: 0.2s;
}

.ellipsis-overflow {
  @apply overflow-hidden;
  @apply overflow-ellipsis;
  @apply whitespace-nowrap;
}
</style>