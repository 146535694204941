<template>
  <!-- grayed out mobile  menu background -->
  <header
    :class="['',
             showMobileMenu ? 'sticky w-full top-0 z-40' : 'relative',
             isLoggedIn ? 'flex' : 'hidden lg:flex']">

    <!-- Right-hand section for help/ logout etc -->
    <nav v-if="isLoggedIn && !simpleNav" class="flex flex-grow justify-end">
      <RouterLink v-if="$route.name == 'NotesEntryId'" to="/notes"
                  class="text-main-alt hover:text-main-dark pl-4 text-base hover:no-underline">
        <i class="material-icons text-lg mr-2 no-underline"> west</i>
        <div class="my-auto underline font-normal text-sm font-roboto">
          Back To Notes List
        </div>
      </RouterLink>
      <button v-if="$route.name == 'UserProfile' || $route.name == 'UserProfileId' || $route.name == 'TemplateSettings' || $route.name == 'TalkatextSettings'" class="text-main-alt hover:text-main-dark pl-4 text-base hover:no-underline"
              to="/notes" @click="navBack">
        <div class="flex whitespace-nowrap my-auto">
          <i class="material-icons text-lg mr-2 no-underline"> west</i>
          <div class=" mt-1 underline font-normal text-sm font-roboto">
            {{ backMessage }}
          </div>
        </div>
      </button>
      <div class="flex-grow">
        <!-- spacer -->
      </div>
      <div class=" my-auto hidden lg:block">
        <RouterLink to="/settings/templates" class="header-nav h-12 w-12 no-underline px-2  transition-opacity hover:bg-neutral-lighter rounded-md ">
          <span class="material-icons text-3xl text-neutral-darker ">
            settings
          </span>
        </RouterLink>
      </div>
      <div class="border-r border-neutral-light my-4 mx-1  hidden lg:block">
        <!-- border spacing -->
      </div>
      <div :class="['hidden lg:flex pr-3 ', simpleNav ? 'pr-24' : '']">

        <!-- notification icon -->
        <div v-if="false && isLoggedIn && !simpleNav" class="border-x px-7 flex flex-col justify-center">
          <BaseIconDropdown icon="notifications" :notifCount="notifCount" class="text-4xl text-gray-400 h-12 w-12">
            <div class="notification" @click="notifCount++"> notifications
            </div>
            <div class="notification"> will
            </div>
            <div class="notification"> be
            </div>
            <div class="notification"> listed
            </div>
            <div class="notification"> here
            </div>
          </BaseIconDropdown>
        </div>

        <div class="my-auto">
          <button class="relative h-10 w-10" @click="toggleProfileMenu">
            <img v-if="userProfile?.profilePhoto" height="40" width="40"
                 :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile.profilePhoto}`"
                 :class="['absolute top-0 block p-1 rounded-full',
                          'aria-selected:border-3 aria-selected:border-main-alt aria-selected:rounded-sm',
                          showProfileMenu ? ' z-50' : '']" />
          </button>

        </div>
      </div>

      <Transition name="fade">
        <div v-if="showProfileMenu"
             :class="['absolute z-50 right-1 lg:right-0 bottom-0 transform translate-y-full rounded-md bg-neutral-lightest h-[324px] w-full xs:w-[350px]',
                      showProfileMenu ? ' ' : '']"
             @click.stop>
          <div class="flex pt-4 pb-3 px-9 justify-between rounded-t-md bg-white">
            <div class="block">
              <RouterLink v-if="isUserSuper" to="/spoof" class="material-icons" @click="toggleProfileMenu">
                settings_accessibility
              </RouterLink>
            </div>
            <button class="block text-error font-bold text-sm" @click="logout">
              Sign Out
            </button>
          </div>
          <div class="relative flex flex-col items-center h-9">
            <img v-if="userProfile?.profilePhoto" class="absolute flex rounded-full transform -translate-y-1/2 mx-auto" width="68" height="68"
                 :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile.profilePhoto}`" />
          </div>
          <div class="flex flex-col mt-2 flex-grow justify-center text-center text-main-darker">
            <div class="ellipsis-overflow font-bold text-xl">
              {{ userProfile.firstName }} {{ userProfile.lastName }}
            </div>
            <div> {{ userProfile.email }}</div>

            <RouterLink to="/profile" class="text-sm mt-4 mx-auto underline" @click.stop="showProfileMenu = false">
              Update Profile
            </RouterLink>
            <RouterLink to="/settings/templates" class="lg:hidden block text-sm mt-4 mx-auto underline" @click.stop="showProfileMenu = false">
              Template Settings
            </RouterLink>
            <!-- <RouterLink to="/settings/talkatexts" class="lg:hidden block text-sm mt-4 mx-auto underline" @click.stop="showProfileMenu = false">
              Talkatext Settings
            </RouterLink> -->
            <a id="`login-to-desktop`"
               class="text-sm mx-auto mt-8 underline"
               @click="navToDesktop">
              Login to Desktop
            </a>
          </div>
        </div>
      </Transition>
      <teleport to="body">
        <Transition name="fade">
          <button v-if="showProfileMenu" class="default-fade-duration fixed z-10 top-0 left-0 bg-black bg-opacity-75  min-w-full min-h-full"
                  @click.stop="toggleProfileMenu">
          </button>
        </Transition>
      </teleport>
      <div v-if="!simpleNav" class="lg:hidden flex">
        <button :class="['text-xl text-main-lighter m-auto h-10 w-10 rounded-xl',
                         showMobileMenu ? 'bg-main-darker' : '']"
                @click="toggleMobileMenu">
          <span class="material-icons block text-4xl leading-8 my-auto"> menu </span>
        </button>

        <!-- mobile menu list -->
        <div class=" mx-3 my-2 border-r border-main-dark">
          <!-- interior border between menu and profile -->
        </div>
        <div class="mt-2 mb-auto">
          <button to="/profile" class="h-10 w-10" @click="toggleProfileMenu">
            <img v-if="userProfile?.profilePhoto"
                 :src="`https://storage.googleapis.com/assets.talkatoo.ai/profile-photos/${userProfile.profilePhoto}`"
                 class="block h-10 w-10 p-1 rounded-full
                aria-selected:border-3 aria-selected:border-main-alt aria-selected:rounded-sm" />
            <!--
                :class="['absolute top-0 block p-1 rounded-full',
                          'aria-selected:border-3 aria-selected:border-main-alt aria-selected:rounded-sm',
                          showProfileMenu ? ' z-50' : '']" /> -->
          </button>
        </div>

        <TransitionGroup name="fade">
          <div v-if="showMobileMenu"
               :key="0"
               class="mobile-menu-position bg-white w-screen z-50 transform translate-y-full'"
          ><!-- :style="menuSizing"> -->
          </div>
          <div v-if="showMobileMenu" :key="1"
               :class="['fade-duration',
                        'mobile-menu-position transform ',
                        ' bg-white vertical-gradient w-screen  flex-col z-50 ']"
               @click="showMobileMenu = !showMobileMenu">
            <!-- :style="menuSizing"  -->
            <!-- provisioning items -->

            <div class="flex flex-col overflow-scroll h-[-webkit-fill-available] pb-[var(--header-h)]">

              <template v-if="isProvisioning">
                <RouterLink
                  class="mobile-link pl-4 xs:pl-10 sm:pl-20 no-underline border-t text-2xl py-6 font-semibold" to="/getting-started">
                  <div class="mr-5">
                    <BaseIcon class="m-auto" text="HM" icon="home" themeName="rare2" diameter="2.3" />
                  </div>
                  Getting Started
                </RouterLink>
              </template>
              <template v-else>
                <div class="px-4 pt-8 pb-9 border-b border-main-alt">
                  <div class="bg-white rounded-md">
                    <RouterLink class="note-link link-button" to="/notes">
                      <img class="pl-2 pr-5" :src="require('Assets/images/notebook_blue.svg')" />
                      Your note Inbox
                      <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                    </RouterLink>
                  </div>
                </div>
                <div class="px-4 pt-8">
                  <div class="gray-gradient p-4 font-roboto text-sm font-extrabold border border-neutral rounded-md">
                    <div class="flex">
                      <span class="material-icons text-main-alt text-3xl pr-4 leading-4"> groups</span>
                      <span class="my-auto text-black">Your Team Settings</span>
                    </div>
                    <div class="py-7">
                      <span class="text-sm pl-3 pb-2"> Team & Tools</span>
                      <div class="link-subgroup">
                        <RouterLink class="mobile-link link-button" to="/users">
                          Manage Users
                          <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                        </RouterLink>
                        <!-- <RouterLink class="mobile-link link-button" to="/dahecky">
                          Team Dictionary
                          <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                        </RouterLink>
                        <RouterLink class="mobile-link link-button" to="/wassat">
                          Team Templates
                          <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                        </RouterLink> -->
                      </div>
                    </div>
                    <span class="text-sm pl-3 pb-2"> Plans & Payment</span>
                    <div class="link-subgroup">
                      <RouterLink class="mobile-link link-button" to="/billing">
                        Billing History
                        <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                      </RouterLink>
                      <RouterLink class="mobile-link link-button" to="/subscription">
                        Manage Subscription
                        <span class="material-icons text-main-alt absolute right-4"> keyboard_arrow_right</span>
                      </RouterLink>
                    </div>
                  </div>

                </div>
              </template>
            </div>

          </div>

        </TransitionGroup>
      </div>
    </nav>
  </header>
</template>

<script>
import BaseIcon from "Components/ui/BaseIcon";
import BaseIconDropdown from "Components/ui/BaseIconDropdown";

import UserAuth from "Services/UserAuth";
import { RouterLink } from "vue-router";

export default {
  name: "TheHeading",
  components: {
    BaseIcon,
    BaseIconDropdown,
    RouterLink
},
  data () {
    return {
      loading: true, // for notification loading
      isClicked: false,
      showMobileMenu: false,
      showProfileMenu: false,
      notifCount: 0,
      windowWidth: window.innerWidth,

    };
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isUserSuper () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Subscription Manager") !== -1;
    },
    backMessage () {
      if (!this.userPerms.user_management) return "Back to notes list";
      if (this.$route.name === "UserProfileId") return "Back To User List";
      if (!this.$router.options.history.state.back) return "Back To User List";
      if (this.$router.options.history.state.back === '/users') return "Back To User List";
      if (this.$router.options.history.state.back !== '/settings/templates') return "Go Back";
      return "Back To User List";

    },
    menuSizing () {
      if (!this.showMobileMenu) return 0;
      let body = document.body.getBoundingClientRect();
      return `height: ${body.height}px; width: ${this.windowWidth}px; min-height:100vh; min-width:100%;`;
    },
    userProfile () {
      return this.$store.getters.getUserProfile;
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn;
    },
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
    featureFlags () {
      return this.$store.getters.getFeatureFlags;
    },
    isProvisioning () {
      return this.$store.getters.getSubscriptionState === "provisioning";
    },
    fullName () {
      if (this.userProfile.firstName !== undefined &&
        this.userProfile.lastName !== undefined) {
        return this.userProfile.firstName + ' ' + this.userProfile.lastName;
      } else {
        return "";
      }
    },
    simpleNav () {
      return this.$route.meta.simpleNav;
    },
    greeting () {
      const ToD = new Date().getHours();
      let Greeting = "Good morning, ";
      if (ToD > 19) Greeting = "Good evening, ";
      if (ToD > 12) Greeting = "Good afternoon, ";
      return `${Greeting}`;
    },
    // placeholder function to update heading with messages to the user
    greetingStatus () {
      return "Save time with Talkatoo!";
    },
    initials () {
      //testing first and lastName for undefined
      let firstName = "";
      let initials = "";
      let storeFirstName = this.userProfile.firstName;
      let storeLastName = this.userProfile.lastName;
      let storeEmail = this.userProfile.email;

      if (storeFirstName) {
        firstName = storeFirstName.trim();
        //setting initials with name sections
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        }
      }
      let lastName = "";
      if (storeLastName) {
        lastName = storeLastName.trim();
        //setting initials with name sections
        if (lastName.length > 0) {
          initials += lastName[0].toUpperCase();
        }
      }
      let email = "";
      if (initials === "") {
        if (storeEmail) {
          email = storeEmail.trim();
          if (email.length > 0) {
            initials += email[0].toUpperCase();
          }
        }
      }
      return initials;
    },
  },
  methods: {
    navBack () {
      if (!this.userPerms.user_management) return this.$router.push("/notes");
      if (!this.$router.options.history.state.back) return this.$router.push("/users");
      else if (this.$router.options.history.state.back === '/users') return this.$router.push("/users");
      else if (this.$router.options.history.state.back !== '/settings/templates') return this.$router.back();
      return this.$router.push("/users");
    },
    onResize () {
      this.windowWidth = window.innerWidth;
      if (window.innerWidth > 1024) {
        this.showMobileMenu = false;
      }
    },
    toggleMobileMenu() {
      this.showProfileMenu = false;
      this.showMobileMenu = !this.showMobileMenu;
    },
    toggleProfileMenu () {
      this.showMobileMenu = false;
      this.showProfileMenu = !this.showProfileMenu;
    },
    logout () {
      this.isClicked = true;
      this.$store
        .dispatch("logout")
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isClicked = false;
        });
    },
    async navToDesktop () {
      let link = document.createElement('A');
      const userId = this.$store.getters.getUserId;
      const SUT = await UserAuth.GetSUT(userId);
      const clientId = this.$store.getters.getClientId;
      link.href = 'tkto://login?token=' + SUT.data.token + '&clientId=' + clientId + '&userId=' + userId;
      link.click();
    }

  },
};
</script>

<style scoped>
.mobile-link {
  text-decoration: none !important;
  @apply font-roboto;
  @apply text-sm;
  @apply bg-white;
  font-weight: 800;
}

.note-link {
  @apply mobile-link;
  @apply py-3;
}

header {
  @apply bg-main-alt;
  @apply pb-1;
  @apply px-1;
  @apply h-14;
}

@media (min-width: 1024px) {
  header {
    height: var(--header-h);
    background-color: #F5F6F8;
    @apply pb-0;
    @apply mx-0;

  }
}

@media (min-width: 1024px) {
  .logo-wrapper {}
}

@media (min-width: 1024px) {
  nav {}
}

.heading-text {
  vertical-align: middle;
  margin: auto;
}

.notification {
  @apply text-base;
  @apply text-neutral-darker;
  @apply py-1;
  @apply px-1;
}

.fade-duration {
  transition-duration: 0.1s;
}

.clamp-name {
  min-width: 10rem;
  max-width: 16em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
}

.header-nav {
  @apply opacity-60;
}

.header-nav:hover {
  @apply opacity-75;
}

.header-nav.router-link-active {
  @apply bg-neutral-light;
  @apply opacity-60;
}

.gray-gradient {
  background: linear-gradient(180deg, #FFF 0%, #E7EBF2 32.18%);
}

.mobile-menu-position {
  position: fixed;
  top: var(--header-h);
  left: 0px;

  height: 100%;
}
</style>