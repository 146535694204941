const ProductService = require("Services/ProductService");
import BaseModal from "Components/ui/BaseModal.vue";
import BaseSlider from "Components/ui/BaseSlider.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BirdsOnAWire from "Components/ui/BirdsOnAWire.vue";
import BillingInfoPreview from "../BillingInfoPreview.vue";
import ChargifyForm from "Components/forms/BillingModal/ChargifyForm/ChargifyForm.vue";
import ShippingForm from "Components/forms/ShippingModal/ShippingForm.vue";

// this.userCountMap[this.sliderVal] - 1
export default {
  name: "SubscriptionModal",
  components: { BaseModal, BaseSlider, BaseLoading, BirdsOnAWire, BillingInfoPreview, ChargifyForm, ShippingForm },
  props: {
    toDisplay: Boolean,
    productList: Array,
    subscription: Object,
    updatePayment: Boolean,
    countryList: Array,
    upgrade: Boolean
  },

  data () {
    let dictationSeatCount, verifiedSeatCount;
    if (this.subscription.stripe_id) {
      dictationSeatCount = this.subscription.number_of_users;
      verifiedSeatCount = this.subscription.verified_seats;
    } else {
      dictationSeatCount = this.subscription.product.number_of_users;
      verifiedSeatCount = this.subscription.verified_seats_available;
    }
    verifiedSeatCount = parseInt(verifiedSeatCount);
    dictationSeatCount = parseInt(dictationSeatCount);
    let selectedDictationSeats = dictationSeatCount + 1;
    // we can default to { handle: '' } for any product without a pricepoint, which will be treated as standard
    const pp = this.subscription?.product?.price_points[0] ?? { handle: '' };
    return {
      title: "Choose Your Plan",
      loading: false,
      // modal states for controlling the view
      planChange: true,
      confirmStep: false,
      billingPreview: false,
      paymentUpdate: !!this.updatePayment,
      shippingUpdate: false,

      shippingInfo: this.subscription.shipping_address ?? {},
      billingInfo: this.subscription.payment_profile.billing_address,
      // toggle bool between standard and annual premium plan
      standardToggle: pp.handle !== 'premium' && pp.handle !== 'annual' && pp.handle !== 'trial',
      disableStandardToggle: false,
      // variables for controlling user/verified seat selection
      selectedDictationSeats: selectedDictationSeats,
      minDictationSeats: dictationSeatCount,
      maxDictationSeats: 16,
      maxVerifiedSeats: 16,
      showVerifiedSeatsSelector: verifiedSeatCount > 0,
      selectedVerifiedSeats: verifiedSeatCount,
      minVerifiedSeats: verifiedSeatCount,
      //billing token generated for plan update w/ credit card update
      chargifyToken: undefined,
      sameAsBilling: true,
      enableCheckout: false,
      fastTrack: false,
      // currency formatter
      // list of component price points. Used to get seat price by currency. initialized in created lc hook.
      userComponentsPP: undefined,
      verifiedComponentsPP: undefined,
    };
  },
  created () {
    this.loading = true;

    // handling url redirects through
    if (this.upgrade) {
      if (this.upgrade == "fast") { // todo get a better keyword
        this.fastTrackUser();
      }
    }
  },
  watch: {
    subscription (newVal) {
      this.shippingInfo = newVal.shipping_address;
      // this can occur when shipping info updates on a trialing account
      if (newVal.payment_profile.billing_address !== undefined && newVal.payment_profile.billing_address !== null) {
        this.billingInfo = newVal.payment_profile.billing_address;
      }
    }
  },
  computed: {
    showVerified () {
      if (this.minVerifiedSeats > 0) {
        return true;
      }
      return true;
    },
    isVerifiedUpgrade () {
      return this.showVerifiedSeatsSelector && (this.selectedVerifiedSeats > 0);
    },
    subDictationSeats () {
      if (this.subscription.stripe_id) {
        return parseInt(this.subscription.dictation_seats);
      }
      return parseInt(this.subscription.product.number_of_users);
    },
    subVerifiedSeats () {
      if (this.subscription.stripe_id)
        return parseInt(this.subscription.verified_seats);
      return parseInt(this.subscription.verified_seats_available);
    },
    // returns currently selected pricepoint's component prices in the subscription's currency
    localCurrencyUserPrice () {
      if (!this.userComponentsPP) return {};
      let pp = this.userComponentsPP.find(x => x.handle === (this.pricePointHandle));
      let price = pp.prices.find(x => x.starting_quantity == this.selectedDictationSeats);
      if (price === undefined) {
        // defaulting to cheapest price when selected user count isn't isn't fround. presuming that the subscription has > 15 users and every subsuquent user will cost the cheapest price
        price = pp.prices.find(x => x.starting_quantity == 15);
      }
      if (this.subscription.currency === "USD") {
        price.price = price.unit_price; //need to mutate this to get a matching property to other currencies. prices and currency_prices are more different than they are alike
        return price;
      }
      let currencyPrice = pp.currency_prices.find(x => x.currency == this.subscription.currency && x.price_id === price.id);
      if (currencyPrice === undefined) {
        console.warn("Could not find currency price for subscription's currency and selected seat count", this.subscription.currency, price);
      }
      return currencyPrice;
    },
    // returns currently selected pricepoint's component prices in the subscription's currency
    localCurrencyVerifiedPrice () {
      if (!this.verifiedComponentsPP) return [];
      let pp = this.verifiedComponentsPP.find(x => x.default);
      if (this.subscription.currency === "USD") {
        let ret = pp.prices[0];
        //need to mutate this to get a matching property to other currencies. prices and currency_prices are more different than they are alike
        ret.price = ret.unit_price;
        return ret;
      }
      return pp.currency_prices.find(x => x.currency == this.subscription.currency);
    },
    // the price per user in an integer form. Helps with formatting and usability
    dictationSeatPrice () {
      return parseInt(this.localCurrencyUserPrice.price);
    },
    // creates displayable Price per user per month based on user count selected. No cents
    dictationSeatMonthlyPriceFormatted () {
      let price = this.standardToggle ? this.dictationSeatPrice : this.dictationSeatPrice / 12;
      return this.$store.getters.currencyFormatter.format(parseInt(price)).slice(0, -3);
    },
    verifiedSeatPrice () {
      return parseInt(this.localCurrencyVerifiedPrice.price);
    },
    verifiedSeatPriceFormatted () {
      return this.$store.getters.currencyFormatter.format(parseInt(this.verifiedSeatPrice)).slice(0, -3);
    },
    // used to display a product's total price for the modal
    productPrice () {
      if (!this.finalProduct) return NaN;
      const additionalUsers = this.selectedDictationSeats - this.finalProduct.number_of_users;
      let pp = this.finalPricePoint;

      let price = 0;
      // clipping any cents, we don't have pricing skews which have any cent value.
      if (this.subscription.currency === "USD") {
        // using type coersion here
        price = pp.price_in_cents / 100;
      }
      else {
        price = parseInt(pp.currencies.find(x => x.code === this.subscription.currency).price);
      }
      price += additionalUsers * this.dictationSeatPrice;
      price += this.selectedVerifiedSeats * this.verifiedSeatPrice;
      return price;
    },
    // used to display a product's description for the modal
    productUserDescription () {
      return `${this.selectedDictationSeats} Users - ${this.standardToggle ? "Monthly" : "Annual"}`;
    },
    billingPeriod () {
      return this.standardToggle ? "month" : "annual";
    },
    pricePointHandle () {
      return this.standardToggle ? "monthly" : "annual";
    },
    // checks the conditions required to make a credit card valid
    // payment == card && card exists && not expired
    cardValid () {
      if (this.subscription.payment_profile.payment_type === "credit_card" && this.subscription.payment_profile.masked_card_number != '') {
        const expiry = new Date(this.subscription.payment_profile.expiration_year, parseInt(this.subscription.payment_profile.expiration_month) - 1, 1);
        return expiry.getTime() > new Date().getTime();
      } else {
        return false;
      }
    },
    //determines if a user will be required to update their billing information
    billingUpdateRequired () {
      return (!this.cardValid);
    },
    // determines if the current subscription's plan is a premium plan
    isSubPremiumPlan () {
      let pp = this.subscription?.product?.price_points[0];
      if (pp) {
        // leaving premium here for historic plans
        return pp.handle === "premium" || pp.handle === "annual";
      }
      // on (double) old plans and now new plans, if we don't have price points (we will) then this fails on annual plans
      return false;
    },
    finalProduct () {
      let product = undefined;
      if (this.standardToggle) {
        product = this.productList.find(x => x.handle === "talkatoo_monthly");
      } else {
        product = this.productList.find(x => x.handle === "talkatoo_annual");
      }
      if (product === undefined) console.warn("product not found, expected handles talkatoo_monthly/talkatoo_annual not found in list of products");
      return product;
    },
    finalPricePoint () {
      let ppObj = undefined;
      if (this.finalProduct && this.finalProduct.price_points?.length > 0) {
        ppObj = this.finalProduct.price_points.find(x => x.handle === this.pricePointHandle);
      }
      if (ppObj === undefined) console.warn("Price point on final product was not found. Searched for handle: ", this.pricePointHandle);
      return ppObj;
    },
    // determines if the currently selected plan and user count is the current subscription's plan
    // this relies on the fact that we only allow users to select a user count > than their plan user count otherwise legacy plans would be broken
    // legacy plans will be considered plans
    isSelectionCurrentPlan () {
      // shortstop on not active subscription state state:
      if (this.$store.getters.isTrialing || this.$store.getters.isTrialEnded) return false;
      if (this.selectedVerifiedSeats !== this.subVerifiedSeats) return false;
      if (this.selectedDictationSeats == this.subscription.product.number_of_users
        && this.standardToggle !== this.isSubPremiumPlan) {
        return true;
      }
      return false;
    },
  },
  methods: {
    toggleModal () {
      this.statePlanSelection();
      if (this.toDisplay) {
        this.handle = this.subscription.product.handle;
      } else {
        this.showVerifiedSeatsSelector = false;
        this.selectedDictationSeats = this.minDictationSeats;
        this.selectedVerifiedSeats = this.minVerifiedSeats;
      }
      this.$emit("subscriptionToggleEvent", this.toDisplay);
    },
    // used to forward right to checkout when a user is sent an upgrade screen
    fastTrackUser () {
      this.fastTrack = true;
      this.stateConfirmStep();
    },
    // this handles the event of when a user selects a plan and will make the decision
    // to either prompt them to update their payment information or not depending on if it exists
    selectedPlan () {
      if (this.billingUpdateRequired) {
        this.statePaymentUpdate();
      } else {
        this.statePreviewBilling();
      }
    },

    updateChargifyToken (event) {
      this.chargifyToken = event.token;
      this.billingInfo = event.billingInfo;
      // in place billing update, this'll update the billing information to use elsewhere and if a user goes back they won't have to re-input the information
      // cannot do this for trialing accounts, it would mean they suddenly become paid which may not be intended
      if (this.$store.getters.isActive) {
        this.$emit("updateBillingEvent", { productId: this.subscription.product.product_id, chargifyToken: this.chargifyToken, noToggle: true });
        // token used once
        this.chargifyToken = "";
      }
      this.loading = false;
      this.stateShippingStep();
    },

    validateShippingInfo () {
      var valid = true;
      if (this.subscription.stripe_id) {
        if (this.shippingInfo.name.value === '') {
          valid = false;
        }
      } else {
        if (this.shippingInfo.firstName.value === '') {
          valid = false;
        }
        if (this.shippingInfo.lastName.value === '') {
          valid = false;
        }
      }
      if (this.shippingInfo.address1.value === '') {
        valid = false;
      }
      let contMap = this.countryList.find(x => x.value == this.country.value);
      if (!contMap || this.country.value === '') {
        valid = false;
      }

      let stateOk = this.shippingInfo.state.value !== '';
      if (contMap && contMap.states) {
        let stateMap = contMap.states.find(x => x.code == this.shippingInfo.state.value);
        stateOk = stateMap != undefined;
      }
      if (!stateOk) {
        valid = false;
      }
      if (this.shippingInfo.city.value === '') {
        valid = false;
      }
      if (this.shippingInfo.zip.value === '') {
        valid = false;
      }
      return valid;
    },
    updateShippingInfo (event) {
      // when a user explicitly changes shipping
      if (event.change) {
        let shippingData = {
          shippingInfo: event.shippingInfo,
          noToggle: true
        };
        this.$emit("updateShippingEvent", shippingData);
        // the user has left the form untouched and used billing info
      } else if (this.subscription.shipping_address.address === "") {
        let shippingData = {
          shippingInfo: this.shippingInfo, //populated from billing info
          noToggle: true
        };
        this.$emit("updateShippingEvent", shippingData);
      }

      if (this.fastTrack) {
        this.fastTrack = false;
        this.stateConfirmStep("Complete Your Purchase");
      } else {
        this.stateConfirmStep();
      }
    },

    // For shipping form, copies billing info to shipping info
    toggleSameAsBilling () {
      if (this.sameAsBilling) {
        this.shippingInfo = this.billingInfo;
      } else {
        this.shippingInfo = this.subscription.shipping_address;
      }
    },

    statePlanSelection () {
      this.title = "Choose Your Plan";
      this.planChange = true;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    statePreviewBilling () {
      this.title = "Billing Information";
      this.planChange = false;
      this.billingPreview = true;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    statePaymentUpdate () {
      this.title = "Update Payment Information";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = true;
      this.shippingUpdate = false;
      this.confirmStep = false;
    },

    stateShippingStep () {
      // set shipping info == billing info if there's no billing info
      if (this.subscription.shipping_address.address === "") {
        this.shippingInfo = this.billingInfo;
        // if billing info is valid, we allow it to be the same as the billing info
        this.this.sameAsBilling = this.validateShippingInfo();

      } else {
        this.sameAsBilling = false;
      }
      this.title = "Shipping Information";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = true;
      this.confirmStep = false;
    },

    stateConfirmStep (title) {
      this.title = title ?? "Review Changes";
      this.planChange = false;
      this.billingPreview = false;
      this.paymentUpdate = false;
      this.shippingUpdate = false;
      this.confirmStep = true;
    },

    // prevent annual plan selection while a user has verified seats
    guardAnnualSelection (e) {
      if (this.showVerifiedSeatsSelector) {
        this.standardToggle = true;
      }
    },

    // * actions for the dictation seat add/remove fields
    removeDictationSeat () {
      if (this.selectedDictationSeats > this.subDictationSeats) {
        this.selectedDictationSeats--;
      }
      //this shouldn't happen
      else if (this.selectedDictationSeats < this.subDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    addDictationSeat () {
      if (this.selectedDictationSeats < this.maxDictationSeats) {
        this.selectedDictationSeats++;
      } else if (this.selectedDictationSeats > this.maxDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    // event handler for input box changes
    dictationSeatChange () {
      if (this.selectedDictationSeats > this.maxDictationSeats) {
        this.selectedDictationSeats = this.maxDictationSeats;
      }
      if (this.selectedDictationSeats < this.subDictationSeats) {
        this.selectedDictationSeats = this.subDictationSeats;
      }
    },
    dictationInputGuard (e) {
      if (this.selectedDictationSeats === '') this.selectedDictationSeats = this.minDictationSeats;
    },

    // * actions for the verified seat add/remove fields
    removeVerifiedSeat () {
      if (this.selectedVerifiedSeats > this.subVerifiedSeats) {
        this.selectedVerifiedSeats--;
      }
      //this shouldn't happen
      else if (this.selectedVerifiedSeats < this.subVerifiedSeats) {
        this.selectedVerifiedSeats = this.subVerifiedSeats;
      }
    },
    addVerifiedSeat () {
      this.selectedVerifiedSeats++;
    },
    addVerified () {
      this.standardToggle = true;
      this.showVerifiedSeatsSelector = true;
    },
    verifiedSeatChange () {
      if (this.selectedVerifiedSeats > this.maxVerifiedSeats) {
        this.selectedVerifiedSeats = this.maxVerifiedSeats;
      }
      if (this.selectedVerifiedSeats < this.minVerifiedSeats) {
        this.selectedVerifiedSeats = this.minVerifiedSeats;
      }
    },
    verifiedInputGuard () {
      if (this.selectedVerifiedSeats === '') this.selectedVerifiedSeats = this.minVerifiedSeats;
    },

    billingFormCancel () {
      if (this.$store.getters.isActive) {
        this.statePreviewBilling();
      } else {
        this.statePlanSelection();
      }
    },

    confirmUpgrade: function () {
      this.loading = true;
      this.$emit("upgradeEvent",
        {
          productId: this.finalProduct.product_id,
          pricePoint: this.finalPricePoint,
          additionalDictationSeatCount: this.selectedDictationSeats - this.finalProduct.number_of_users,
          verifiedSeatCount: this.selectedVerifiedSeats,
          chargifyToken: this.chargifyToken
        });
      this.toggleModal();
    },
  },
};