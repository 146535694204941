<template>
  <FadeInWrapper>
    <div class="flex py-2 px-3 lg:hidden bg-[#F5F6F8] rounded-t-md">
      <button v-if="showNav == false"
              class="flex pl-2 pr-3 py-2 bg-pastel-blue-light text-main-alt font-roboto text-xs font-extrabold rounded"
              @click="showNav = true; selectedTalkatext = { talkatext_id: selectedTalkatext.talkatext_id };">
        <span class="material-icons my-auto leading-3 w-5">
          keyboard_arrow_left
        </span>
        <span class="my-auto">Talkatext List</span>
      </button>
      <button v-else
              class="flex pl-2 pr-3 py-2 bg-pastel-blue-light text-main-alt font-roboto text-xs font-extrabold rounded"
              @click="navBack">
        <span class="material-icons my-auto leading-3 w-5">
          keyboard_arrow_left
        </span>
        <span class="my-auto">back</span>
      </button>
    </div>
    <div class="flex lg:hidden bg-[#FAFBFC] justify-center border-y border-[#E7EBF2]">
      <div v-if="showNav" class="font-bold neutral-darker text-lg my-6">
        My Talkatexts
      </div>
      <div v-else class="flex flex-col my-6">
        <div class="flex justify-center  mx-10">
          <div class="inline-block font-bold neutral-darker text-lg text-center">
            {{ selectedTalkatext.keyword.trim() ? selectedTalkatext.keyword : "Untitled" }}
          </div>
          <button class="inline-block material-symbols-outlined my-auto ml-2" @click="deleteTalkatext">
            delete
          </button>
        </div>

        <div class="flex mx-auto">
          <button @click="toggleFavorite">
            <div :class="['text-2xl transform rotate-12',
                          selectedTalkatext.is_pinned ? 'fill-main-alt  text-main-alt' : 'text-neutral']">
              <svg v-if="selectedTalkatext.is_pinned" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#0386b7"><path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Z" /></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#7c7c7c"><path d="m634-448 86 77v60H510v241l-30 30-30-30v-241H240v-60l80-77v-332h-50v-60h414v60h-50v332Zm-313 77h312l-59-55v-354H380v354l-59 55Zm156 0Z" /></svg>
            </div>
          </button>
          <div class="pl-1 flex flex-col justify-center">
            <div class="inline-block my-auto text-neutral">
              Favorite your Talkatext to pin it to the top of the list.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col bg-white min-h-[60vh]">
      <div class="body-container flex flex-col 2xl:flex-row pt-7">
        <div v-if="showNav || windowWidth >= 1024" class="block 2xl:mr-3 flex-shrink pb-5">
          <SettingsNav
            :selectedTalkatext="selectedTalkatext"
            :talkatexts="talkatexts"
            :loading="loading"
            :windowWidth="windowWidth"
            @talkatextSelected="selectTalkatext"
            @createTalkatext="newTalkatext"
          />
        </div>
        <div v-if="!showNav || windowWidth >= 1024"
             class="flex flex-col flex-grow basis-full rounded-xl border border-neutral-lighter px-2 py-4 sm:px-6 sm:py-6 max-w-[60rem] mb-16">
          <template v-if="loading"></template>
          <template v-else>
            <!-- Title section -->
            <div class="title-section pb-10 hidden lg:flex justify-between">
              <div class="flex flex-col">
                <div class="flex font-extrabold text-xl ml-1 capitalize break-all">
                  <div class="inline-block">
                    {{ selectedTalkatext.keyword.trim() ? selectedTalkatext.keyword : "Untitled" }}
                  </div>
                  <button class="inline-block material-symbols-outlined my-auto ml-2" @click="deleteTalkatext">
                    delete
                  </button>
                </div>
                <div class="flex ">
                  <button @click="toggleFavorite">
                    <div :class="['text-2xl transform rotate-12',
                                  selectedTalkatext.is_pinned ? 'fill-main-alt  text-main-alt' : 'text-neutral']">
                      <svg v-if="selectedTalkatext.is_pinned" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#0386b7"><path d="m640-480 80 80v80H520v240l-40 40-40-40v-240H240v-80l80-80v-280h-40v-80h400v80h-40v280Z" /></svg>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#7c7c7c"><path d="m634-448 86 77v60H510v241l-30 30-30-30v-241H240v-60l80-77v-332h-50v-60h414v60h-50v332Zm-313 77h312l-59-55v-354H380v354l-59 55Zm156 0Z" /></svg>
                    </div>
                  </button>
                  <div class="pl-1 flex flex-col justify-center">
                    <div class="inline-block my-auto text-neutral">
                      Favorite your Talkatext to pin it to the top of the list.
                    </div>
                  </div>
                </div>
              </div>
              <div class="hidden lg:block relative h-11  min-w-[120px] sm:min-w-[160px]">
                <TransitionGroup name="fade-pull">
                  <div v-if="selectedTalkatext.pendingChange"
                       class="absolute flex flex-grow justify-center default-fade-duration w-full top-0 h-full">
                    <BaseLoading class="transition-none transform scale-50 -translate-y-1/4" />
                  </div>
                  <div v-else-if="selectedTalkatext.changeMade" key="1"
                       class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
                    <button id="saveProfile"
                            class="whitespace-nowrap rounded-full py-3 px-4 sm:px-8
                                  font-roboto text-sm font-semibold bg-main-alt hover:bg-main-dark transition colors text-white"
                            :disabled="!validateTalkatext() || !validateKey() || selectedTalkatext.pendingChange"
                            @click="saveChanges">
                      Save Changes
                    </button>
                  </div>
                </TransitionGroup>
              </div>
              <!-- <button @click="() => (selectedTalkatext.wasDeleted = !selectedTalkatext.wasDeleted)" class="bg-error">
              mock delete
            </button>
            <button @click="() => (selectedTalkatext.incomingUpdate = !selectedTalkatext.incomingUpdate)" class="bg-main-alt">
              mock incoming
            </button>
            <button @click="deleteTalkatext" class="bg-error">
              delete
            </button> -->
            </div>

            <div class="flex relative">
              <transition name="fade">
                <div v-if="selectedTalkatext.wasDeleted" class="flex transition-all text-error default-fade-duration absolute -top-2 -translate-y-full">
                  <span class="material-icons mr-2">warning</span>
                  <span class="my-auto">This command was deleted elsewhere, click save to re-add it.</span>
                </div>
              </transition>
              <transition name="fade">
                <div v-if="selectedTalkatext.incomingUpdate" class="flex transition-all text-main-alt default-fade-duration absolute -top-2 -translate-y-full">
                  <span class="material-icons mr-2">warning</span>
                  <button class="as-anchor my-auto" @click="updateSelectedWithEvent">This command was updated elsewhere, click to apply them.
                  </button>
                </div>
              </transition>
            </div>
            <!-- talkatext editing -->
            <div class="flex flex-col">
              <!-- key -->
              <div class="flex flex-col">
                <div class="pb-2 font-extrabold text-neutral-darker">
                  1. Creating your command
                </div>

                <div class="flex flex-col px-4">
                  <span class="font-roboto text-xs text-neutral"> The command will always require the word “insert” followed by your defined name. </span>
                  <div class="relative font-roboto">
                    <BaseInput v-model="selectedTalkatext.keyword" class="key-input" :maxLength="80" type="text" :errorMessage="keyError" @input="validateKey" />
                    <div class="absolute left-4 top-4 select-none">
                      <span class="font-semibold font-roboto text-sm">Insert&nbsp;&nbsp;&nbsp;- </span>
                    </div>
                  </div>
                  <!-- <div class="pl-2">
                    <button class="as-anchor font-roboto text-sm font-bold"> Test your command now.</button>
                  </div> -->
                </div>
                <div class="relative pb-2">
                  <span class="font-extrabold text-neutral-darker">
                    2. Add the content you want
                  </span>
                  <div v-if="talkatextError" class="absolute shake -bottom-1 left-4 text-xs text-error">
                    {{ talkatextError }}
                  </div>
                </div>
                <!-- editing area of the RTF  -->
                <div class="flex flex-col px-4">
                  <span class="font-roboto text-xs text-neutral"> <!-- lorem ipsum --></span>
                  <div class="pt-2 font-normal">
                    <div
                      :class="['growing-textarea p-2 border border-[#9BACB2] rounded-md',
                               talkatextError ? 'border-error' : '']">
                      <textarea id="talkatext-area" v-model="selectedTalkatext.talkatext"
                                class="custom-area w-full"
                                @input="inputDetect" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <template v-if="showNav == true">
      <Transition name="fade">
        <div class="default-fade-duration lg:hidden flex fixed bottom-0 left-0 w-full py-3 z-10 bg-main-alt min-h-[68px]">
          <div class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
            <button id="new-talkatext"
                    class="rounded-full bg-white py-3 w-2/3 mx-auto text-main-darker font-roboto text-sm font-semibold"
                    @click="newTalkatext">
              Add a new Talkatext
            </button>
          </div>
        </div>
      </Transition>
    </template>
    <template v-if="selectedTalkatext.changeMade || selectedTalkatext.pendingChange">
      <Transition name="fade">
        <div class="default-fade-duration lg:hidden flex fixed bottom-0 left-0 w-full py-3 z-10 bg-main-alt min-h-[68px]">
          <TransitionGroup name="fade-pull">
            <div v-if="selectedTalkatext.pendingChange"
                 class="absolute flex default-fade-duration w-full top-1/2 left-1/2">
              <BaseLoading class="transition-none transform scale-50 -translate-y-1/2 -translate-x-1/2 mr-4" />
            </div>
            <div v-else-if="selectedTalkatext.changeMade" key="1" class="flex flex-grow justify-end default-fade-duration replace-fade-pull w-full my-auto">
              <button id="saveProfile" :disabled="selectedTalkatext.pendingChange"
                      class="rounded-full bg-white py-3 w-2/3 mx-auto text-main-darker font-roboto text-sm font-semibold"
                      @click="saveChanges">
                Save Changes
              </button>
            </div>
          </TransitionGroup>
        </div>
      </Transition>
    </template>

  </FadeInWrapper>
</template>

<script>
import BaseInput from "@/components/ui/BaseInput.vue";
import SettingsNav from "./SettingsNav.vue";
import FadeInWrapper from "Components/layout/FadeInWrapper.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
const TalkatextService = require("Services/TalkatextService.js");

export default {
  name: "Settings",
  components: { SettingsNav, FadeInWrapper, BaseLoading, BaseInput },
  data () {
    return {
      loading: true,
      pendingChange: false,
      changeMade: false,
      talkatexts: [],
      selectedTalkatext: { keyword: "", talkatext: "", is_pinned: false },
      fieldElement: undefined,
      keyError: "",
      talkatextError: "",
      wsId: 0,
      windowWidth: window.innerWidth,
      showNav: true,
    };
  },
  created () {
    this.wsId = this.$store.getters.getWebsocketEventHandler.onTalkatextUpdateEvent(this.talkatextUpdateEventHandler);

    TalkatextService.GetAll()
      .then((resp) => {
        this.talkatexts = resp.data.talkatext;
        if (this.talkatexts.length === 0) {
          this.selectedTalkatext = this.defaultTalkatext();
        } else {
          this.sortTalkatexts();
          this.selectTalkatext(this.talkatexts[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
  },
  methods: {
    onResize (e) {
      console.log(e);
      if (this.windowWidth === window.innerWidth) return;
      this.showNav = true;
      this.windowWidth = window.innerWidth;
    },
    sortTalkatexts () {
      this.talkatexts
        .sort((a, b) => {
          return b.talkatext_id - a.talkatext_id;
        })
        .sort((a, b) => {
          return a.keyword == b.keyword ? 0 : a.keyword > b.keyword ? 1 : -1;
        })
        .sort((a, b) => {
          if (a.is_pinned == b.is_pinned) return 0;
          return a.is_pinned ? -1 : 1;
        });
    },
    defaultTalkatext () {
      return { keyword: "", talkatext: "", is_pinned: false, changeMade: false, pendingChange: false };
    },
    navBack () {
      if (!this.$router.options.history.state.back)
        return this.$router.push("/profile");
      else if (this.$router.options.history.state.back !== "/user")
        return this.$router.back();
    },
    selectTalkatext (t) {
      if (t.talkatext_id === this.selectedTalkatext.template_id) return;
      if (this.selectedTalkatext.changeMade) {
        if (this.selectedTalkatext.tmpKeyword !== "")
          this.selectedTalkatext.keyword = this.selectedTalkatext.tmpKeyword;
        this.talkatextError = "";
        this.keyError = "";
      }
      this.showNav = false;
      this.selectedTalkatext = this.talkatexts.find(
        (x) => x.talkatext_id === t.talkatext_id,
      );
      this.selectedTalkatext.tmpKeyword = this.selectedTalkatext.keyword;
      this.selectedTalkatext.justEdited = false;
      this.selectedTalkatext.updated = false;
      this.selectedTalkatext.incomingUpdate = false;
    },
    newTalkatext () {
      this.showNav = false;
      this.selectedTalkatext = this.defaultTalkatext();
    },
    toggleFavorite () { this.selectedTalkatext.changeMade = true; this.selectedTalkatext.is_pinned = !this.selectedTalkatext.is_pinned; this.sortTalkatexts(); },
    validateKey () {
      this.selectedTalkatext.changeMade = true;
      if (this.selectedTalkatext.keyword.length == 0) {
        this.keyError = "Add a command to the Talkatext.";
        return false;
      }
      if (-1 !== this.talkatexts.findIndex(x =>
        x.keyword.toLowerCase().trim() == this.selectedTalkatext.keyword.toLowerCase().trim()
        && x.talkatext_id !== this.selectedTalkatext.talkatext_id)) {
        this.keyError = "This is a duplicate command.";
        return false;
      }
      this.keyError = "";
      return true;
    },
    validateTalkatext () {
      let valid = true;
      if (!this.selectedTalkatext.talkatext) {
        valid = false;
      }
      if (this.selectedTalkatext.talkatext.length > 10000) {
        valid = false;
        this.talkatextError = "Talkatext cannot be over 10000 characters long";
      }
      return valid;
    },
    inputDetect (event) {
      this.talkatextError = '';
      this.selectedTalkatext.changeMade = true;
      this.selectedTalkatext.keyword = this.selectedTalkatext.keyword.trim().toLowerCase();
      // let fieldElement = document.getElementById("talkatext-area");
      // fieldElement.parentNode.dataset.replicatedValue = this.selectedTalkatext.talkatext;
      this.validateTalkatext();

    },
    // entry function for committing changes for a new or existing talkatext
    saveChanges () {
      if (!this.validateTalkatext()) {
        return;
      }
      this.selectedTalkatext.pendingChange = true;
      this.selectedTalkatext.keyword = this.selectedTalkatext.keyword.trim().toLowerCase();
      if (!this.selectedTalkatext.talkatext_id) {
        this.createTalkatext();
      } else {
        this.updateTalkatext();
      }
    },
    updateTalkatext () {
      // take ref incase talkatext changes
      let ref = this.selectedTalkatext;
      this.sortTalkatexts();
      TalkatextService.Update(ref)
        .then((resp) => {
          ref.justEdited = true;
          this.$toast.success({ message: "Talkatext Updated!" });
          ref.changeMade = false;
        }).catch((err) => {
          console.log(err);
          this.$toast.error({ message: "Talkatext failed to update!" });
        }).finally(() => {
          ref.pendingChange = false;
        });
    },
    createTalkatext () {
      // take ref incase talkatext changes
      let ref = this.selectedTalkatext;
      TalkatextService.Create(this.selectedTalkatext)
        .then((resp) => {
          ref.talkatext_id = resp.data.talkatext_id;
          ref.justEdited = true;
          this.talkatexts.push(ref);
          this.sortTalkatexts();
          ref.changeMade = false;
        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          ref.pendingChange = false;
        });
    },
    deleteTalkatext () {
      this.showNav = true;
      let deleted = [];
      // not deleting a new unsaved talkatext
      if (this.selectedTalkatext.talkatext_id) {
        TalkatextService.Delete(this.selectedTalkatext)
          .then((resp) => {
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error({ message: "There was an error deleting the talkatext!" });
            this.talkatexts.push(...deleted);
          });
      }

      if (this.talkatexts.length === 0) {
        this.selectedTalkatext = this.defaultTalkatext();
      } else {
        // remove deleted talkatext
        let index = this.talkatexts.findIndex(x => x.talkatext_id == this.selectedTalkatext.talkatext_id);
        if (index !== -1) deleted = this.talkatexts.splice(index, 1);
        if (this.talkatexts.length === 0) {
          this.selectedTalkatext = this.defaultTalkatext();
        } else {
          this.selectedTalkatext = this.talkatexts[0];
        }
      }
    },
    talkatextUpdateEventHandler (event) {
      let talkatext = this.talkatexts.find(x => x.talkatext_id == event.talkatextId);
      // either a talkatext was deleted or created
      if (!talkatext) {
        // event deleted
        if (event.updatedKeyword == "") {
          let index = this.talkatexts.findIndex(x => x.talkatext_id == event.talkatextId);
          if (index < 0) return;
          this.talkatexts.splice(index, 1);
          if (event.TalkatextId == this.selectedTalkatext.talkatext_id) {
            this.selectedTalkatext.wasDeleted = true;
            this.selectedTalkatext.changeMade = true;
          }
          return;
        }
        // created elsewhere I guess
        talkatext = this.defaultTalkatext();
        talkatext.talkatext_id = event.talkatextId;
        talkatext.keyword = event.updatedKeyword;
        talkatext.talkatext = event.updatedTalkatext;
        talkatext.is_pinned = event.isPinned;
        talkatext.updated = true;
        this.talkatexts.push(talkatext);
        this.sortTalkatexts();
      }
      // ignore events if user made the change here.
      // justEdited bool is faulty but its probably good enough.
      else if (talkatext && !talkatext.justEdited) {
        talkatext.updateEvent = event;
        if (event.TalkatextId == this.selectedTalkatext.talkatext_id) {
          this.selectedTalkatext.incomingUpdate = true;
          this.selectedTalkatext.websocketEvent = event;
          // talkatext deleted.
        } else if (event.updatedKeyword == "") {
          // remove deleted talkatext
          let index = this.talkatexts.findIndex(x => x.talkatext_id == talkatext.talkatext_id);
          this.talkatexts.splice(index, 1);
          if (this.talkatexts.length === 0) {
            this.selectedTalkatext = this.defaultTalkatext();
          } else {
            this.selectedTalkatext = this.talkatexts[0];
          }
          // update talkatext with the latest info
        } else {
          talkatext.keyword = event.updatedKeyword;
          talkatext.talkatext = event.updatedTalkatext;
          talkatext.is_pinned = event.isPinned;
        }
      }
    },
    // take the websocket event and apply the changes to the selected talkatext
    updateSelectedWithEvent () {
      let event = this.selectedTalkatext.websocketEvent;
      this.selectedTalkatext.updated = false;
      this.selectedTalkatext.incomingUpdate = false;
      if (!event) return;
      this.selectedTalkatext.keyword = event.updatedKeyword;
      this.selectedTalkatext.talkatext = event.updatedTalkatext;
      this.selectedTalkatext.is_pinned = event.isPinned;
    }
  }
};
</script>

<style scoped>
input:focus-visible~div>* {
  visibility: visible;
}

.custom-area {
  outline: none;
  resize: none;
  display: inline-table;
  cursor: text;
  background: rgba(1, 1, 1, 0);
  @apply transition-colors;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

.custom-area,
.growing-textarea {
  overflow-y: auto;
  min-height: 40vh;
}

.growing-textarea {
  display: grid;
}

.growing-textarea::after {
  /* Note the weird space! Needed to prevent jumpy behavior */
  content: attr(data-replicated-value) " ";
  /* This is how textarea text behaves */
  white-space: pre-wrap;
  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
</style>
<!-- unscoped style -->
<style>
.key-input input.base-input {
  @apply pl-20;
  @apply h-9;
}
</style>