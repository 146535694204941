import axios from 'axios';
import { store } from '../store';

// const SERVICE_URL = "http://localhost:3000/";
const SERVICE_URL = `${__API_URL}/mixpanel/`;

const Track = (eventName, properties) => {
  const body = [{
    "event": eventName,
    "properties": {
      "token": __MIXPANEL_PUBLIC_KEY,
      "distinct_id": store.getters.getUserId == '' ? undefined : store.getters.getUserId.toString(),
      "email": store.getters.getUserProfile.email,
      "name": `${store.getters.getUserProfile.firstName} ${store.getters.getUserProfile.lastName}`,
      ...properties
    }
  }];
  return axios({
    method: 'POST',
    url: SERVICE_URL + 'track',
    data: body
  });
};

const Engage = (properties) => {
  const body = [{
    "$token": __MIXPANEL_PUBLIC_KEY,
    "$distinct_id": store.getters.getUserId == '' ? undefined : store.getters.getUserId.toString(),
    "$set": {
      "email": store.getters.getUserProfile.email,
      "name": `${store.getters.getUserProfile.firstName} ${store.getters.getUserProfile.lastName}`,
      ...properties
    }
  }];

  return axios({
    method: 'POST',
    url: SERVICE_URL + 'engage',
    data: body
  });
};

// special engage for setting userprofile on our testing project.
const EngageLog = (properties) => {
  const body = [{
    "$token": __MIXPANEL_PUBLIC_KEY_ERRORS,
    "$distinct_id": store.getters.getUserId == '' ? undefined : store.getters.getUserId.toString(),
    "$set": {
      "$email": store.getters.getUserProfile.email,
      "$name": `${store.getters.getUserProfile.firstName} ${store.getters.getUserProfile.lastName}`,
      ...properties
    }
  }];

  return axios({
    method: 'POST',
    url: SERVICE_URL + 'engage',
    data: body
  });
};

const Log = (eventName, properties) => {
  const body = {
    "event": eventName,

    properties: {
      "token": __MIXPANEL_PUBLIC_KEY_ERRORS,
      "distinct_id": store.getters.getUserId == '' ? undefined : store.getters.getUserId.toString(),
      "email": store.getters.getUserProfile.email,
      "name": `${store.getters.getUserProfile.firstName} ${store.getters.getUserProfile.lastName}`,
      ...properties
    }
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL + 'track',
    data: body
  });
};

export default {
  Track,
  Engage,
  EngageLog,
  Log,
};


