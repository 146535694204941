import axios from 'axios';
import { store } from '../store';

const SERVICE_URL_CGFY = __API_URL_V6 + 'subscription/';
const SERVICE_URL_STRIPE = __API_URL_V7 + 'subscription/';

function SERVICE_URL () {
  if (store.getters.getBillingProvider === "stripe") {
    return SERVICE_URL_STRIPE;
  }
  return SERVICE_URL_CGFY;
}

function GetSubscription () {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL() + subscriptionId,
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function PostSubscription(subscription){
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId,
    data: subscription
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpdatePermissions (batch) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId + '/permissions',
    data: batch
  });
}

function GetSubscriptionUsers () {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL() + subscriptionId + "/users",
    data: { subscription_id: subscriptionId }
  });
}

function GetPermissions (userId) {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL() + subscriptionId + "/permissions/" + userId,
    data: { subscription_id: subscriptionId }
  });
}

function DeleteSubscriptionUser (userId) {
  //getting the current user's subscription
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'DELETE',
    url: SERVICE_URL() + subscriptionId + "/users/" + userId,
  });
}

function ReactivateSubscription () {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: `${SERVICE_URL_STRIPE}${subscriptionId}/reactivate`,
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });

}

function UpdateSubscription (productId, token) {
  const subscriptionId = store.getters.getSubscriptionId;
  if (token == undefined)
    token = "";

  const payload = {
    "chargify_token": token,
    "product_id": productId
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId,
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpdateStripeSubscription (token) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    "stripe_token": token.id,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId,
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

// *sub_id/Upgrade* is an immediate subscription change, rather than waiting for a billing
function MigrateSubscription (productId, pricePointId, chargifyToken) {
  const subscriptionId = store.getters.getSubscriptionId;
  if (chargifyToken == undefined)
    chargifyToken = "";
  const payload = {
    "chargify_token": chargifyToken,
    "product_id": productId,
    "price_point_id": pricePointId,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId + '/upgrade',
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpgradeSubscriptionProduct (products, stripeToken) {
  const subscriptionId = store.getters.getSubscriptionId;
  if (stripeToken == undefined)
    stripeToken = "";
  const payload = {
    "stripe_token": stripeToken,
    "products": products,
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId + '/products',
    data: payload
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}



function SubscriptionComponents (additionalUserCount, ppHandle, accrue_charge) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    new_component_allocation: {
      chargify_component_handle: "users",
      chargify_component_price_point_handle: ppHandle,
      component_allocation_count: additionalUserCount,
      accrue_charge: accrue_charge
    }
  };
  // expect SubscriptionResponse
  return axios({
    method: 'POST',
    data: payload,
    url: SERVICE_URL() + subscriptionId + '/components',
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function UpdateVerifiedCount (new_count) {
  const subscription_id = store.getters.getSubscriptionId;
  return axios({
    method: 'POST',
    url: SERVICE_URL() + `${subscription_id}/verified_count`,
    data: {
      new_count
    }
  }).then((resp) => {
    store.commit("set_subscription", resp.data);
    return resp;
  });
}

function GetInvites () {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL() + subscriptionId + '/invites',
    data: { 'subscription_id': subscriptionId }
  });
}

function CreateInvites (invitees) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    subscription_id: subscriptionId,
    invitees: invitees
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId + '/invites',
    data: payload
  });
}


function ResendInvites (invites) {
  const subscriptionId = store.getters.getSubscriptionId;
  const payload = {
    invites: invites
  };
  return axios({
    method: 'POST',
    url: SERVICE_URL() + subscriptionId + '/invites/resend',
    data: payload
  });
}

function DeleteInvite (inviteId) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'DELETE',
    url: SERVICE_URL() + subscriptionId + '/invites/' + inviteId,
  });
}

function GetStatementPage (page, pageSize) {
  const subscriptionId = store.getters.getSubscriptionId;
  return axios({
    method: 'GET',
    url: SERVICE_URL_CGFY + subscriptionId + `/statements?page_number=${page}&page_size=${pageSize}`,
  });
}
function GetStripeStatementPage (statementId, pageSize) {
  const subscriptionId = store.getters.getSubscriptionId;
  let startingAfter = "";
  if (statementId !== undefined){
    startingAfter = `&starting_after=${statementId}`;
  }
  return axios({
    method: 'GET',
    url: SERVICE_URL_STRIPE + subscriptionId + `/statements?page_size=${pageSize}${startingAfter}`,
  });
}

function GetChargifyJWT () {
  return axios({
    method: 'POST',
    url: __API_URL_V6 + "chargify_jwt",
  });
}



export default {
  GetSubscription,
  PostSubscription,
  UpdatePermissions,
  GetSubscriptionUsers,
  GetPermissions,
  DeleteSubscriptionUser,
  ReactivateSubscription,
  UpdateSubscription,
  UpdateStripeSubscription,
  MigrateSubscription,
  UpgradeSubscriptionProduct,
  SubscriptionComponents,
  UpdateVerifiedCount,
  GetInvites,
  CreateInvites,
  ResendInvites,
  DeleteInvite,
  GetStatementPage,
  GetStripeStatementPage,
  // for chargify js auth
  GetChargifyJWT
};