import { shallowRef } from "vue";
const NotesService = require("Services/NotesService");
import MixpanelService from "Services/MixpanelService";
import StandardTemplate from "Components/layout/StandardTemplate";
import BaseLoading from "Components/ui/BaseLoading.vue";
import BaseTable from "Components/ui/BaseTable/BaseTable.vue";
import BaseSwitch from "Components/ui/BaseSwitch.vue";
import BaseModal from "Components/ui/BaseModal.vue";
import TableLabel from "./TableLabel.vue";
import TableTitle from "./TableTitle.vue";
import TableActionList from "./TableActionList.vue";
import NoteStatesMap from "Modules/NoteStatesMap";
import { QueryManager, NoteViews } from "Modules/NotesQueryManager.js";
import { IssueMessageTypes } from "@/modules/Websocket.js";
import { Sorting } from "@/modules/NotesQueryManager";

// simple hash function. Used to create an id for saved filters on the json string of the filter config
function hash (str) {
  let hash = 32;
  for (let i = 0, len = str.length; i < len; i++) {
    let c = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + c;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export default {
  name: "Notes",
  components: { BaseTable, BaseLoading, StandardTemplate, BaseSwitch, BaseModal },
  emits: ["noteViewChange"],
  props: {
    kanbanView: Boolean,
    noteUsage: Object
  },
  data () {
    const currUserId = this.$store.getters.getUserId;
    let userConfig = localStorage.getItem(`Notes:${currUserId}UserConfig`);
    if (!userConfig) userConfig = {};
    else userConfig = JSON.parse(userConfig);
    let pageSize = userConfig.pageSize ?? 25;
    if (![15, 25, 50, 100].includes(userConfig.pageSize)) {
      pageSize = 25;
      userConfig.pageSize = pageSize;
    }
    let sort = userConfig.sortBy;
    if (!sort) {
      // for scribes sort by status
      if (this.$store.getters.getUserGroups && this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1) {
        sort = { field: "status", sortBy: Sorting.ASCENDING };
      } else {
        sort = { field: "date", sortBy: Sorting.DESCENDING };
      }
    }
    let savedFilters = userConfig.savedFilters ?? [];
    return {
      currUserId: currUserId,
      loading: true,
      tableTitle: "Active Notes",
      authors: [],
      notes: undefined,
      activeNotes: undefined,
      inPmsNotes: undefined,
      archivedNotes: undefined,
      tableData: [],
      colDef: {},
      pagination: { page: 1, pageSize: pageSize, truePagination: true },
      sort: sort,
      customTableFilters: undefined,
      queryManager: new QueryManager({ paged: true, page: 0, pageLength: pageSize, asTable: true }),
      activeRequest: 0,

      savedFilters: savedFilters,
      userConfig: userConfig,
      // bools for what type of notes are displayed on the pages
      noteView: NoteViews.ACTIVE,
      archived: false,
      inPms: false,
      messages: [],

      // websocket props
      wsIds: [],
      eventBatching: { timeoutId: -1, events: [] },

      // for table row selection
      selectedData: [],
      showArchiveModal: false,

      actionList: {
        component: shallowRef(TableActionList),
        props: {
          selectedData: [],
          activeView: true
        },
      },

    };
  },

  created () {
    const queryParams = this.$router.currentRoute.value.query;
    if (queryParams?.archived) {
      this.archived = queryParams.archived == 'true';
      if (this.archived) this.tableTitle = "Archived Notes";
    } else if (queryParams?.in_pms) {
      this.inPms = queryParams.in_pms == 'true';
      if (this.inPms) this.tableTitle = "Notes in PMS";
    }
    this.noteView = this.archived ? NoteViews.ARCHIVED :
      this.inPms ? NoteViews.IN_PMS : NoteViews.ACTIVE;
    if (queryParams?.page) {
      this.pagination.page = parseInt(queryParams?.page);
      if (isNaN(this.pagination.page)) this.pagination.page = 1;
    }
    if (queryParams?.pageSize) {
      this.pagination.pageSize = parseInt(queryParams?.pageSize);
      if (isNaN(this.pagination.page)) this.pagination.pageSize = this.userConfig.pageSize;
    }
    this.actionList.props.activeView = !this.archived;
    this.getPageDataFromCachedFilters();
  },
  mounted () {
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteStateEvent(this.noteStateEventHandler));
    this.wsIds.push(this.$store.getters.getWebsocketEventHandler.onNoteIssueEvent(this.noteIssueEventHandler));
  },
  watch: {
    userConfig: {
      handler () {
        this.$nextTick(() => {
          this.getPageData();
        });
      },
      deep: true,
    },
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    isUserVerified () {
      return this.$store.getters.getUserPerms.verified;
    },
    // filters selectedData to the set of notes a user may actually archive
    archiveListNotes () {
      return this.selectedData.filter(row => row.user_id == this.currUserId);
    }
  },
  methods: {
    toggleListType () {
      this.$store.commit("set_notes_view", "kanban");
      this.$emit("noteViewChange", "kanban");
    },
    // '{"noteId":26,"eventUserId":1,"oldState":2,"newState":3,"verify":false,"eventTs":1694780030,"authorId":1}'
    noteStateEventHandler (event) {
      // there is not a (simple) way to handle note updates coming in than just
      // invalidating all cache in the query manager
      this.queryManager?.invalidateCache();
      // search for the note in the current view, add a notif icon for it and create a toast message
      // events are batch to prevent spamming a view with tons of toast messages
      let i = this.notes?.findIndex(n => n.id == event.noteId) ?? -1;
      if (i === -1) return;

      this.eventBatching.events.push(event);
      // setting a timeout to batch note change events.
      if (this.eventBatching.timeoutId !== -1) window.clearTimeout(this.eventBatching.timeoutId);
      this.eventBatching.timeoutId = window.setTimeout(() => {
        let ids = this.eventBatching.events.map(x => x.noteId)
          .filter((item, i, self) => self.indexOf(item) === i);
        if (ids.length === 1) {
          let note = this.notes.find(n => n.id == event.noteId);
          let startEvent = this.eventBatching.events[0];
          let endEvent = this.eventBatching.events[this.eventBatching.events.length - 1];

          // guard on state toast message. don't post toast on no real state change
          if (startEvent.oldState === endEvent.newState) return;

          let noteRef = note.title ? `"${note.title}"` : `Note ${startEvent.noteId}`;
          this.$toast.success({
            message: `${noteRef} changed from ${NoteStatesMap[startEvent.oldState]} to ${NoteStatesMap[endEvent.newState]}.`,
            action: { fn: this.getPageData, message: "Refresh" },
            timeout: 5000,
          });
        } else {
          //counting unique ids
          this.$toast.success({
            message: `${ids.length} Notes were updated!`,
            action: { fn: this.getPageData, message: "Refresh" },
            timeout: 5000,
          });
        }
        this.eventBatching = { timeoutId: -1, events: [] };
      }, 500);
      // disabled updating state. doing this would animate the table to add/remove notes based on filters
      // this.notes[i].`state = `event.newState;
      this.notes[i].updated = true;
      this.notes[i].updatedState = event.newState;
      this.notes[i].updatedState = event.newState;
      this.buildTable(this.notes);
    },
    noteIssueEventHandler (event) {
      // there is not a (simple) way to handle note updates coming in than just
      // invalidating all cache in the query manager
      this.queryManager?.invalidateCache();
      // search for the note in the current view, add a notif icon for it and create a toast message
      // events are batch to prevent spamming a view with tons of toast messages
      let i = this.notes?.findIndex(n => n.id == event.noteId) ?? -1;
      if (i === -1) return;

      let noteMessage = "";
      switch (event.eventType) {
        case (IssueMessageTypes.NEW_ISSUE):
          noteMessage = `Note ${event.noteId} had an issue raised on it!`;
          this.notes[i].needs_attention = true;
          break;
        case (IssueMessageTypes.ISSUE_RESPONSE):
          noteMessage = `Note ${event.noteId} had a response to the issue!`;
          this.notes[i].number_of_responses = 1 + (this.notes[i].number_of_responses ?? 0);
          break;
        case (IssueMessageTypes.ISSUE_RESOLVED):
          noteMessage = `Note ${event.noteId} had its issue resolved!`;
          this.notes[i].needs_attention = false;
          this.notes[i].state = 1;

          break;
      }
      this.$toast.success({
        message: noteMessage,
        action: { fn: () => { this.$router.push(`/notes/${event.noteId}`); }, message: "Take me to it" },
      });
      this.notes[i].updated = true;
      this.buildTable(this.notes);
    },
    swapActiveArchived () {
      this.archived = !this.archived;
      this.inPms = false;
      this.noteView = this.archived ? NoteViews.ARCHIVED :
        this.inPms ? NoteViews.IN_PMS : NoteViews.ACTIVE;
      //update url, this doesn't redirect
      if (this.archived) {
        this.$router.push('/notes?archived=true');
        this.tableTitle = "Archived Notes";
      } else {
        this.$router.push('/notes?archived=false');
        this.tableTitle = "Active Notes";
      }
      this.actionList.props.activeView = !this.archived;
      // update page data
      this.getPageData();
    },

    swapActiveInPms () {
      this.inPms = !this.inPms;
      this.archived = false;
      this.noteView = this.archived ? NoteViews.ARCHIVED :
        this.inPms ? NoteViews.IN_PMS : NoteViews.ACTIVE;
      //update url, this doesn't redirect
      if (this.inPms) {
        this.$router.push('/notes?in_pms=true');
        this.tableTitle = "Notes in PMS";
      } else {
        this.$router.push('/notes?in_pms=false');
        this.tableTitle = "Active Notes";
      }
      this.actionList.props.activeView = !this.archived;
      // update page data
      this.getPageData();
    },
    getPageDataFromCachedFilters () {
      NotesService.GetAuthorList()
        .then((resp) => {
          let i = 0;
          const names = resp.data.authors.map(x => `${x.first_name} ${x.last_name}`);
          this.authors = resp.data.authors.map((x, index, self) => {
            // add integer to names when there are duplicates. Not great but its something
            let dup = names.indexOf(`${x.first_name} ${x.last_name}`) !== index;
            return {
              firstName: x.first_name, lastName: x.last_name,
              name: `${x.first_name} ${x.last_name}${dup ? ` ${(++i)}` : ''}`,
              userId: parseInt(x.user_id)
            };
          });
          this.setCustomTableFilter();
          //dependant on author list so performing nested requests
          this.queryManager.getNotesWith({
            filters: this.customTableFilters.input.map(x => ({ ...x })),
            sorting: this.userConfig.sortBy,
            paged: true,
            page: this.pagination.page,
            pageSize: this.pagination.pageSize,
            noteView: this.noteView,
          })
            .then((resp) => {
              this.notes = resp.data.notes;
              this.notes.forEach(note => {
                if (note.needs_attention) {
                  note.state = NoteStatesMap.indexOf("Needs Attention");
                }
              });
              this.pagination.totalPages = resp.data.pages;
              this.pagination.totalCount = resp.data.total_count;
              this.buildTable(resp.data.notes);
              this.loading = false;
            }).catch(err => {
              this.$toast.error({ message: "There was an error getting the Notes!" });
              console.log(err);
            });
        })
        .catch(err => {
          this.$toast.error({ message: "There was an error getting the list of verified users!" });
          console.log(err);
        });

    },
    getPageData () {
      // query for notes
      this.loading = true;
      this.selectedData = [];
      this.activeRequest += 1;
      const currRequest = this.activeRequest;

      this.NoteListProm = this.queryManager.getNotesWith({
        filters: this.customTableFilters.input.map(x => ({ ...x })),
        sorting: this.userConfig.sortBy,
        paged: true,
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        noteView: this.noteView,
      })
        .then((resp) => {
          if (this.activeRequest !== currRequest) return;
          this.notes.forEach(note => {
            if (note.needs_attention) {
              note.state = NoteStatesMap.indexOf("Needs Attention");
            }
          });
          this.notes = resp.data.notes;
          this.notes.forEach(note => {
            if (note.needs_attention) {
              note.state = NoteStatesMap.indexOf("Needs Attention");
            }
          });
          this.pagination.totalPages = resp.data.pages;
          this.pagination.totalCount = resp.data.total_count;
          // update the custom filters for the new set of authors.
          this.setCustomTableFilter();
          this.buildTable(resp.data.notes);
        })
        .catch(err => {
          this.$toast.error({ message: "There was an error getting the Notes!" });
          console.log(err);
        }).finally(() => {
          this.loading = false;
        });
    },
    trackNoteNav (note) {
      let subId = this.$store.getters.getSubscriptionId;
      MixpanelService.Track("AdminPortal:NoteNavigation", {
        subscription_id: subId,
        note_id: note.id,
        source: "table",
      });
    },
    buildTable (notes) {
      this.tableData = [];
      notes.forEach((note => {
        // value is created date for drafts, otherwise, it will be published date
        let tableDateStamp = 0;
        if (note.state == 0 || note.state == 5) {
          tableDateStamp = new Date(parseInt(note.created_at) * 1000);
        } else {
          let ts = note.published_at == 0 ? note.last_edited : note.published_at;
          tableDateStamp = new Date(parseInt(ts) * 1000);
        }

        //initializing the state displayed in the table, passed to the table label component
        let respCount = note?.number_of_responses;
        let noteStatus =
          note.archived ? "Archived" :
            note.needs_attention && respCount >= 1 ? "Resp:Needs Attention" :
              note.needs_attention ? "Needs Attention" :
                NoteStatesMap[note.state];
        // this is the value the filters will use to filter for note state
        let filterStatus =
          note.archived ? "Archived" :
            note.needs_attention ? "Needs Attention" :
              NoteStatesMap[note.state];
        // if the note is in state verified replace the verified language with completed
        if (note.state == 3 && !this.isUserVerified && !this.isUserScribe) {
          filterStatus = filterStatus.replace("Verified", "Completed");
        }
        let dates = {
          date: Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", year: "numeric", }).format(tableDateStamp),
          time: Intl.DateTimeFormat(undefined, { hour: "numeric", minute: "numeric", second: "numeric" }).format(tableDateStamp),
        };
        let titleSortText = (note.title.trim() ? note.title : note.transcript_preview).trim();
        titleSortText = titleSortText ? titleSortText.toLowerCase() : "untitled note";

        let params = new URLSearchParams();
        if (note.needs_attention) {
          params.append('has_issue', 'true');
        }
        if (note.metadata.user_preferred_view) {
          params.append('view', note.metadata.user_preferred_view);
        }
        const rowLink = params.size == 0 ? `/notes/${note.id}` : `/notes/${note.id}?${params.toString()}`;
        let preferredView = '';
        //handing a 'nil' case
        if (note.metadata.user_preferred_view == '') {
          if (note.newState == 1) {
            preferredView = "Available";
          } else if (note.state == 0) {
            preferredView = '';
          } else if (note.state == 5) {
            preferredView = 'Processing';
          } else {
            preferredView = "Available";
          }
        } else {
          preferredView = note.metadata.user_preferred_view == 'no_template' ? "Transcript" :
            note.metadata.user_preferred_view.toUpperCase();
        }
        let row = {
          id: note.id,
          // initial sort of the table data by notif == true
          updated: note.updated ?? false,
          user_id: note.user_id,
          title: {
            title: note.title,
            text: note.transcript_preview,
            sort_text: titleSortText,
          },
          needs_attention: note.needs_attention,
          responseCount: respCount,
          // filter Field, not for displaying. key'd to the custom filter field
          recorded_by_name: note.metadata.recorded_by_name,
          recorded_by_sort: note.metadata.recorded_by_name.trim().toLowerCase(),
          timestamp: tableDateStamp.getTime(),
          created_at: note.created_at,
          published_at: note.published_at,
          date: dates,
          status: noteStatus,
          _status: filterStatus,
          state: note.state,
          user_preferred_view: preferredView,
          // view: note.metadata.user_preferred_view,
          rowLink: { to: rowLink },
        };
        if (note.updatedState) {
          row.updatedStatus = NoteStatesMap[note.updatedState];
        }
        this.tableData.push(row);
      }));

      // function to sort needs attention notes to the top
      const needsAttnFn = function (row1, row2) {
        if (row2.needs_attention) return 1;
        if (row1.needs_attention) return -1;
        else 0;
      };
      // sort needs attn to the top. default unsorted table.
      this.tableData.sort(needsAttnFn);
      this.colDef = {
        keyField: "id",
        col: [
          {
            field: "title",
            displayName: "Title",
            filter: true,
            sortable: true,
            sortBy: this.sort.field == "title" ? this.sort.sortBy : undefined,
            sortFunctions: [
              function (row1, row2, asc) {
                if (asc)
                  return row1.title.sort_text.localeCompare(row2.title.sort_text);
                return row2.title.sort_text.localeCompare(row1.title.sort_text);
              },
              needsAttnFn,
            ],
            customComponent (row) {
              return { component: TableTitle, props: { title: row.title.title, text: row.title.text, notif: row.updated } };
            },
          },
          // {
          //   field: "view",
          //   displayName: "view",
          // },
          {
            field: "user_preferred_view",
            displayName: "Template",
            sortable: false,
            filter: true,
            // sortBy: this.sort.field == "user_preferred_view" ? this.sort.sortBy : undefined,
            // sortFunctions: [
            //   function (row1, row2, asc) {
            //     if (asc)
            //       return row1.user_preferred_view.localeCompare(row2.user_preferred_view);
            //     return row2.user_preferred_view.localeCompare(row2.user_preferred_view);
            //   },
            //   needsAttnFn,
            // ],
            colClass: "whitespace-nowrap w-32",
            customDisplay: (val) => {
              let html =
                `<span class="hidden xl:block"> ${val} </span>
                <span class="block xl:hidden px-4 xl:px-0 text-sm font-roboto mb-1">
                   <strong> Template: ${val}</strong>&nbsp
                </span>`;
              return html;
            },
          },
          // {
          //   field: "view",
          //   displayName: "view",
          // },
          {
            field: "date",
            displayName: "Date",
            filter: true,
            sortable: true,
            sortBy: this.sort.field == "date" ? this.sort.sortBy : undefined,
            sortFunctions: [
              function (row1, row2, asc) {
                if (asc) return row1.timestamp == row2.timestamp ? 0 : row1.timestamp - row2.timestamp;
                return row1.timestamp == row2.timestamp ? 0 : row2.timestamp - row1.timestamp;
              },
              needsAttnFn,
            ],
            colClass: "w-44",
            customDisplay: (val) => {
              let html =
                `<span class="px-4 xl:px-0 block text-sm font-roboto">
                  <div class="inline-block"> <strong>${val.date}</strong></div>
                  <div class="inline-block"> ${val.time}</div>
                </span>`;
              return html;
            },
          },
          {
            field: "recorded_by_name",
            displayName: "Recorded By",
            filter: true,
            sortable: true,
            sortBy: this.sort.field == "recorded_by_name" ? this.sort.sortBy : undefined,
            sortFunctions: [
              // sort by date, asc
              function (row1, row2) {
                return row1.timestamp == row2.timestamp ? 0 : row1.timestamp - row2.timestamp;
              },
              // then by User name, preserve order
              function (row1, row2, asc) {
                if (asc) return row1.recorded_by_sort.localeCompare(row2.recorded_by_sort);
                return row2.recorded_by_sort.localeCompare(row1.recorded_by_sort);
              },
              needsAttnFn,
            ],
            colClass: "xl:w-52",
            customDisplay: (val) => {
              let html =
                `<div>
                  <span class="flex px-4 xl:px-0 sm:mb-6 xl:my-auto font-bold font-roboto text-sm">${val}</span>
                </div>`;
              return html;
            },
          },
          {
            field: "status",
            displayName: "Status",
            filter: true,
            sortable: true,
            sortBy: this.sort.field == "status" ? this.sort.sortBy : undefined,
            sortFunctions: [
              // sort by   date,
              function (row1, row2) {
                return row1.timestamp == row2.timestamp ? 0 : row1.timestamp - row2.timestamp;
              },
              // then by  state, preserve order
              function (row1, row2, asc) {
                if (asc)
                  return row1.state == row2.state ? 0 :
                    row1.state < row2.state ? -1 : 1;
                return row1.state == row2.state ? 0 :
                  row1.state < row2.state ? 1 : -1;
              },
              needsAttnFn,
            ],
            colStyle: { width: "14rem" },
            customComponent (row) {
              return { component: TableLabel, props: { noteState: row.status, updatedStatus: row.updatedStatus, responseCount: row.responseCount, } };
            },
          },
        ],
      };
      if (!this.isUserScribe && this.authors.length == 1) {
        // if authors length == 1 then the user is the only editor for their own notes.
        let recordedByIndex = this.colDef.col.findIndex(x => { return x.field === 'recorded_by_name'; });
        if (recordedByIndex !== -1) {
          this.colDef.col.splice(recordedByIndex, 1);
        }
      }
    },
    setCustomTableFilter () {
      let cachedFilters = this.parseCachedFilters();
      this.customTableFilters = {
        input: [
          {
            id: 'dp-timestamp',
            inputType: "datepicker",
            placeholder: "Date Range",
            filterField: "timestamp",
            value: cachedFilters['dp-timestamp'] ?? [],
          },
          {
            id: 'ms-record-by',
            inputType: "multi-select",
            filterField: "user_id",
            placeholder: "Recorded By",
            ErrorMessage: "",
            // reduce to object for multi select {recorded_by_name:false/true}
            value: this.authors
              .reduce((prop, curr) => {
                if (cachedFilters['ms-record-by'] && cachedFilters['ms-record-by'][curr.name]) {
                  prop[curr.name] = true;
                } else {
                  prop[curr.name] = false;
                }
                return prop;
              }, {}),
            _value: this.authors.reduce((prop, x) => {
              prop[x.name] = x.userId;
              return prop;
            }, {})
          },
          {
            id: 'ms-status',
            inputType: "multi-select",
            filterField: "_status",
            placeholder: "Status",
            label: "",
            value: cachedFilters['ms-status'] ?? {
              "Draft": false,
              "Awaiting Review": false,
              "In Review": false,
              "Verified": false,
              // "In PMS": false,
              // "Processing": false,
              "Needs Attention": false,
            }
          },
        ]
      };

      // setting proper language for non-verified users
      if (!this.isUserVerified && !this.isUserScribe) {
        this.customTableFilters.input[2].value["Completed"] =
          this.customTableFilters.input[2].value["Verified"];
        delete this.customTableFilters.input[2].value["Verified"];
      }

      // copy filters
      let filters = this.customTableFilters.input.map(x => ({ ...x }));
      this.queryManager.setFilters(filters);
    },
    clearCustomTableFilters () {
      this.userConfig.cachedFilters = {};
      this.saveUserConfig();
      // localStorage.removeItem("Notes:CustomTableFilters");
      this.setCustomTableFilter();
    },
    mapNameToUserId () {
      let nameMap = {};
      this.notes.forEach(x => nameMap[x.metadata.recorded_by_name] = parseInt(x.user_id));
      return nameMap;
    },
    // Function to check local storage and parse out any filters that were cached from the previous session.
    parseCachedFilters () {
      let cachedFilters = this.userConfig.cachedFilters;
      // keyed on id of custom filter input properties
      let parsedValues = {};
      // return parsedValues;
      if (!cachedFilters) {
        return parsedValues;
      }
      // hardcoding these, if anything changes about this format we'd be in a lot of pain if there wasn't a specific key to reference
      if (cachedFilters['dp-timestamp'] && cachedFilters['dp-timestamp'].length > 0) {
        // needs to be an array of length 2
        let d0, d1;
        if (Array.isArray(cachedFilters['dp-timestamp']) && cachedFilters['dp-timestamp'].length === 2) {
          // attempt to parse the dates
          d0 = new Date(cachedFilters['dp-timestamp'][0]);
          d1 = new Date(cachedFilters['dp-timestamp'][1]);
        }
        if (!d0 || d0 == 'Invalid Date' || !d1 || d1 === 'Invalid Date') {
          parsedValues['dp-timestamp'] = [];
        } else {
          parsedValues['dp-timestamp'] = [d0, d1];
        }
      }
      if (cachedFilters['ms-record-by']) {
        parsedValues['ms-record-by'] = cachedFilters['ms-record-by'];
      }
      if (cachedFilters['ms-status']) {
        parsedValues['ms-status'] = {
          "Draft": cachedFilters['ms-status']["Draft"] ?? false,
          // "Processing": cachedFilters['ms-status']["Processing"]          ?? false,
          "Awaiting Review": cachedFilters['ms-status']["Awaiting Review"] ?? false,
          "In Review": cachedFilters['ms-status']["In Review"] ?? false,
          "Verified": cachedFilters['ms-status']["Verified"] ?? cachedFilters['ms-status']["Completed"] ?? false,
          // "In PMS": cachedFilters['ms-status']["In PMS"] ?? false,
          "Needs Attention": cachedFilters['ms-status']["Needs Attention"] ?? false,
        };
      }
      return parsedValues;
    },
    // handler for filter change events coming from the BaseTable component
    // takes in a filter item from the customTableFilters list that is passed to the BaseTable
    updateCachedFilters (filter) {
      let cachedFilters = this.userConfig.cachedFilters;
      if (!cachedFilters) {
        cachedFilters = {};
      }
      //specifically target multiselect to cull out useless values. remove all false fields from the filter
      let cache = filter.value;
      if (filter.inputType === "multi-select") {
        cache = Object.keys(cache)
          .reduce((prop, key) => {
            if (cache[key]) {
              prop[key] = true;
            }
            return prop;
          }, {});
      }
      cachedFilters[filter.id] = cache;
      this.userConfig.cachedFilters = cachedFilters;
      this.saveUserConfig();
    },
    handleTextSearch (text) {
      this.pagination.page = 1;
      this.queryManager.setTextSearch(text);
      this.getPageData();
    },
    handlePageSizeSelect (val) {
      let pageSize = parseInt(val.pageSize);
      this.userConfig.pageSize = pageSize;
      this.pageSize = pageSize;
      this.pagination.page = 1;
      this.pagination.pageSize = pageSize;
      this.saveUserConfig();
    },
    handlePageSelect (val) {
      this.currentPage = parseInt(val);
      this.queryManager.setPage({ page: this.currentPage });
      this.getPageData();
    },
    handleColumnSort (col) {
      this.userConfig.sortBy = { field: col.field, sortBy: col.sortBy };
      this.sort = this.userConfig.sortBy;
      this.queryManager.setSorting({ field: col.field, sortBy: col.sortBy });
      this.saveUserConfig();
    },

    toggleRowSelect (row, val) {
      if (val) {
        this.selectedData.push(this.tableData.find(x => x.id == row.id));
      } else {
        const index = this.selectedData.findIndex(x => x.id == row.id);
        if (index > -1) {
          this.selectedData.splice(index, 1);
        }
      }
      this.actionList.props.selectedData = this.archiveListNotes;
    },
    toggleAllSelect (val, selectedIds) {
      this.selectedData = [];
      if (!val) {
        this.actionList.props.selectedData = this.archiveListNotes;
        return;
      }
      this.tableData.forEach(row => {
        const index = selectedIds.findIndex(id => id === row.id);
        if (index !== -1) {
          selectedIds.splice(index, 1);
          this.selectedData.push(row);
        }
      });
      this.actionList.props.selectedData = this.archiveListNotes;
    },
    toggleArchiveModal () {
      this.showArchiveModal = !this.showArchiveModal;
    },

    archiveSelected () {
      this.loading = true;

      let promises = [];
      this.selectedData.forEach(
        row => {
          promises.push(NotesService.ArchiveNote(row.id, !this.archived));
        }
      );
      Promise.all(promises)
        .then((resp) => {
          console.log(resp);
          this.$toast.success({ message: "Notes were archived!" });
        })
        .catch(e => {
          console.log(e);
          this.$toast.error({ message: "Archiving failed!" });
        })
        .finally(() => {
          this.selectedData = [];
          this.loading = false;
          this.toggleArchiveModal();
          this.queryManager.invalidateCache();
          this.getPageData();
        });
    },

    // * functions for managing saved custom filters
    // savedFilters should be given a unique id
    // Saving the current set of custom filters into the cashed preset filters for users
    // this.$emit("save-custom-filters", { fullName: this.filterNameValue, tagName: tagName });
    saveCustomFilters (filterNames) {
      // the cool thing about using a hash is I can splice out any duplicate filters and rename ezpz
      const id = hash(JSON.stringify(this.userConfig.cachedFilters));
      let i = this.savedFilters.findIndex(x => x.id == id);
      if (i !== -1) {
        this.savedFilters.splice(i, 1);
      }
      let newFilter = {
        id: id,
        name: filterNames.fullName,
        tag: filterNames.tagName,
        filters: structuredClone(this.userConfig.cachedFilters), // cachedFilters is kept up-to-date as current filter as filters change so it can be used to save
      };
      this.savedFilters.push(newFilter);
      this.userConfig.savedFilters = this.savedFilters;
      this.saveUserConfig();
    },
    selectSavedFilters (filterId) {
      let selected = this.savedFilters.find(x => x.id == filterId);
      this.userConfig.cachedFilters = structuredClone(selected.filters);
      this.pagination.page = 1;
      this.setCustomTableFilter();
      this.saveUserConfig();
    },
    removeSavedFilters (filterId) {
      let i = this.savedFilters.findIndex(x => x.id == filterId);
      if (i === -1) return;
      this.savedFilters.splice(i, 1);
      this.userConfig.savedFilters = structuredClone(this.savedFilters);
      this.saveUserConfig();
    },
    saveUserConfig () {
      localStorage.setItem(`Notes:${this.currUserId}UserConfig`, JSON.stringify(this.userConfig));
    },
  },
  beforeUnmount () {
    this.$store.getters.getWebsocketEventHandler.removeEvents(this.wsIds);
  }

};
