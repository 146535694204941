const UserService = require('Services/UserService');
import SubscriptionService from "Services/SubscriptionService";
const PasswordResetService = require("Services/PasswordResetService");
import BaseBox from "Components/ui/BaseBox";
import BaseInput from "Components/ui/BaseInput";
import BaseMultiSelect from "Components/ui/BaseMultiSelect";
import BaseIcon from "Components/ui/BaseIcon";
import BaseLoading from "Components/ui/BaseLoading";
import BaseModal from "Components/ui/BaseModal";
import FadeInWrapper from  'Components/layout/FadeInWrapper.vue';

export default {
  name: 'UserProfile',
  components: {
    BaseBox,
    BaseIcon,
    BaseInput,
    BaseMultiSelect,
    BaseLoading,
    BaseModal,
    FadeInWrapper,
  },
  data () {

    const queryParams = this.$router.currentRoute.value.query;
    const pathParams = this.$route.params;
    let userId = 0;
    if (queryParams.id) {
      userId = queryParams.id;
    } else if (pathParams.userId) {
      userId = pathParams.userId;
    } else {
      userId = this.$store.getters.getUserId;
    }
    return {
      currentUser: this.$store.getters.getUserId,

      pendingUserManagementRequest: false,
      users: undefined,
      invites: undefined,
      seatList: undefined,
      subscription: undefined,

      fullName: '',
      email: '',
      iconText: '',
      firstName: '',
      lastName: '',
      loading: true,
      // practice: '',
      phone: '',
      permissions: undefined,
      userProfile: undefined,
      tmpProfile: undefined,
      user: undefined,
      userId,
      changeMade: false,
      // for managing the state of the password fields
      currentPass: "",
      newPass: "",
      currPassError: '',
      newPassError: '',
      showPass: false,
      passwordChangeMade: false,
      pendingChange: false,

      showPickerModal: false,
      showRemoveModal: false,
      profileImagePath: '@/assets/images/profile-photos/',
      selectedImage: undefined,

      permsChanged: false,
    };
  },
  async created () {

    if (this.$store.getters.getUserPerms.user_management) {
      this.pendingUserManagementRequest = true;
      Promise.all([SubscriptionService.GetSubscriptionUsers(), SubscriptionService.GetInvites(), SubscriptionService.GetSubscription()])
        .then((resp) => {
          this.users = resp[0].data.users;
          // ! for removing all permissions when accounts don't have the right settings
          this.users.forEach(x => this.hidePermsForVerified(x.permissions));
          this.invites = resp[1].data.invites;
          this.invites.forEach(x => this.hidePermsForVerified(x.permissions));
          this.seatList = this.users.concat(this.invites);
          this.subscription = resp[2].data;

        }).catch((err) => {
          console.log(err);
        }).finally(() => {
          this.pendingUserManagementRequest = false;
        });
    }
    if (this.currentUser == this.userId) {
      let userProfile = this.$store.getters.getUserProfile;
      this.handleProfile(userProfile);
      let permissions = { dictation: false, user_management: false, billing_management: false, verified: false, editor: false };
      Object.keys(this.$store.getters.getUserPerms).forEach(key => { permissions[key] = this.$store.getters.getUserPerms[key]; });
      delete permissions.editor;
      if (!this.subHasVerifiedSeats) {
        delete permissions.verified;
      }
      this.permissions = permissions;
      this.loading = false;

    } else {

      let permsPromise = SubscriptionService.GetPermissions(this.userId);
      permsPromise.then(resp => {
        let permissions = this.hidePermsForVerified(resp.data.permissions);
        this.permissions = permissions;
      });
      Promise.all([UserService.GetUser(this.userId), UserService.GetUserProfile(this.userId), permsPromise])
        .then((resp) => {
          if (resp[1].data.profile_photo == '') {
            let defaultPhoto = `profile-${parseInt(this.userId / 2) % 20 + 1}.png`;
            resp[1].data.profile_photo = defaultPhoto;
          }
          resp[1].data.firstName = resp[1].data.first_name,
            resp[1].data.lastName = resp[1].data.last_name,
            resp[1].data.jobTitle = resp[1].data.job_title,
            resp[1].data.phoneNumber = resp[1].data.phone_number,
            resp[1].data.profilePhoto = resp[1].data.profile_photo,
            resp[1].data.email = resp[0].data.email,
            this.handleProfile(resp[1].data);
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.loading = false;
        });
    }

  },
  watch: {
    currentPass () {
      this.currPassError = "";
      this.passwordChangeMade = this.currentPass !== '';
    },
    newPass () {
      this.newPassError = "";
    },
  },
  computed: {
    isUserScribe () {
      if (!this.$store.getters.getUserGroups) return false;
      return this.$store.getters.getUserGroups.findIndex(x => x === "Scribe") !== -1;
    },
    dictationUsers () {
      return this.seatList.filter(x => x.permissions.dictation);
    },
    managerUsers () {
      return this.seatList.filter(x => x.permissions.user_management && x.permissions.billing_management);
    },
    verifiedUsers () {
      return this.seatList.filter(x => x.permissions.verified);
    },
    editorUsers () {
      return this.seatList.filter(x => x.permissions.editor);
    },
    subHasVerifiedSeats () {
      return this.$store.getters.subHasVerifiedSeats;
    },
    imageBorder () {
      if (this.tmpProfile.profilePhoto == '') return '';
      let img = this.tmpProfile.profilePhoto.split('.')[0];
      let index = img.split('-')[1] - 1;
      let colorKey = parseInt(index / 5);
      return ["#52BAE2", "#6BEDD3", "#97ABED", "#FFD37A"][colorKey];
    },
    newPassValid () {
      if (this.newPass.length < 6) {
        return false;
      }
      else if (this.newPass.length > 128) {
        this.newPassError = "New password cannot be longer than 128 characters";
        return false;
      }
      this.newPassError = "";
      return true;
    },
    initials () {
      //testing first and lastName for undefined
      let firstName = "";
      let initials = "";
      if (this.firstName) {
        firstName = this.firstName.trim();
        //setting initials with name sections
        if (firstName.length > 0) {
          initials += firstName[0].toUpperCase();
        }
      }

      let lastName = "";
      if (this.lastName) {
        lastName = this.lastName.trim();
        //setting initials with name sections
        if (lastName.length > 0) {
          initials += lastName[0].toUpperCase();
        }
      }

      if (initials === "") {
        let email = "";
        if (this.email) {
          email = this.email.trim();
          if (email.length > 0) {
            initials += email[0].toUpperCase();
          }
        }
      }
      return initials;
    },
    listizePermissions () {
      let keys = [];
      if (!this.permissions) {
        return keys;
      }
      Object.keys(this.permissions).forEach(key => { if (this.permissions[key]) keys.push(key.replace(/_/g, " ")); });
      if (this.userId == this.currentUser && this.$store.getters.getUserGroups)
        this.$store.getters.getUserGroups.forEach(x => keys.push(x));
      return keys;
    },
    // list of permissions to disable for the permissions multiselect
    disabledList () {
      let disableList = [];
      if (!this.subscription) return [];
      let maxDictators = 0;
      let maxVerified = 0;
      if (this.subscription.stripe_id) {
        maxDictators = parseInt(this.subscription.dictation_seats),
          maxVerified = parseInt(this.subscription.verified_seats);
      } else {
        maxDictators = parseInt(this.subscription.product.number_of_users),
          maxVerified = parseInt(this.subscription.verified_seats_available);
      }
      if (maxDictators <= this.dictationUsers.length) {
        disableList.push({ "dictation": false });
      }
      if (maxVerified <= this.verifiedUsers.length) {
        disableList.push({ "verified": false });
      }
      //  if dictation is checked, only let users uncheck verified
      if (this.permissions.dictation && !this.permissions.verified) {
        disableList.push({ "verified": false });
      }
      //  if verified is checked, only let users uncheck dictation
      if (this.permissions.verified && !this.permissions.dictation) {
        disableList.push({ "dictation": false });
      }
      // prevent adding permissions they don't have
      let disableBilling = true;
      if (!this.$store.getters.getUserPerms.billing_management) {
        disableBilling = false;
        // disable for both states, no management permission permitted to change to change
        disableList.push({ "billing_management": false }, { "billing_management": true });
      }
      // checking manager rules
      const managers = this.seatList.filter(x => x.permissions.user_management && x.permissions.billing_management);
      if (managers.length == 1) {
        disableList.push({ "user_management": true });
        if (disableBilling) {
          disableList.push({ "billing_management": true });
        }
      }
      return disableList;
    },
  },
  methods: {
    disablePerm (perm) {
      // check if there is no rule to apply
      let ruleIndex = this.disabledList.findIndex(x => {
        return x[perm] !== undefined;
      });
      if (ruleIndex === -1) return false;

      return this.disabledList[ruleIndex][perm] === this.permissions[perm];
    },
    imgPath (img) {
      return require(this.profileImagePath + img);
    },
    handleProfile (userProfile) {
      this.tmpProfile = structuredClone(userProfile);
      this.userProfile = userProfile;
      if (userProfile.firstName || userProfile.lastName) {
        this.firstName = userProfile.firstName;
        this.lastName = userProfile.lastName;
        this.fullName = userProfile.firstName + ' ' + userProfile.lastName;
      }
      this.email = userProfile.email;
      this.loading = false;
      this.phone = userProfile.phone;
    },
    hidePermsForVerified (perms) {
      delete perms.editor;
      if (!this.subHasVerifiedSeats) delete perms.verified;
      return perms;
    },
    change () {
      this.changeMade = true;
    },
    togglePerm (perm) {
      if (!this.$store.getters.getUserPerms.user_management) return;
      if (this.disabledList.find(x => x[perm] === this.permissions[perm])) {
        // provide prevention reason... or don't :^)
        // if (perm == "dictation") {
        // } else if (perm == "user_management") {
        // } else if (perm == "billing_management") {
        // }
        return;
      }
      this.permsChanged = true;
      this.permissions[perm] = !this.permissions[perm];
      console.log(this.userProfile);
      console.log(this.seatList);
      this.seatList.find(x => x.user_id == this.userId).permissions == this.permissions;

      this.changeMade = true;
    },

    togglePickerModal () {
      this.showPickerModal = !this.showPickerModal;
    },
    cancelPickerModal () {
      this.togglePickerModal();
      this.selectedImage = this.userProfile.profilePhoto;
    },
    selectImage (index) {
      if (index == 'none') { this.selectedImage = ''; return; }
      this.selectedImage = `profile-${index}.png`;
    },
    confirmImage () {
      this.togglePickerModal();
      this.tmpProfile.profilePhoto = this.selectedImage;
      this.changeMade = true;
      this.saveChanges();
    },
    saveChanges () {
      let proms = [this.submitProfile()];
      if (this.permsChanged) proms.push(this.submitPermissions());
      Promise.all(proms)
        .then((r) => {
          this.$toast.success({ message: "Profile Updated!" });
        }).catch((e) => {
          this.$toast.error({ message: "There was an issue updating your profile! If this persists contact support." });
        }).finally(() => {
          this.pendingChange = false;
        });
    },
    submitProfile () {
      this.changeMade = false;
      this.pendingChange = true;
      this.userProfile = structuredClone(this.tmpProfile);

      return UserService.UpdateUserProfile({
        first_name: this.userProfile.firstName,
        last_name: this.userProfile.lastName,
        job_title: this.userProfile.jobTitle,
        phone_number: this.userProfile.phoneNumber,
        profile_photo: this.userProfile.profilePhoto,
        email: this.userProfile.email,
      }, this.userId)
        .then((resp) => {
          resp.data.firstName = resp.data.first_name;
          resp.data.lastName = resp.data.last_name;
          resp.data.jobTitle = resp.data.job_title;
          resp.data.phoneNumber = resp.data.phone_number;
          resp.data.profilePhoto = resp.data.profile_photo;
          resp.data.email = this.userProfile.email;
          if (this.userId == this.currentUser)
            this.$store.commit("set_user_profile", resp.data);

          this.$store.getters.getWebsocketEventHandler.sendInternalUpdateEvent(
            {
              type: "UserManagement:UpdateProfile",
              profile: resp.data,
            });

          this.handleProfile(resp.data);
        }).catch((err) => {
          console.log(err);
        });
    },
    submitPermissions () {
      let req = {
        subscription_id: this.$store.getters.getSubscriptionId,
        user_permissions: [{
          user_id: this.userId,
          permissions: this.permissions,
        }]
      };
      return SubscriptionService.UpdatePermissions(req)
        .then((resp) => {
        })
        .catch((err) => {
          console.error(err);
        }).finally(() => {
          this.permsChanged = false;
        });
    },
    submitPassword () {
      this.pendingChange = true;
      UserService.UpdatePassword(this.currentPass, this.newPass).then((resp) => {
        this.$toast.success({ message: "Password changed!" });
        this.currentPass = "";
        this.newPass = "";
      }).catch((err) => {
        console.log(err);
      if (err.status == 401) {
        this.currPassError = "Current password is incorrect.";
      } else if (err.data.message) {
          this.newPassError = err.data.message;
      }
      }).finally(() => {
        this.pendingChange = false;
      });
    },
    sendPasswordReset () {
      this.pendingChange = true;
      PasswordResetService.SendPasswordReset(this.userProfile.email)
        .then(resp => {
          this.$toast.success({ message: `Password reset sent!` });
        }).catch(err => {
          this.$toast.error({ message: "Failed sending reset!" });
          console.log(err, "failed sending reset");
        }).finally(() => {
          this.pendingChange = false;
        });
    },

  }
};