<template>
  <div class="sidebar sidebar-width">
    <!-- header container for talkatoo logo -->
    <div class="heading-container flex">
      <RouterLink v-if="isLoggedIn" class="flex items-center mx-auto " to="/notes">
        <div class="logo-wrapper" @click="mobileMenu = false">
          <img
            src="@/assets/images/talkatoo_icon_new.png"
            class="logo m-auto"
            aria-label="Admin Portal Home"
            height="46" width="46"
          />

        </div>
      </RouterLink>
    </div>
    <routerLink to="/notes" title="Notes list"
                :class="['sidebar-link no-underline flex justify-center transition-colors duration-150',
                         'active:bg-main-darker active:bg-opacity-25 hover:bg-main p-3 rounded-lg w-[54px] h-w-[50px]',
                         $route.name === 'NotesEntryId' ? 'router-link-active' : '']">
      <img class="transform -translate-x-[1px] " :src="require('Assets/images/notebook_white.svg')" />
    </routerLink>
    <!-- spacer -->
    <div class="flex-grow mt-8"></div>
    <!-- bottom close side bar button -->
    <!-- <div class="bg-main-alt w-[90px] flex flex-col items-center"> -->
    <!-- hover == lighter, active == darker -->
    <routerLink v-if="isManager" :to="teamLink" title="Team Management"
                class="sidebar-link no-underline flex justify-center transition-colors duration-150 mb-2
                active:bg-main-darker active:bg-opacity-25 hover:bg-main p-3 rounded-lg w-[54px] h-w-[50px]">
      <span class="material-icons no-underline text-3xl leading-4 text-white opacity-60">groups</span>
    </routerLink>
    <a href="https://support.talkatoo.com/knowledge" target="_blank" title="Knowledge Base"
       class="sidebar-link no-underline flex justify-center transition-colors duration-150 mb-2
              active:bg-main-darker active:bg-opacity-25 hover:bg-main p-3 rounded-lg w-[54px] h-w-[50px]">
      <span class="material-symbols-outlined text-3xl no-underline text-white opacity-60">help</span>
    </a>
    <!-- </div> -->
  </div>
</template>
<script>

export default {
  name: "SideBar",
  data () {
    return {
      adminRoutes: ["AdminDashboard", "UserManagement", "BillingHistory", "Subscription"]
    };
  },
  mounted () {

  },
  computed: {
    isAdminPage () {
      return this.adminRoutes.find(x => x == this.$router.currentRoute.value.name);
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn;
    },
    userPerms () {
      return this.$store.getters.getUserPerms;
    },
    isManager() {
      return this.userPerms !== undefined &&
      (this.userPerms.billing_management || this.userPerms.user_management);
    },
    featureFlags () {
      return this.$store.getters.getFeatureFlags;
    },
    isProvisioning () {
      return this.$store.getters.getSubscriptionState === "provisioning";
    },
    teamLink () {
      if (!this.userPerms) return '/';
      if (this.userPerms.user_management) {
        return '/users';
      } else if (this.userPerms.billing_management) {
        return '/subscription';
      }
      return '/';
    },
  },
  methods: {

  }
};
</script>

<style scoped>
.logo-wrapper {
  @apply h-auto;
}


/* using vars here for the sake of the header matching the size */
.heading-container {
  margin-top: var(--logo-mt);
  margin-bottom: var(--logo-mb);
}

.logo {
  min-height: var(--logo-h);
  min-width: var(--logo-h);
}

.sidebar {
  @apply text-white;
  @apply bg-main-alt;
  @apply sticky;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-width {
  min-width: var(--sidebar-w);
  width: var(--sidebar-w);
}

.sidebar a {
  /* @apply px-2; */
  @apply no-underline;
}


.sidebar-heading {
  font-size: 1.25rem;
}

.sidebar-link {
  width: 54px;
  height: 50px;
}

.sidebar-link>img {
  @apply opacity-60;
  pointer-events: none;
}

.sidebar-link.router-link-active>img {
  @apply opacity-100;
}

.sidebar-link>span {
  @apply transition-opacity;
}

.sidebar-link.router-link-active {
  @apply bg-main-darker;
}

.sidebar-link.router-link-active>span {
  @apply opacity-100;
}</style>
