import BaseSelectList from "Components/ui/BaseSelectList.vue";
import BaseInput from "Components/ui/BaseInput.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";

export default {
  name: "ShippingForm",
  props: {
    back: Boolean,
    shippingInfo: Object,
    countryList: Array,
    disable: Boolean,
    disableButton: Boolean,
    ContinueText: { type: String, optional: true },
    asStripe: Boolean,
  },
  components: { BaseSelectList, BaseInput, BaseLoading },
  data () {
    //! handle case where user may have plaintext name of the country. this is a catch for maybe better UX
    let country = this.shippingInfo?.country ?? "";
    let state = this.shippingInfo?.state ?? "";
    let stateList = [];
    let showStatePicker = false;
    if (country) {
      let contMap = this.countryList.find(x => x.text.toLowerCase() == country.toLowerCase());
      // if user has plaintext country name
      if (contMap) {
        country = contMap.value;
      // if user has plaintext state name
        if (contMap.states) {
          let stateMap = contMap.states.find(x => x.state.toLowerCase() == state.toLowerCase());
          if (stateMap){
            state = stateMap.code;
            showStatePicker = true;
            stateList = contMap.states.map((el) => { return { text: el.state, value: el.code }; });
          }
        }
      }
    }
    return {
      stateList: stateList,
      showStatePicker: showStatePicker,
      name: { value: this.shippingInfo.name ?? "", error: "" },
      firstName: { value: this.shippingInfo?.first_name ?? "", error: "" },
      lastName: { value: this.shippingInfo?.last_name ?? "", error: "" },
      address1: { value: this.shippingInfo?.address ?? "", error: "" },
      address2: { value: this.shippingInfo?.address_2 ?? "", error: "" },
      country: { value: country, error: "" },
      state: { value: state, error: "" },
      city: { value: this.shippingInfo?.city ?? "", error: "" },
      zip: { value: this.shippingInfo?.zip ?? "", error: "" },
      change: false,
    };
  },
  watch: {
    shippingInfo (newVal) {
      if (!newVal) return;
      this.change = true;
      this.name = { value: newVal.name ?? "", error: "" };
      this.firstName = { value: newVal.first_name ?? "", error: "" };
      this.lastName = { value: newVal.last_name ?? "", error: "" };
      this.address1 = { value: newVal.address ?? "", error: "" };
      this.address2 = { value: newVal.address_2 ?? "", error: "" };
      this.city = { value: newVal.city ?? "", error: "" };
      this.zip = { value: newVal.zip ?? "", error: "" };
      if (newVal.country && newVal.country !== "") {
        this.countryChange(newVal.country);
        this.stateChange(newVal.state);
      } else {
        this.country = { value: "", error: "" };
        this.state = { value: "", error: "" };
      }
    },
  },
  methods: {
    countryChange (event) {
      this.country.value = event;
      this.country.error = "";
      this.state.value = "";
      this.state.error = "";

      const states = this.countryList.find(x => x.value == event)?.states;
      this.stateList.length = 0;
      if (states) {
        this.stateList = states.map((el) => { return { text: el.state, value: el.code }; });
        this.showStatePicker = true;
      } else {
        this.showStatePicker = false;
      }
    },

    stateChange (event) {
      this.state.value = event;
      this.state.error = "";
    },

    validateForm () {
      var valid = true;
      if (this.asStripe) {
        if (this.name.value === '') {
          this.name.error = "Please write your name";
          valid = false;
        }
      } else {
        if (this.firstName.value === '') {
          this.firstName.error = "Please write your first name";
          valid = false;
        }
        if (this.lastName.value === '') {
          this.lastName.error = "Please write your last name";
          valid = false;
        }
      }
      if (this.address1.value === '') {
        this.address1.error = "Please write your address";
        valid = false;
      }
      let contMap = this.countryList.find(x => x.value == this.country.value);
      if (!contMap || this.country.value === '') {
        this.country.error = "Please select a country";
        valid = false;
      }

      let stateOk = this.state.value !== '';
      if (contMap && contMap.states) {
         let stateMap = contMap.states.find(x => x.code == this.state.value);
        stateOk = stateMap != undefined;
      }
      if (!stateOk) {
        this.state.error = "Please select a state";
        if (!this.showStatePicker) {
          this.state.error = "Please write your state";
        }
        valid = false;
      }
      if (this.city.value === '') {
        this.city.error = "Please write your city";
        valid = false;
      }
      if (this.zip.value === '') {
        this.zip.error = "Please write your zipcode";
        valid = false;
      }
      return valid;
    },

    //when a field changes, just remove errors
    fieldChange (event, field) {
      this.change = true;
      switch (field) {
        case ("name"): this.name.error = ""; break;
        case ("firstName"): this.firstName.error = ""; break;
        case ("lastName"): this.lastName.error = ""; break;
        case ("address1"): this.address1.error = ""; break;
        case ("address2"): this.address1.error = ""; break;
        // case ("country"): this.country.error = ""; break;
        // case ("state"): this.state.error = ""; break;
        case ("city"): this.city.error = ""; break;
        case ("zip"): this.zip.error = ""; break;
      }
    },

    handleSubmit () {
      if (!this.validateForm()) {
        return;
      }
      if (this.change) {
        const shippingInfo = {
          name: this.name.value,
          first_name: this.name.value, // old for chargify. no harm in inclusion here
          last_name: this.name.value,  // old for chargify. no harm in inclusion here
          address: this.address1.value,
          address_2: this.address2.value,
          country: this.country.value,
          state: this.state.value,
          city: this.city.value,
          zip: this.zip.value,
        };
        this.$emit("submitEvent", { shippingInfo, change: this.change });
      } else {
        this.$emit("submitEvent", { change: this.change });
      }
    },
    cancelEvent () {
      this.$emit("cancelEvent");
    }
  },

  created () {
    if (this.country.value !== "" && this.state.value !== "") {
      const states = this.countryList.find(x => x.value == this.country.value)?.states;
      if (states) {
        this.stateList.length = 0;
        this.stateList = states.map((el) => { return { text: el.state, value: el.code }; });
        this.showStatePicker = true;
      } else {
        this.showStatePicker = false;
      }
    }
  },
};