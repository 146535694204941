/* global Chargify */
import BaseSelectList from "Components/ui/BaseSelectList.vue";
import BaseInput from "Components/ui/BaseInput.vue";
import BaseLoading from "Components/ui/BaseLoading.vue";
import SubscriptionService from "Services/SubscriptionService";

export default {
  name: "ChargifyForm",
  props: {
    back: Boolean,
    billingAddress: Object,
    disableButton: Boolean,
    countryList: Array,
    continueText: { type: String, optional: true },
  },
  components: {
    BaseSelectList,
    BaseInput,
    BaseLoading,
  },
  data () {
    return {
      chargify: new Chargify(),
      chargifyToken: undefined,
      showStatePicker: true,
      loading: true,
      stateList: [],
      errorMessage: "",
      firstName: { value: this.billingAddress.first_name ?? "", error: "" },
      lastName: { value: this.billingAddress.last_name ?? "", error: "" },
      address1: { value: this.billingAddress.address ?? "", error: "" },
      address2: { value: this.billingAddress.address_2 ?? "", error: "" },
      country: { value: this.billingAddress.country ?? "", error: "" },
      state: { value: this.billingAddress.state ?? "", error: "" },
      city: { value: this.billingAddress.city ?? "", error: "" },
      zip: { value: this.billingAddress.zip ?? "", error: "" },
    };
  },
  beforeUnmount () {
    this.chargify.unload();
  },
  methods: {
    countryChange (event) {
      this.country.value = event;
      this.country.error = "";
      this.state.value = "";
      this.state.error = "";
      const states = this.countryList.find(x => x.value == event).states;
      if (states) {
        this.stateList.length = 0;
        this.stateList = states.map((el) => { return { text: el.state, value: el.code }; });
        this.showStatePicker = true;
      } else {
        this.showStatePicker = false;
      }
    },

    stateChange (event) {
      this.state.value = event;
      this.state.error = "";
    },

    validateForm () {
      var valid = true;
      if (this.firstName.value === '') {
        this.firstName.error = "Please write your first name";
        valid = false;
      }
      if (this.lastName.value === '') {
        this.lastName.error = "Please write your last name";
        valid = false;
      }
      if (this.address1.value === '') {
        this.address1.error = "Please write your billing address";
        valid = false;
      }
      if (this.country.value === '') {
        this.country.error = "Please select a country";
        this.state.error = "Please select a state";
        valid = false;
      }
      if (this.state.value === '') {
        this.state.error = "Please select a state";
        if (!this.showStatePicker) {
          this.state.error = "Please write your billing state";
        }
        valid = false;
      }
      if (this.city.value === '') {
        this.city.error = "Please write your billing city";
        valid = false;
      }
      if (this.zip.value === '') {
        this.zip.error = "Please write your billing zipcode";
        valid = false;
      }
      return valid;
    },

    //when a field changes, just remove a
    fieldChange (event, field) {
      switch (field) {
        case ("firstName"): this.firstName.error = ""; break;
        case ("lastName"): this.lastName.error = ""; break;
        case ("address1"): this.address1.error = ""; break;
        case ("address2"): this.address1.error = ""; break;
        case ("country"): this.country.error = ""; break;
        case ("state"): this.state.error = ""; break;
        case ("city"): this.city.error = ""; break;
        case ("zip"): this.zip.error = ""; break;
      }
    },
    // these 3 functions are broken out to ensure that 'this' context is preserved correctly
    // such that chargify can be tied into the vue lifecycle
    handleChargifyFormSubmit (event) {
      event.preventDefault();
      if (!this.validateForm()) {
        return;
      }
      // submit event
      this.$emit("toggleLoading");
      this.chargify.token(
        document.querySelector("#chargify-form"),
        //success function after form submit though chargify js api is validated on their side
        this.chargifySuccess,
        //failure, function. validated on their side, returns feedback of form input
        this.chargifyFailure
      );
    },

    chargifySuccess (token) {
      this.chargifyToken = token;
      const billingInfo = {
        first_name: this.firstName.value,
        last_name: this.lastName.value,
        address: this.address1.value,
        address_2: this.address2.value,
        country: this.country.value,
        state: this.state.value,
        city: this.city.value,
        zip: this.zip.value,
      };
      this.$emit("validTokenEvent", { token, billingInfo });
    },

    chargifyFailure (err) {
      this.$emit("toggleLoading");

      console.log("token ERROR - err: ", err);
      if (err.invalidFields) {
        err.invalidFields.forEach(function (err) {
          switch (err) {
            //errors should be caught on these fields in validateForm but its possible something has gone unaccounted for
            case ("firstName"): this.firstName.error = "This field is not valid"; break;
            case ("lastName"): this.lastName.error = "This field is not valid"; break;
            case ("address"): this.address1.error = "This field is not valid"; break;
            case ("country"): this.country.error = "This field is not valid"; break;
            case ("state"): this.state.error = "This field is not valid"; break;
            case ("city"): this.city.error = "This field is not valid"; break;
            case ("zipcode"): this.zip.error = "This field is not valid"; break;
          }
        });
      } else if (err.errors) {
        if (err.status === 429) {
          this.loadChargifyJs();
        }
        this.errorMessage = "There is an issue with your card, please check your card details and try again.";
      }
    },
    cancelEvent () {
      this.$emit("cancelEvent");
    },
    loadChargifyJs () {
      // moved into mounted from data property, vue3 proxies data properties which doesn't cooperate well
      // with postMessage on iframes so we need a real object and not a function
      let chargifyStyles = {
        field: {
          paddingTop: "16px",
          margin: "0",
        },
        input: {
          width: "100%",
          paddingLeft: "16px",
          border: "1px solid rgba(119,119,119,1)",
          borderRadius: "8px",
          boxSizing: "border-box",
          display: "inline-flex",
          height: screen.width < 640 ? "2.3rem" : "2.8rem",
          fontSize: screen.width < 640 ? "0.75rem" : "1rem",
          fontWeight: 400,
          placeholder: {
            color: "rgba(0, 0, 0, 0.315)",
            fontWeight: 400,
            fontSize: screen.width < 640 ? "0.75rem" : "1rem",
          },
        },
        label: {
          fontFamily: '"Montserrat" "Helvetica Neue", Arial, sans-serif',
          top: "0px",
          left: "8px",
          padding: "0px 4px",
          zIndex: 10,
          position: "absolute",
          backgroundColor: "white",
          fontWeight: 500,
          lineHeight: "12px",
          fontSize: "12px",
          color: "rgba(155, 172, 178, 1)",
        },
        message: {
          fontSize: "0.875rem",
          margin: "2px 0 0 0"
        }
      };
      SubscriptionService.GetChargifyJWT().then((resp) => {
        this.chargify.load({
          type: "card",
          publicKey: __CHARGIFY_PUBLIC_KEY,
          serverHost: __CHARGIFY_HOST,
          securityToken: resp.data.token,
          hideCardImage: true,
          requiredLabel: "*",
          optionalLabel: "(Optional)",
          fields: {
            number: {
              selector: "#credit-card",
              label: "Card Number",
              placeholder: "XXXX XXXX XXXX XXXX",
              required: true,
              message: "This field is not valid.",
              style: chargifyStyles,
            },
            month: {
              selector: "#month",
              label: "Month",
              placeholder: "MM",
              maxlength: "2",
              required: true,
              message: "This field is not valid.",
              style: chargifyStyles,
            },
            year: {
              selector: "#year",
              label: "Year",
              placeholder: "YYYY",
              maxlength: "4",
              required: true,
              message: "This field is not valid.",
              style: chargifyStyles,
            },
            cvv: {
              selector: "#security-code",
              label: "CVV",
              placeholder: "XXX",
              maxlength: "4",
              required: true,
              message: "This field is not valid.",
              style: chargifyStyles,
            },
          },
          // nowhere does it say chargify.load returns a promise but I tired it and it works so we have loading now
        }).then(() => {
          this.loading = false;
          //adding a form submit listener, preventing normal form actions
          let form = document.querySelector("#chargify-form");
          if (form) {
            form.addEventListener("submit", this.handleChargifyFormSubmit);
          }
        });
      });
    }
  },

  created () {
    if (this.country.value !== "" && this.state.value !== "") {
      const states = this.countryList.find(x => x.value == this.country.value).states;
      if (states) {
        this.stateList.length = 0;
        this.stateList = states.map((el) => { return { text: el.state, value: el.code }; });
        this.showStatePicker = true;
      } else {
        this.showStatePicker = false;
      }
    }
  },

  mounted () {
    this.loadChargifyJs();
  },
};
