import MixpanelService from "Services/MixpanelService";

export default {
  handle3dsError: function (threeDSecureErr) {
    if (!threeDSecureErr.body) {
      return;
    }
    const body = JSON.parse(threeDSecureErr.body);
    // tracking when 3ds is presented to a user
    MixpanelService.Track("AdminPortal:3dsPresented", { "subscription_id": this.$store.getters.getSubscriptionId });
    // append current url as redirect_url after the user is finished
    const callback = `${__API_URL_V5}complete_3ds?payment_gateway_id=${body.gateway_payment_id}&subscription_id=${this.subscription.chargify_id}`;
    // threeDSecureRedirect link
    return body.action_link + `&callback_url=${callback}&redirect_url=${window.location.href}?threeds_success=true`;
  },
  handle3dsStripeError: function (threeDSecureErr) {
    // tracking when 3ds is presented to a user
    MixpanelService.Track("AdminPortal:3dsPresented", { "subscription_id": this.$store.getters.getSubscriptionId });
    // append current url as redirect_url after the user is finished
    // threeDSecureRedirect link
    return threeDSecureErr.data.details[0].action_url + `&redirect_url=${window.location.href}?threeds_success=true`;
  },

  // initalizes a list of countries and associated states from ISO mapping files
  setCountryList: function (COUNTRY_ISO_MAP, STATE_ISO_MAP) {
    return COUNTRY_ISO_MAP.map((el) => {
      return {
        text: el.country,
        value: el.code,
        states: STATE_ISO_MAP.find(x => x.country == el.country)?.states
      };
    });
  },

  // the following is used to copy billing address for mutation, and setting default values when a user is trialing
  setBillingAddress: function (billing_address) {
    // null when users are trialing or provisioning
    if (billing_address == null || billing_address.address === "") {
      // transition form null to empty billing object
      this.subscription.payment_profile.billing_address = this.billingInfo;
      return;
    }
    // billing country and state are iso mapped
    let country = this.countryList.find(x => x.value == billing_address.country);
    country = country ? country.text : billing_address.country;
    let state = billing_address.state;
    if (country.states) {
      let stateData = country.states.find(x => x.code === billing_address.state);
      state = stateData ? stateData.state : billing_address.state;
    }
    this.billingInfo = {
      address: billing_address.address ?? "",
      address2: billing_address.address_2 ?? "",
      city: billing_address.city ?? "",
      zip: billing_address.zip ?? "",
      country: country ?? "",
      state: state ?? "",
      first_name: billing_address.first_name ?? "",
      last_name: billing_address.last_name ?? "",
      name: billing_address.name ?? ""
    };
  },

  setShippingAddress: function (shipping_address) {
    if (shipping_address == null) {
      shipping_address = {};
    }
    // billing country and state are iso mapped
    let country = this.countryList.find(x => x.value == shipping_address.country);
    country = country ? country.text : shipping_address.country;
    let state = shipping_address.state;
    if (country?.states) {
      let stateData = country.states.find(x => x.code === shipping_address.state);
      state = stateData ? stateData.state : shipping_address.state;
    }
    this.shippingInfo = {
      address: shipping_address.address ?? "",
      address2: shipping_address.address_2 ?? "",
      city: shipping_address.city ?? "",
      zip: shipping_address.zip ?? "",
      country: country ?? "",
      state: state ?? "",
      name: shipping_address.name ?? "",
      first_name: shipping_address.first_name ?? "",
      last_name: shipping_address.last_name ?? ""
    };
  },

};